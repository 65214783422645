import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { toast, ToastContainer } from "react-toastify";
import { SingleInputTimeRangeField } from "@mui/x-date-pickers-pro";
import { Dialog, useMediaQuery } from "@material-ui/core";

const EditDepartmentPopup = ({
  data,
  EditPopupOpen,
  setEditPopupOpen,
  active,
  Refresh,
  setRefresh,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //pop up
  const handleClose = () => setEditPopupOpen(false);

  const [departmentName, setDepartmentName] = useState(data.DepartmentName);
  const [isInternal, setIsInternal] = useState(
    data.Internaldepartment == "Yes" ? true : false
  );
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  var close = "";
  var start = "";
  const [value, setvalue] = useState(() => [
    dayjs(`0000-00-00T${data.WorkingFrom}`),
    dayjs(`0000-00-00T${data.WorkingTo}`),
  ]);

  if (
    value.length >= 1 &&
    value[0] != null &&
    value[0].$d != "Invalid Date" &&
    value[1] != null &&
    value[1].$d != "Invalid Date"
  ) {
    start = new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(value[0]?.$d);
    close = new Intl.DateTimeFormat("default", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(value[1]?.$d);
  } else {
  }

  let workingto = close.split(" ");
  let finalclose = "";
  if (workingto.length === 2 && workingto[1] == "PM") {
    const settime = workingto[0].split(":");
    if (settime[0] != 12) {
      finalclose =
        parseInt(settime[0]) +
        parseInt(12) +
        ":" +
        settime[1] +
        ":" +
        settime[2];
    } else {
      finalclose = parseInt(settime[0]) + ":" + settime[1] + ":" + settime[2];
    }
  } else {
    finalclose = workingto[0];
  }
  let WorkingFrom = start.split(" ");
  let finalstart = "";
  if (WorkingFrom.length === 2 && WorkingFrom[1] == "PM") {
    const fromtime = WorkingFrom[0].split(":");
    if (fromtime[0] != 12) {
      finalstart =
        parseInt(fromtime[0]) +
        parseInt(12) +
        ":" +
        fromtime[1] +
        ":" +
        fromtime[2];
    } else {
      finalstart =
        parseInt(fromtime[0]) + ":" + fromtime[1] + ":" + fromtime[2];
    }
  } else {
    finalstart = WorkingFrom[0];
  }

  const department = async () => {
    if (departmentName == "" || finalclose == "" || finalstart == "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let payload = {
        AppCode: AppCode,
        DepartmentName: departmentName,
        WorkingFrom: finalstart,
        WorkingTo: finalclose,
        UserId: userId,
        Internaldeparment: isInternal ? "Yes" : "No",
      };

      const response = await global.$axios
        .put(`department/edit/${data.DepartmentId}`, payload)
        .then(async (response) => {
          if (response.data.Status == 200) {
            handleClose();
            active();
            setDepartmentName("");
            toast.success("Department Edited");
            setRefresh(!Refresh);
          }
        })
        .catch((e) => {
          console.error("editdepartement", e);
        });
    }
  };

  const [err, seterr] = useState(0);

  const validation = async (val) => {
    const response = await global.$axios
      .get(`/department/validatedepartmentname/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("departementpopvalidation", e);
      });
  };

  const onChangecategory = () => {
    setIsInternal(!isInternal);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"xs"}
        open={EditPopupOpen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
            xs={12}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              Edit Department{" "}
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            container
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Grid item xs={12}>
              <p style={style5.title}>Department *</p>
              <TextField
                fullWidth
                value={departmentName}
                onChange={(w) => setDepartmentName(w.target.value)}
                onKeyUp={(e) => validation(e.target.value)}
                error={err === 1 ? true : false}
                helperText={err === 1 && <span>Already Exist</span>}
                placeholder="Add Department Here"
                id="outlined-size-small"
                size="small"
                required
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <p style={{ margin: "0px 0px 0px 0px", fontSize: "14px" }}>
                Internal Department
              </p>
              <Checkbox checked={isInternal} onChange={onChangecategory} />
            </Grid>

            <Grid item xs={12}>
              <p style={{ margin: "0px 0px 10px 0px", fontSize: "14px" }}>
                Working Hours *
              </p>

              <Grid
                xs={12}
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <SingleInputTimeRangeField
                    id="time"
                    fullWidth
                    minTime={dayjs().set("hour", 0)}
                    maxTime={dayjs().set("hour", 23)}
                    value={value}
                    onChange={(newvalue) => setvalue(newvalue)}
                    slotProps={{ textField: { size: "small" } }}
                    label="From - To(hh:mm AM/PM)"
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>

          <center style={{ marginTop: "10px" }}>
            <Button
              id="Errorvalue"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              disabled={err === 1 ? true : false}
              variant="contained"
              onClick={() => {
                department();
                // window.location.reload(false);
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default EditDepartmentPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 380,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  },
};
const mainmenu = {
  width: "180px",
  height: "100%",
};
const style5 = {
  title: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};
