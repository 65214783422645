import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

function Todaycount() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [count, setcount] = useState({
    New: "0",
    Progress: "0",
    Holding: "0",
    Completed: "0",
    Solved: "0",
  });
  const dailycountapi = async () => {
    const { data } = await global.$axios.post(
      "/dashboard/daywiseupdatedTicket",
      {
        AppCode: AppCode,
        UserId: [userId],
      }
    );
    if (data.Status == 200) {
      setcount(data.Message[0]);
    }
  };
  useEffect(() => {
    dailycountapi();
  }, []);
  return (
    <>
      <Grid
        container
        sx={{ boxShadow: 1, background: "#fff", p: 1, borderRadius: 2 }}
      >
        <Grid container item xs={12}>
          <Typography
            sx={{
              fontSize: 14,
              p: 0.2,
            }}
          >
            {" "}
            Today's Updated Ticket Count
          </Typography>
          <Grid
            item
            xs={12}
            sx={{
              backgroundColor: "#E2F4FC",
              display: "flex",
              borderRadius: 1,
              justifyContent: "space-between",
              p: 0.5,
              px: 0.5,
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: 13, color: "#4F4949" }}>
              New: <span style={{ color: "#0071BC" }}>{count.New}</span>
              {/* <span style={{ color: "#0071BC", fontWeight: 500, fontSize: 14 }}>
              </span> */}
            </Typography>
            <Typography sx={{ fontSize: 13, color: "#4F4949" }}>
              Progress:{" "}
              <span style={{ color: "#0071BC" }}> {count.Progress}</span>
            </Typography>
            <Typography sx={{ fontSize: 13, color: "#4F4949" }}>
              Hold: <span style={{ color: "#0071BC" }}> {count.Holding}</span>
            </Typography>
            <Typography sx={{ fontSize: 13, color: "#4F4949" }}>
              Completed:{" "}
              <span style={{ color: "#0071BC" }}> {count.Completed}</span>
            </Typography>
            <Typography sx={{ fontSize: 13, color: "#4F4949" }}>
              Solved: <span style={{ color: "#0071BC" }}> {count.Solved}</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Todaycount;
