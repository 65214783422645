import axios from "axios";

// axios start
// axios.defaults.baseURL = "http://localhost:7003";
// axios.defaults.baseURL = "http://172.16.5.27:4007/";
// axios.defaults.baseURL = "http://172.16.5.11:7003";
// axios.defaults.baseURL = "http://172.16.5.213:4004";
//
//staging
// axios.defaults.baseURL =
//   "https://dewtcd3nszll2edinvyl5zcy4q0lfthm.lambda-url.ap-south-1.on.aws/";

//staging
// axios.defaults.baseURL = "https://bkapistaging.treeone.one/";

// //live
axios.defaults.baseURL = "https://bkticketapi.treeone.one/";

global.$axios = axios;
global.$test = "axios";
// axios end
