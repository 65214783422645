import { Autocomplete, Box, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import {
  // CloseIcon,
  CompletedIcon,
  HoldIcon,
  NewIcon,
  ProgressIcon,
  RightArrowIcon,
  SolvedIcon,
  // TicketTableIcon,
} from "../Assets/Icon";
import { useNavigate } from "react-router-dom";
import AAll from "./Allcomponents/AAll";
import ANewTable from "./Allcomponents/ANewTable";
import AProgressTable from "./Allcomponents/AProgressTable";
import ACompletedTable from "./Allcomponents/ACompletedTable";
import ASolvedTable from "./Allcomponents/ASolvedTable";
import AHoldTable from "./Allcomponents/AHoldTable";
import { ToastContainer, toast } from "react-toastify";

export default function TeamTickets() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const [table, setTable] = useState("All");
  const Navigate = useNavigate();
  const alluser = [{ UserName: "All", UserId: "All" }];
  const allDepartement = [{ DepartmentName: "All", DepartmentId: "All" }];
  const [fixed, setfixed] = useState([]);
  const [sharedepartement, setsharedepartement] = useState([]);

  //values

  var Depart = localStorage.getItem("All Depart");
  var departeequal = localStorage.getItem("Departname");
  const departeequal1 = JSON.parse(departeequal);
  const [depart, setdepart] = useState(
    departeequal !== null ? departeequal1 : []
  );

  const depart1 = JSON.parse(Depart);
  const [DepartmentDrop, SetDepartmentDrop] = useState([]);
  const [DepartmentValue, SetDepartmentValue] = useState(
    Depart !== null
      ? JSON.parse(Depart)
      : { DepartmentName: "All", DepartmentId: "All" }
  );
  var Use = localStorage.getItem("All User");
  const Use1 = JSON.parse(Use);
  const [UserDrop, SetUserDrop] = useState([]);
  const [UserValue, SetUserValue] = useState(
    Use !== null
      ? JSON.parse(Use)
      : {
          UserName: "All",
          UserId: "All",
        }
  );

  const [ShareId, SetShareId] = useState([]);
  const [countTicket, SetcountTicket] = useState("");
  const [SlaCount, SetSlaCount] = useState("");

  //api
  const DepartementList = async () => {
    const response = await global.$axios.get(
      `/ticket/team/department/${AppCode}/${userId}`
    );
    if (response.data.Status === 200) {
      SetDepartmentDrop([...allDepartement, ...response.data.Message]);
      setfixed(response.data.Message);
      setsharedepartement(
        Depart == null || depart1?.DepartmentId == "All"
          ? response.data?.Message?.map((e) => e.DepartmentId)
          : [depart1.DepartmentId]
      );
      await UserApi(
        Depart == null || depart1?.DepartmentId == "All"
          ? response.data?.Message?.map((e) => e.DepartmentId)
          : [depart1.DepartmentId]
      );
    }
  };
  const UserApi = async (DeptId) => {
    let data = {
      DepartmentId: DeptId,
    };
    const response = await global.$axios.put(
      `/ticket/team/user/${AppCode}`,
      data
    );
    if (response.data.Status === 200) {
      SetUserDrop([...alluser, ...response.data.Message]);
      SetShareId(
        Use == null || Use1?.UserId == "All" || depart?.DepartmentId != DeptId
          ? response.data?.Message?.map((e) => e.UserId)
          : [Use1.UserId]
      );
      Tickecount(
        Use == null || Use1?.UserId == "All" || depart?.DepartmentId != DeptId
          ? {
              user: response.data.Message.map((e) => e.UserId),
              Depat: DeptId,
            }
          : {
              user: [Use1.UserId],
              Depat: DeptId,
            }
      );
      Slacount(
        Use == null || Use1?.UserId == "All" || depart.DepartmentId != DeptId
          ? {
              user: response.data.Message.map((e) => e.UserId),
              Depat: DeptId,
            }
          : {
              user: [Use1.UserId],
              Depat: DeptId,
            }
      );
    }
  };
  const Tickecount = async (id) => {
    const data = {
      AppCode: AppCode,
      DepartmentId: id.Depat,
      UserId: id.user,
    };
    const response = await global.$axios.post(`/ticket/team/count`, data);
    if (response.data.Status == 200) {
      SetcountTicket(response.data.msg);
    }
  };
  const Slacount = async (id) => {
    const data = {
      AppCode: AppCode,
      DepartmentId: id.Depat,
      UserId: id.user,
    };

    const response = await global.$axios.post(`/ticket/team/SLAcount`, data);
    if (response.data.Status == 200) {
      SetSlaCount(response.data.msg);
    }
  };
  useEffect(() => {
    setTable(
      localStorage.getItem("All Tab") == null
        ? "All"
        : localStorage.getItem("All Tab")
    );
    DepartementList();
  }, []);
  return (
    <>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("All Tab", "All");
              setTable("All");
            }}
          >
            All Ticket
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            {table}
          </span>
        </div>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => Navigate("/app/AddTicket")}
            style={{
              textTransform: "none",
              maxHeight: "22px",
              minHeight: "22px",
              padding: "12px",
              fontSize: "12px",
            }}
            variant="contained"
          >
            + Add New Ticket
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <Grid
          item
          md={5}
          xs={12}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item md={5.5} xs={12}>
            <Autocomplete
              id="combo-box-demo"
              options={DepartmentDrop}
              value={DepartmentValue}
              disableClearable
              isOptionEqualToValue={(option, value) =>
                option.DepartmentName == value.DepartmentName
              }
              getOptionLabel={(option) => option.DepartmentName || ""}
              size="small"
              renderInput={(params) => (
                <TextField {...params} label=" Department" />
              )}
              onChange={(event, newvalue) => {
                localStorage.setItem("All Depart", JSON.stringify(newvalue));
                if (newvalue.DepartmentId == "All") {
                  setsharedepartement(fixed.map((e) => e.DepartmentId));
                  SetDepartmentValue({
                    DepartmentName: "All",
                    DepartmentId: "All",
                  });
                  SetUserValue({ UserName: "All", UserId: "All" });
                  UserApi(fixed.map((e) => e.DepartmentId));
                  SetShareId(UserDrop.map((e) => e.UserId));
                } else {
                  localStorage.removeItem("All User");
                  setsharedepartement([newvalue.DepartmentId]);
                  SetDepartmentValue(newvalue);
                  SetUserValue({ UserName: "All", UserId: "All" });
                  UserApi([newvalue.DepartmentId]);
                  setdepart([newvalue.DepartmentId]);
                  localStorage.setItem("Departname", JSON.stringify(newvalue));
                }
              }}
            />
          </Grid>
          <Grid item md={5.5} xs={12}>
            <Autocomplete
              id="combo-box-demo"
              options={UserDrop}
              value={UserValue}
              disableClearable
              isOptionEqualToValue={(option, value) =>
                option.UserName == value.UserName
              }
              getOptionLabel={(option) => option.UserName || ""}
              size="small"
              renderInput={(params) => <TextField {...params} label="Users" />}
              onChange={(event, newvalue) => {
                localStorage.setItem("All User", JSON.stringify(newvalue));
                if (newvalue.UserId == "All") {
                  SetShareId(UserDrop.map((e) => e.UserId));
                  SetUserValue({ UserName: "All", UserId: "All" });

                  Tickecount({
                    user: UserDrop.map((e) => e.UserId),
                    Depat:
                      DepartmentValue.DepartmentId == "All"
                        ? DepartmentDrop.map((e) => e.UserId)
                        : [DepartmentValue.DepartmentId],
                  });
                  Slacount({
                    user: UserDrop.map((e) => e.UserId),
                    Depat:
                      DepartmentValue.DepartmentId == "All"
                        ? DepartmentDrop.map((e) => e.UserId)
                        : [DepartmentValue.DepartmentId],
                  });
                } else {
                  SetUserValue(newvalue);
                  SetShareId([newvalue.UserId]);
                  Tickecount({
                    user: [newvalue.UserId],
                    Depat:
                      DepartmentValue.DepartmentId == "All"
                        ? DepartmentDrop.map((e) => e.DepartmentId)
                        : [DepartmentValue.DepartmentId],
                  });
                  Slacount({
                    user: [newvalue.UserId],
                    Depat:
                      DepartmentValue.DepartmentId == "All"
                        ? DepartmentDrop.map((e) => e.DepartmentId)
                        : [DepartmentValue.DepartmentId],
                  });
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px 0px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("All Tab", "New");
            setTable("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>New</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <NewIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {countTicket[0] && countTicket[0]?.New}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p style={{ margin: "0px", fontSize: "12px" }}>
              {SlaCount[0] && SlaCount[0]?.New}
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("All Tab", "Progress");
            setTable("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Progress</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <ProgressIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {countTicket[0] && countTicket[0]?.Progress}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p style={{ margin: "0px", fontSize: "12px" }}>
              {SlaCount[0] && SlaCount[0]?.Progress}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("All Tab", "Hold");
            setTable("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Hold</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <HoldIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {countTicket[0] && countTicket[0]?.Holding}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p style={{ margin: "0px", fontSize: "12px" }}>
              {SlaCount[0] && SlaCount[0]?.Holding}
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("All Tab", "Completed");
            setTable("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Completed</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <CompletedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {countTicket[0] && countTicket[0]?.Completed}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p style={{ margin: "0px", fontSize: "12px" }}>
              {SlaCount[0] && SlaCount[0]?.Completed}
            </p>
          </div>
        </Grid>
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "5px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("All Tab", "Solved");
            setTable("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #C2C2C2",
              padding: "0px 5px",
            }}
          >
            <p style={styles.tablehdr}>Solved</p>
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <SolvedIcon />
              <RightArrowIcon />
            </div>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-around",
              padding: "10px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              Total
            </p>

            <p style={{ margin: "0px", fontSize: "12px" }}>
              {countTicket[0] && countTicket[0]?.Solved}
            </p>
            <p
              style={{
                margin: "0px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              SLA
            </p>
            <p style={{ margin: "0px", fontSize: "12px" }}>
              {SlaCount[0] && SlaCount[0]?.Solved}
            </p>
          </div>
        </Grid>
        <Grid item md={3.9} sm={5.9} xs={12}></Grid>
      </Grid>
      {table === "All" && (
        <AAll Id={ShareId} DepartmentValue={sharedepartement} />
      )}
      {table === "New" && (
        <ANewTable Id={ShareId} DepartmentValue={sharedepartement} />
      )}
      {table === "Progress" && (
        <AProgressTable Id={ShareId} DepartmentValue={sharedepartement} />
      )}
      {table === "Completed" && (
        <ACompletedTable Id={ShareId} DepartmentValue={sharedepartement} />
      )}
      {table === "Solved" && (
        <ASolvedTable Id={ShareId} DepartmentValue={sharedepartement} />
      )}
      {table === "Hold" && (
        <AHoldTable Id={ShareId} DepartmentValue={sharedepartement} />
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
    // borderBottom: "1px solid #0071BC",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 500,
    fontSize: "14px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
};
