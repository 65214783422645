import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

export default function ReassignTicketPopup({
  openReassign,
  setOpenReassign,
  setResigned,
  Department,
  AppCode,
  UserId,
  RowId,
  selectedUser,
  setSelectedUser,
  userList,
  setUserList,
}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClose = () => {
    setOpenReassign(false);
  };

  const assignList = async (deptid) => {
    if (!deptid) {
      setUserList([]);
    } else {
      try {
        const { data } = await global.$axios.get(
          `/ticket/assignto/${AppCode}/${deptid}`
        );
        if (data.Status === 200) {
          setUserList(data.Message);
        } else {
          setUserList([]);
        }
      } catch (error) {
        setUserList([]);

        console.log("Assign List Issue", error);
      }
    }
  };

  const handleSubmit = async () => {
    setIsDisabled(true);
    try {
      if (!selectedUser) {
        toast.error("Choose the Reassign Person");
        return true;
      }

      const payload = {
        AppCode: AppCode,
        from_User: RowId,
        from_Dep: Department?.DepartmentId,
        to_User: selectedUser?.UserId,
        to_Dep: Department?.DepartmentId,
      };
      const response = await global.$axios.post(
        `/userInfo/transferTicket`,
        payload
      );

      if (response.data.Status == 200) {
        toast.success("Task Reassigned");
        setResigned("Yes");
        setIsDisabled(false);
        handleClose();
      } else {
        toast.error("Try Again");
        setIsDisabled(false);
      }
    } catch (error) {
      console.log("Re assign Task Issue", error);
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    assignList(Department?.DepartmentId);
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={openReassign}
        onClose={handleClose}
      >
        <DialogTitle>TICKET REASSIGN DETAILS</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              fullWidth
              size="small"
              value={Department?.DepartmentName}
              disabled
              sx={{ my: 2 }}
            />
            <Autocomplete
              size="small"
              options={userList}
              autoComplete
              getOptionDisabled={(option) => option.UserId == RowId}
              getOptionLabel={(option) => option.UserName || ""}
              isOptionEqualToValue={(option, value) =>
                option.UserName == value.UserName
              }
              value={selectedUser}
              onChange={(event, newValue) => {
                setSelectedUser(newValue);
              }}
              sx={{
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid black",
                },
                my: 2,
              }}
              renderInput={(params) => <TextField {...params} label="User*" />}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid sx={{ display: "flex", gap: 6, px: 4 }}>
            <Button onClick={handleClose} sx={{ color: "#1A2C5B" }}>
              Close
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isDisabled}
              variant="contained"
              sx={{
                width: "100%",
                color: "#fff",
                backgroundColor: "#1A2C5B",
                ":hover": { color: "#fff", backgroundColor: "#1A2C5B" },
              }}
            >
              Submit
            </Button>
          </Grid>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </Dialog>
    </React.Fragment>
  );
}
