import { Grid, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CompletedIcon,
  HoldIcon,
  Logincolor,
  NewIcon,
  Nodatdash,
  ProgressIcon,
  SolvedIcon,
} from "../../Assets/Icon";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Todayupdated() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  let datavalue = global?.$userData?.logindata?.AccessAdminTicket == "Yes";
  const Navigate = useNavigate();

  const [Tabsvalue, setTabsvalue] = useState(0);
  const [Ticketvalue, setTicketvalue] = useState([]);
  const [department, setdepartment] = useState([]);
  const [adminvalue, setadminvalue] = useState([]);

  const handleChange = (event, newValue) => {
    changefun(newValue);
    setTabsvalue(newValue);
  };

  //api
  const updatedticket = async () => {
    const { data } = await global.$axios.get(
      `/dashboard/deptTickets/${AppCode}/${userId}`
    );
    if (data.status === 200) {
      setTicketvalue(data.message);
      setadminvalue(data.message);
    } else {
      setTicketvalue([]);
    }
  };
  const departmentlist = async () => {
    const { data } = await global.$axios.get(
      `/ticket/team/department/${AppCode}/${userId}`
    );
    if (data.Status === 200) {
      setdepartment([
        { DepartmentId: "All", DepartmentName: "All" },
        ...data.Message,
      ]);
    } else {
      setdepartment([]);
    }
  };
  const changefun = (data) => {
    if (data != 0) {
      let value = Ticketvalue.filter(
        (x) => x.DepartmentId === department[data].DepartmentId
      );
      setadminvalue([...value]);
    } else {
      setadminvalue([...Ticketvalue]);
    }
  };

  useEffect(() => {
    if (datavalue) {
      departmentlist();
    }
    updatedticket();
  }, []);

  return (
    <Grid
      container
      sx={{
        background: "#0071BC30",
        p: 0.6,
        display: "flex",
        borderRadius: "10px",
      }}
    >
      <Grid container item>
        <Grid item xs={12} style={{ fontSize: 14, fontWeight: 400 }}>
          <Typography sx={{ fontSize: "14px", px: 0.5, mb: 1 }}>
            Today Updated Tickets
          </Typography>
          {/* {datavalue && (
            <span style={{ fontSize: "14px", padding: "5px 5px" }}>
              Departments
            </span>
          )} */}
          {datavalue && (
            <Grid item xs={12} sx={{ mt: 0.6 }}>
              <Tabs
                value={Tabsvalue}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  background: "#fff",
                  borderRadius: 2,
                  "& .MuiTab-root": {
                    fontSize: 14,
                    padding: 1,
                    textTransform: "none",
                  },
                  "& .MuiTab-label": {
                    fontSize: 14,
                    padding: 1,
                    textTransform: "none",
                  },
                }}
              >
                {department.map((x) => {
                  return (
                    <Tab label={x.DepartmentName} sx={{ px: 2 }} wrapped />
                  );
                })}
              </Tabs>
            </Grid>
          )}
          <Grid
            item
            sx={{ height: datavalue ? "50vh" : "60vh", overflow: "scroll" }}
          >
            {adminvalue.length != 0 ? (
              adminvalue.map((x) => {
                return (
                  <>
                    <Grid
                      xs={12}
                      item
                      sx={{
                        boxShadow: 1,
                        borderRadius: 2,
                        p: 1,
                        background: "#fff",
                        mt: 0.8,
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        Navigate("ticketview", {
                          state: { data: x, Table: Ticketvalue },
                        })
                      }
                    >
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Logincolor
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#0071BC",
                            }}
                          />
                          <span
                            style={{ paddingLeft: "10px", color: "#3A3A3A" }}
                          >
                            {x?.contact?.account?.CompanyName ?? "-"}
                          </span>
                        </div>
                        {x.TicketStatus <= 1 ? (
                          <NewIcon
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#0071BC",
                            }}
                          />
                        ) : x.TicketStatus === 2 ? (
                          <ProgressIcon
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#0071BC",
                            }}
                          />
                        ) : x.TicketStatus === 3 ? (
                          <HoldIcon
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#0071BC",
                            }}
                          />
                        ) : x.TicketStatus === 4 ? (
                          <CompletedIcon
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#0071BC",
                            }}
                          />
                        ) : (
                          <SolvedIcon
                            style={{
                              width: "25px",
                              height: "25px",
                              color: "#0071BC",
                            }}
                          />
                        )}
                      </Grid>
                      <Grid xs={12} item>
                        <div
                          style={{
                            borderRadius: "10px",
                            border: "1px solid #8cbfe2",
                            height: "40px",
                            marginTop: "5px",
                            padding: "5px",
                            overflow: "scroll",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: 14,
                            }}
                            dangerouslySetInnerHTML={{
                              __html: x.TicketDescription,
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          color: "#3A3A3A",
                          p: 0.5,
                        }}
                      >
                        {x.TicketStatus <= 1 ? (
                          <span style={{ fontSize: "12px" }}>
                            Created by : {x?.created?.UserFirstName ?? "-"}
                          </span>
                        ) : (
                          <span style={{ fontSize: "12px" }}>
                            Updated by : {x?.updated?.UserFirstName ?? "-"}
                          </span>
                        )}

                        {x.TicketStatus <= 1 ? (
                          <span style={{ fontSize: "12px" }}>
                            Created on :{" "}
                            {x.CreatedAt
                              ? moment(x.CreatedAt).format("DD-MM-YYYY")
                              : "-"}
                          </span>
                        ) : (
                          <span style={{ fontSize: "12px" }}>
                            Updated on :{" "}
                            {x.UpdatedAt
                              ? moment(x.UpdatedAt).format("DD-MM-YYYY")
                              : "-"}
                          </span>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              })
            ) : (
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "80px",
                }}
              >
                <Nodatdash style={{ width: "250px" }} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Todayupdated;
