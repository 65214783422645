import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  Grid,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Edituserpopup from "../PopupScreens/EditPopupscreeen/Edituserpopup";
import { toast, ToastContainer } from "react-toastify";

export default function UserInactiveTable() {
  const Navigate = useNavigate();

  const AppCode = global.$userData.logindata.AppCode;

  const [UserEditPopupOpen, setUserEditPopupOpen] = useState(false);
  const [EditUserId, setEditUserId] = useState(null);

  const active = async () => {
    // userInfo/inactivelist/AppCode
    const response = await global.$axios
      .get(`userInfo/inactivelist/${AppCode}`)
      .then(async (response) => {
        setUserSearch([...response.data.Message]);
        setUserRow([...response.data.Message]);
      })
      .catch((e) => {
        console.error("userinactive", e);
      });
  };

  const restore = async (id, value) => {
    const data1 = {
      Forid: id,
      Value: "Yes",
    };
    // userinfo/changeactive/:AppCode/:id
    const response = await global.$axios
      .put(`userinfo/changestatus/${AppCode}/${value}`, data1)
      .then(async (response) => {
        if ((response.data.status = 200)) {
          active();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("userinactiverestore", e);
      });
  };

  //Search
  const [UserRow, setUserRow] = useState([]);
  const [UserSearch, setUserSearch] = useState([]);
  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = UserRow.filter((item) => {
        const UserId = String(item?.UserId);
        const UserName = String(item?.UserName);
        const departments = String(item?.departments?.DepartmentName);
        const AccessWebAppTicket = String(item?.AccessWebAppTicket);
        const AccessMobileAppTicket = String(item?.AccessMobileAppTicket);
        const AccessAdminTicket = String(item?.AccessAdminTicket);
        const UserEmailId = String(item?.UserEmailId);
        const EmployeeId = String(item?.EmployeeId);

        const BranchName = String(item?.branch_master?.BranchName);

        const text_data = term.toLowerCase();
        return (
          UserId.indexOf(text_data) > -1 ||
          UserName.toLowerCase().indexOf(text_data) > -1 ||
          departments.toLowerCase().indexOf(text_data) > -1 ||
          AccessWebAppTicket.toLowerCase().indexOf(text_data) > -1 ||
          AccessMobileAppTicket.toLowerCase().indexOf(text_data) > -1 ||
          AccessAdminTicket.toLowerCase().indexOf(text_data) > -1 ||
          UserEmailId.toLowerCase().indexOf(text_data) > -1 ||
          EmployeeId.toLowerCase().indexOf(text_data) > -1 ||
          BranchName.toLowerCase().indexOf(text_data) > -1
        );
      });

      setUserSearch(ticketData);
    } else {
      setUserSearch(UserRow);
    }
  }
  useEffect(() => {
    active();
  }, []);

  function WebAccess(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues?.row?.AccessWebAppTicket}
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="Yes"
            size="small"
            labelPlacement="end"
            onClick={() => restore(1, cellValues?.row?.UserId)}
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    );
  }

  function MobileAccessradio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues?.row?.AccessMobileAppTicket}
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="Yes"
            size="small"
            labelPlacement="end"
            onClick={() => {
              restore(2, cellValues?.row?.UserId);
            }}
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    );
  }
  function AdminAccessradio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={cellValues?.row?.AccessAdminTicket}
          s
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio style={{ width: "5px", height: "5px" }} />}
            label="Yes"
            size="small"
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio style={{ width: "10px", height: "5px" }} />}
            label="No"
            labelPlacement="end"
          />
        </RadioGroup>
      </Grid>
    );
  }
  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "UserId",
      sort: "desc",
    },
  ]);
  const columns = [
    {
      field: "UserId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 80,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 100,
      renderCell: (cellValues) => {
        return (
          <EditIcon
            onClick={() => {
              setEditUserId(cellValues.id);
              setUserEditPopupOpen(true);
            }}
            style={{
              width: "23px",
              height: "23px",
              cursor: "pointer",
              padding: "2px",
            }}
          />
        );
      },
    },
    {
      field: "UserName",
      headerClassName: "super-app-theme--header",
      headerName: "User Name",
      width: 180,
    },
    {
      field: "AccessWebApp",
      headerClassName: "super-app-theme--header",
      headerName: "Web-App Access",
      width: 145,
      renderCell: (cellValues) => {
        return WebAccess(cellValues);
      },
    },

    {
      field: "AccessMobileApp",
      headerClassName: "super-app-theme--header",
      headerName: "Mobile-App Access",
      width: 145,
      renderCell: (cellValues) => {
        return MobileAccessradio(cellValues);
      },
    },
    {
      field: "AccessAdmin",
      headerName: "Admin Access",
      headerClassName: "super-app-theme--header",
      width: 145,
      renderCell: (cellValues) => {
        return AdminAccessradio(cellValues);
      },
    },
    {
      field: "UserEmailId",
      headerName: "Email",
      headerClassName: "super-app-theme--header",
      width: 240,
    },
    {
      field: "EmployeeId",
      headerName: "Employee ID",
      headerClassName: "super-app-theme--header",
      width: 130,
    },
    {
      field: "BranchName",
      headerName: "Branch",
      headerClassName: "super-app-theme--header",
      width: 140,
      valueGetter: (fields) => fields.row?.branch_master?.BranchName || "",
    },
  ];

  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            margin: "10px 0px 10px 0px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <div style={{ width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            // width: "80vw",
            height: "70vh",
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...UserRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={UserSearch}
          getRowId={(row) => row.UserId}
          rowHeight={38}
          columns={columns}
          pageSize={10}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
        {UserEditPopupOpen && (
          <Edituserpopup
            active={active}
            data={EditUserId}
            UserEditPopupOpen={UserEditPopupOpen}
            setUserEditPopupOpen={setUserEditPopupOpen}
          />
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};
