import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerIcon3 } from "../../Assets/Icon";
import TicketPriority from "./TicketPriority";
import ChannelDistribution from "./ChannelDistribution";
import TicketResolutionChart from "./TicketResolution";
import TicketTrendChart from "./TicketTrendChart";

function CustomerDashboard() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Name = global.$userData.logindata.Name;
  const userData = global.$userData.logindata;

  const allProduct = [{ ProductName: "All", ProductId: "All" }];
  const [companyData, setCompanyData] = useState([]);
  const [companyValue, setCompanyValue] = useState({});
  const [productData, setProductData] = useState([]);
  const [productValue, setProductValue] = useState({});
  const [tab, setTab] = useState("overall");
  const [trendsFreq, setTrendsFreq] = useState("12months");
  const [ticketResolutionFreq, setTicketResolutionFreq] = useState("12months");
  const [ticketCount, setTicketCount] = useState({
    New: "0",
    Completed: "0",
    Holding: "0",
    Progress: "0",
    Solved: "0",
    TotalCount: "0",
  });
  const [onTimeTicket, setOnTimeTicket] = useState(0);
  const [slaTicket, setSlaTicket] = useState("0%");
  const [avgRespTime, setAvgRespTime] = useState(`0 hours`);
  const [priorityList, setPriorityList] = useState([]);
  // graph Data
  const [trendsData, setTrendsData] = useState([]);
  const [priorityGraphList, setPriorityGraphList] = useState([]);
  const [ticketResolutionGraphList, seTicketResolutionGraphList] = useState([]);

  const [channelList, setChannelList] = useState([]);

  const handleButton = (val) => {
    setTab(val);
    getTicketDetailsApi(
      companyValue,
      productValue,
      val == "overall" ? "" : val == "recent" ? 30 : 15,
      productData
    );
    getOnTimeTicketApi(
      companyValue,
      productValue,
      val == "overall" ? "" : val == "recent" ? 30 : 15,
      productData
    );
    getSlaTicketApi(
      companyValue,
      productValue,
      val == "overall" ? "" : val == "recent" ? 30 : 15,
      productData
    );

    getAvgRespTimeApi(
      companyValue,
      productValue,
      val == "overall" ? "" : val == "recent" ? 30 : 15,
      productData
    );
    getTicketPriorityApi(
      companyValue,
      productValue,
      val == "overall" ? "" : val == "recent" ? 30 : 15,
      productData
    );
    getTrendsGraphApi(companyValue, productValue, "12months", productData);
    getTicketPrirityGraphApi(
      companyValue,
      productValue,
      val == "overall" ? "" : val == "recent" ? 30 : 15,
      productData
    );
  };

  const handleTrendFreq = (val) => {
    setTrendsFreq(val);
    getTrendsGraphApi(companyValue, productValue, val, productData);
  };

  const handleTicketResolutionFreq = (val) => {
    setTicketResolutionFreq(val);
    getTicketResolutionApi(companyValue, productValue, val, productData);
  };

  const getCompayListApi = async () => {
    try {
      const response = await global.$axios.get(`ticket/companies/${AppCode}`);
      if (response.data.Status == 200) {
        setCompanyData(response.data.Message);
        setCompanyValue(response.data.Message[0]);
        getProductDataApi(response.data.Message[0]);
      }
    } catch (error) {
      console.log("ticket/companies", error);
    }
  };

  const getProductDataApi = async (companyData) => {
    try {
      let payload = {
        AccountsId: companyData.AccountsId,
        AppCode: AppCode,
      };
      const response = await global.$axios.post(
        `dashboard/getProductContactWise`,
        payload
      );

      if (response.data.Status == 200) {
        setProductData([...allProduct, ...response.data.Message]);
        setProductValue({
          ProductName: "All",
          ProductId: "All",
        });

        handleApi(
          companyData,
          {
            ProductName: "All",
            ProductId: "All",
          },
          [...allProduct, ...response.data.Message]
        );
      }
    } catch (error) {
      console.log("getProductContactWise", error);
    }
  };

  const getTicketDetailsApi = async (accId, productObj, days, productArray) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId === "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };

      const response = await global.$axios.post(
        "dashboard/getproductcompletedAllTicketscount",
        payload
      );

      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setTicketCount((prev) => ({
          ...prev,
          New: ticketValue.New || "0",
          Completed: ticketValue.Completed || "0",
          Holding: ticketValue.Holding || "0",
          Progress: ticketValue.Progress || "0",
          Solved: ticketValue.Solved || "0",
          TotalCount: ticketValue.TotalCount || "0",
        }));
      }
    } catch (error) {
      console.log("getproductcompletedAllTicketscount", error);
    }
  };

  const getOnTimeTicketApi = async (accId, productObj, days, productArray) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getproductontimecompletedAllTicketscount",
        payload
      );
      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setOnTimeTicket(ticketValue.OnTimeCount | 0);
      }
    } catch (error) {
      console.log("getproductontimecompletedAllTicketscount", error);
    }
  };

  const getSlaTicketApi = async (accId, productObj, days, productArray) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getproductSLACount",
        payload
      );

      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setSlaTicket(ticketValue.SLA_Percentage || "0%");
      }
    } catch (error) {
      console.log("getproductSLACount", error);
    }
  };

  const getAvgRespTimeApi = async (accId, productObj, days, productArray) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };

      const response = await global.$axios.post(
        "dashboard/getproductcompleteavgtimeCount",
        payload
      );
      if (response.data.Status == 200) {
        let totalSeconds = Number(response.data.Message.avgtime);
        const hours = Math.floor(totalSeconds / 3600); // Get whole hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Get remaining minutes
        setAvgRespTime(
          hours == 0 ? `${minutes} minutes` : `${hours} hour ${minutes} minutes`
        );
      }
    } catch (error) {
      console.log("getproductcompleteavgtimeCount", error);
    }
  };

  const getTicketPriorityApi = async (
    accId,
    productObj,
    days,
    productArray
  ) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/productIssueticketlistcount",
        payload
      );
      if (response.data.Status == 200) {
        let listData = response.data.Message.splice(0, 5);
        setPriorityList(listData);
      }
    } catch (error) {
      console.log("productPriorityLevelticketcount", error);
    }
  };

  const getTrendsGraphApi = async (accId, productObj, period, productArray) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        timePeriod: period,
      };

      const response = await global.$axios.post(
        "dashboard/getproductticketTrends",
        payload
      );

      if (response.data.Status == 200) {
        setTrendsData(response.data.Message);
      }
    } catch (error) {
      console.log("getproductticketTrends", error);
    }
  };

  const getTicketPrirityGraphApi = async (
    accId,
    productObj,
    days,
    productArray
  ) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };

      const response = await global.$axios.post(
        "dashboard/productPriorityLevelticketcount",
        payload
      );

      if (response.data.Status == 200) {
        setPriorityGraphList(response.data.Message);
      }
    } catch (error) {
      console.log("productPriorityLevelticketcount", error);
    }
  };

  const getChannelDistributionApi = async (
    accId,
    productObj,
    days,
    productArray
  ) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        filterDays: days,
      };

      const response = await global.$axios.post(
        "dashboard/Ticketsourceticketlist",
        payload
      );
      if (response.data.Status == 200) {
        setChannelList(response.data.Message);
      }
    } catch (error) {
      console.log("Ticketsourceticketlist", error);
    }
  };

  const getTicketResolutionApi = async (
    accId,
    productObj,
    period,
    productArray
  ) => {
    try {
      let prodId = productArray.map((item) => item.ProductId);
      let payload = {
        ProductId:
          productObj.ProductId == "All" ? prodId : [productObj.ProductId],
        AccountsId: [accId.AccountsId],
        AppCode: AppCode,
        timePeriod: period,
      };

      const response = await global.$axios.post(
        "dashboard/getproductmonthwisecompleteavgtimeCount",
        payload
      );
      if (response.data.Status == 200) {
        seTicketResolutionGraphList(response.data.Message);
      }
    } catch (error) {
      console.log("getproductmonthwisecompleteavgtimeCount", error);
    }
  };

  useEffect(() => {
    getCompayListApi();
  }, []);

  const handleApi = (accId, Value, prodData) => {
    getTicketDetailsApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );
    getOnTimeTicketApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );
    getSlaTicketApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );

    getAvgRespTimeApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );
    getTicketPriorityApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );
    getTrendsGraphApi(accId, Value, "12months", prodData);
    getTicketPrirityGraphApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );
    getChannelDistributionApi(
      accId,
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 15,
      prodData
    );
    getTicketResolutionApi(accId, Value, "12months", prodData);
  };

  return (
    <>
      <Grid container sx={styles.container}>
        <Grid item container xs={12} sm={6}>
          <div>
            <Typography sx={styles.txt}>Welcome Back , {Name}</Typography>
            <Typography sx={styles.txt1}>
              Every Metric, Every Insight – In One Place
            </Typography>
          </div>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <Grid item md={5.7} xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={companyData}
                value={companyValue}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.CompanyName == value?.CompanyName
                }
                getOptionLabel={(option) => option.CompanyName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Company Name" />
                )}
                onChange={(event, newvalue) => {
                  setCompanyValue(newvalue);
                  getProductDataApi(newvalue);
                }}
              />
            </Grid>
            <Grid item md={5.7} xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={productData}
                value={productValue}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.ProductName == value?.ProductName
                }
                getOptionLabel={(option) => option.ProductName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Product" />
                )}
                onChange={(event, newvalue) => {
                  setProductValue(newvalue);
                  handleApi(companyValue, newvalue, productData);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Grid item xs={12} sm={8} sx={{ display: "flex", gap: "15px" }}>
          <div
            style={tab == "overall" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("overall")}
          >
            <span style={tab == "overall" ? styles.actTxt : styles.inactTxt}>
              Overall Insights
            </span>
          </div>
          <div
            style={tab == "recent" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("recent")}
          >
            <span style={tab == "recent" ? styles.actTxt : styles.inactTxt}>
              Recent Insights
            </span>
          </div>
          <div
            style={tab == "last15" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("last15")}
          >
            <span style={tab == "last15" ? styles.actTxt : styles.inactTxt}>
              Last 15 Days Insights
            </span>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          backgroundColor: "#0071BC20",
          border: "1px solid #E3E3E3",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Typography style={styles.txt}>Ticket Overviews</Typography>
          <Typography style={styles.htxt}>{ticketCount?.TotalCount}</Typography>
          <Typography style={styles.txt1}>Total Tickets </Typography>
        </Grid>

        {/* Total Tickets  */}
        <Grid item container xs={12} sx={styles.mainCard}>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>New</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{ticketCount?.New}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Progress</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>
                {ticketCount?.Progress}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Holding</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{ticketCount?.Holding}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Completed</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>
                {ticketCount?.Completed}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Solved</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{ticketCount?.Solved}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>On Time</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{onTimeTicket}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>SLA Breached</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{slaTicket}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Avg Responses Time</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{avgRespTime}</Typography>
            </div>
          </Grid>
        </Grid>

        {/* Ticket priority  */}
        <Grid container sx={styles.container1}>
          <Grid item xs={12}>
            <Typography style={styles.txt1}>Ticket Type </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {priorityList.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={5.5}
                  md={2.3}
                  style={styles.card}
                >
                  <div style={styles.box}>
                    <Typography style={styles.inactTxt}>
                      {item.IssueName}
                    </Typography>
                    <CustomerIcon3 />
                  </div>
                  <div style={styles.btnCard}>
                    <Typography style={styles.txt1}>{item.count}</Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px",
          gap: "20px",
        }}
      >
        {/* Graph 1  */}
        <Grid item xs={12} sm={5.8} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={5}>
              <Typography style={styles.txt1}>Ticket Trend</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={() => handleTrendFreq("12months")}
                style={
                  trendsFreq == "12months"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>12 Months</span>
              </div>
              <div
                onClick={() => handleTrendFreq("6months")}
                style={
                  trendsFreq == "6months" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>6 Months</span>
              </div>
              <div
                onClick={() => handleTrendFreq("30days")}
                style={
                  trendsFreq == "30days" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>30 Days</span>
              </div>
              <div
                onClick={() => handleTrendFreq("7days")}
                style={
                  trendsFreq == "7days" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>7 Days</span>
              </div>
            </Grid>
          </Grid>
          <TicketTrendChart trendsData={trendsData} />
        </Grid>

        {/* Graph 2  */}
        <Grid item xs={12} sm={5.8} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>
                Distribution of Ticket Priorities
              </Typography>
            </Grid>
          </Grid>
          <TicketPriority priorityGraphList={priorityGraphList} />
        </Grid>

        {/* Graph 3  */}
        <Grid item xs={12} sm={5.8} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>
                Ticket Resolution Time{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={() => handleTicketResolutionFreq("12months")}
                style={
                  ticketResolutionFreq == "12months"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>12 Months</span>
              </div>
              <div
                onClick={() => handleTicketResolutionFreq("6months")}
                style={
                  ticketResolutionFreq == "6months"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>6 Months</span>
              </div>
              <div
                onClick={() => handleTicketResolutionFreq("30days")}
                style={
                  ticketResolutionFreq == "30days"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>30 Days</span>
              </div>
              <div
                onClick={() => handleTicketResolutionFreq("7days")}
                style={
                  ticketResolutionFreq == "7days"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>7 Days</span>
              </div>
            </Grid>
          </Grid>

          <TicketResolutionChart
            ticketResolutionGraphList={ticketResolutionGraphList}
          />
        </Grid>

        {/* Graph 4  */}
        <Grid item xs={12} sm={5.8} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>Channel Distribution</Typography>
            </Grid>
          </Grid>
          <ChannelDistribution channelList={channelList} />
        </Grid>
      </Grid>
    </>
  );
}
const styles = {
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  txt: {
    fontSize: "18px",
    color: "#7A7A7A",
    fontWeight: 600,
  },
  txt1: {
    fontSize: "14px",
    color: "#474747",
    fontWeight: 500,
  },
  txt2: {
    fontSize: "14px",
    color: "#7A7A7A",
    fontWeight: 600,
  },
  htxt: {
    fontSize: "40px",
    color: "#232323",
    fontWeight: 500,
  },
  mainCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  card: {
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "12px",
  },
  sub: {
    display: "flex",
    width: "100%",
    gap: "12px",
    alignItems: "center",
    margin: "5px 0px 0px 0px",
  },
  percentageTxt: {
    fontSize: "12px",
    color: "#377E36",
    fontWeight: 500,
    backgroundColor: "#E0F0E4",
    padding: "3px 5px",
    borderRadius: "6px",
  },
  graphCard: {
    border: "1px solid #E3E3E3",
    padding: "10px",
    borderRadius: "10px",
  },
  trendCard: {
    border: "1px solid #B3B3BD",
    padding: "2px 4px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  trendCard1: {
    padding: "2px 4px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  container1: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    padding: "5px",
    borderRadius: "8px",
  },

  box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  btnCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFB6B6BF",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  actbtn: {
    backgroundColor: "#0071BC",
    borderRadius: "6px",
    border: "1px solid #D4D4D4",
    padding: "8px 16px",
    cursor: "pointer",
  },
  inactbtn: {
    backgroundColor: "#fff",
    borderRadius: "6px",
    border: "1px solid #D4D4D4",
    padding: "8px 16px",
    cursor: "pointer",
  },
  actTxt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#fff",
  },
  inactTxt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#232323",
  },
};

export default CustomerDashboard;
