import React, { useEffect, useState } from "react";
import { Typography, Grid, Box } from "@mui/material";

const TicketCard = ({ title, count }) => {
  return (
    <Grid item xs={12} sx={styles.card}>
      <Typography style={styles.txt2}>{title}</Typography>
      <div style={styles.sub}>
        <Typography style={styles.txt}>{count}</Typography>
      </div>
    </Grid>
  );
};

const TicketTypeDistribution = ({ issueList }) => {
  const [tickets, setTickets] = useState([]);
  const colors = ["#8A2BE2", "#40E0D0", "#FFD700", "#6495ED", "#FF6347"];

  useEffect(() => {
    const total = issueList.reduce((sum, item) => sum + item.count, 0);
    const formattedTickets = issueList.map((item, index) => ({
      title: item?.IssueName,
      count: item?.count,
      percentage: ((item.count / total) * 100).toFixed(1), // Calculate percentage
      change: (Math.random() * 10 - 5).toFixed(1), // Random change percentage
      color: colors[index % colors.length], // Assign colors
    }));
    setTickets(formattedTickets);
  }, [issueList]);

  return (
    <Box>
      {/* Progress Bar */}
      <Box sx={{ display: "flex", gap: 0.5, mt: 3, width: "100%" }}>
        {tickets.map((ticket, index) => (
          <Box
            key={index}
            sx={{
              height: 10,
              width: `${ticket.percentage}%`,
              backgroundColor: ticket.color,
              borderRadius: 5,
            }}
          />
        ))}
      </Box>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        {tickets.map((ticket, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <TicketCard {...ticket} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const styles = {
  card: {
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "12px",
  },
  sub: {
    display: "flex",
    width: "100%",
    gap: "12px",
    alignItems: "center",
    margin: "5px 0px 0px 0px",
  },
  txt: {
    fontSize: "18px",
    color: "#7A7A7A",
    fontWeight: 600,
  },

  txt2: {
    fontSize: "14px",
    color: "#7A7A7A",
    fontWeight: 600,
  },
};

export default TicketTypeDistribution;
