import React, { memo, useEffect, useState } from "react";
import Chart from "react-apexcharts";

function TicketTrendChart({ trendsData }) {
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Tickets",
        data: [40, 10, 90, 90, 46, 22, 41, 39, 79, 68, 80, 89], // Example data for each month
      },
    ],
    options: {
      chart: {
        type: "area",
        toolbar: { show: false },
      },
      colors: ["#005DAA"], // Blue color for the line
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.4,
          opacityFrom: 0.8,
          opacityTo: 0.2,
          stops: [0, 100],
        },
      },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 2 },
      xaxis: {
        categories: [
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
        ],
      },
      yaxis: {
        labels: { formatter: (value) => Math.round(value) },
      },
      tooltip: {
        enabled: true,
        x: { format: "MMMM yyyy" },
        y: { formatter: (val) => `${val} Tickets` },
      },
      grid: { show: false },
    },
  });

  useEffect(() => {
    const ticketDate = trendsData.map((item) => item.date);
    const ticketCount = trendsData.map((item) => item.count);

    setChartData((prev) => ({
      ...prev,
      series: [{ name: "Tickets", data: ticketCount }],
      options: {
        ...prev.options,
        xaxis: { ...prev.options.xaxis, categories: ticketDate },
      },
    }));
  }, [trendsData]);

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={250}
    />
  );
}

export default memo(TicketTrendChart);
