import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerIcon3 } from "../../Assets/Icon";
import TicketPriority from "./TicketPriority";
import TicketTrendChart from "./TicketTrendChart";
import TicketStatusGraph from "./TicketStatusGraph";
import Chart from "react-apexcharts";
import TicketTypeDistribution from "./TicketTypeDistributiongraph";

function OverAllDashboard() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Name = global.$userData.logindata.Name;

  const allDepartement = [{ DepartmentName: "All", DepartmentId: "All" }];
  const [DepartmentDrop, SetDepartmentDrop] = useState([]);
  const [DepartmentValue, SetDepartmentValue] = useState({
    DepartmentName: "All",
    DepartmentId: "All",
  });

  const [todayTicket, setTodayTicket] = useState({
    Completed: 0,
    Holding: 0,
    New: 0,
    Progress: 0,
    Solved: 0,
    TotalCount: 0,
  });

  const [tab, setTab] = useState("overall");
  const [trendsFreq, setTrendsFreq] = useState("12months");
  const [overAllTicket, setOverAllTicket] = useState({
    New: "0",
    Completed: "0",
    Holding: "0",
    Progress: "0",
    Solved: "0",
    TotalCount: "0",
  });
  const [ticketStatusGraphList, setTicketStatusGraphList] = useState([]);
  const [onTimeTicket, setOnTimeTicket] = useState(0);
  const [resolvedTicket, setResolvedTicket] = useState(0);
  const [respondTicket, setRespondTicket] = useState(0);
  const [slaTicket, setSlaTicket] = useState("0 %");
  const [avgRespTime, setAvgRespTime] = useState(`0 hours`);
  const [priorityList, setPriorityList] = useState([]);
  const [issueList, setIssueList] = useState([]);
  // graph Data
  const [trendsData, setTrendsData] = useState([]);

  const [comparison, setComparison] = useState([]);
  const [comparison1, setComparison1] = useState([]);
  const [graphStatus, setGraphStatus] = useState("overall");

  const [chartData, setChartData] = useState({
    series: [{ name: "Tickets", data: [] }],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { columnWidth: "50%", borderRadius: 4 } },
      xaxis: { categories: [], labels: { style: { fontSize: "12px" } } },
      yaxis: { title: { text: "Assigned Tickets" } },
      fill: {
        type: "gradient",
        gradient: { shade: "light", type: "vertical" },
      },
    },
  });

  const handleButton = (val) => {
    setTab(val);
    getTicketDetailsApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getReopenedTicketApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getRespondTicketApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getOnTimeTicketApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getSlaTicketApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );

    getAvgRespTimeApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getTicketPriorityApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getIssueListApi(
      DepartmentValue,
      val === "overall" ? "" : val === "recent" ? 30 : 1,
      DepartmentDrop
    );
    getTrendsGraphApi(DepartmentValue, "12months", DepartmentDrop);
    getAgentCOmparisonApi(DepartmentValue, DepartmentDrop);
  };

  const handleTrendFreq = (val) => {
    setTrendsFreq(val);
    getTrendsGraphApi(DepartmentValue, val, DepartmentDrop);
  };

  const DepartementList = async () => {
    const response = await global.$axios.get(
      `/ticket/team/department/${AppCode}/${userId}`
    );
    if (response.data.Status === 200) {
      SetDepartmentDrop([...allDepartement, ...response.data.Message]);
      handleApi(
        { DepartmentName: "All", DepartmentId: "All" },
        response.data.Message
      );
      getTodayTicketListApi(
        { DepartmentName: "All", DepartmentId: "All" },
        response.data.Message
      );
    }
  };

  const getTodayTicketListApi = async (deptObj, deptArray) => {
    let deptId = deptArray.map((item) => item.DepartmentId);
    try {
      let payload = {
        AppCode: AppCode,
        DepartmentId:
          deptObj.DepartmentId === "All" ? deptId : [deptObj.DepartmentId],
      };

      const response = await $axios.post(
        "dashboard/getcurrentdayTicketCount",
        payload
      );
      if (response.data.Status == 200) {
        let tickets = response.data.Message;

        setTodayTicket((prev) => ({
          ...prev,
          Completed: tickets.Completed,
          Holding: tickets.Holding,
          New: tickets.New,
          Progress: tickets.Progress,
          Solved: tickets.Solved,
          TotalCount: tickets.TotalCount,
        }));
      }
    } catch (error) {
      console.log("dashboard/getcurrentdayTicketCount", error);
    }
  };

  const getTicketDetailsApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId === "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };

      const response = await global.$axios.post(
        "dashboard/getalldepartmentTicketCount",
        payload
      );

      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setOverAllTicket((prev) => ({
          ...prev,
          New: ticketValue?.New?.Count || "0",
          Completed: ticketValue?.Completed?.Count || "0",
          Holding: ticketValue?.Holding?.Count || "0",
          Progress: ticketValue?.Progress?.Count || "0",
          Solved: ticketValue?.Solved?.Count || "0",
          TotalCount: ticketValue?.TotalCount || "0",
        }));
        setTicketStatusGraphList(ticketValue.GraphData);
      }
    } catch (error) {
      console.log("getalldepartmentTicketCount", error);
    }
  };

  const getReopenedTicketApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId === "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmentTicketreopendcount",
        payload
      );

      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setResolvedTicket(ticketValue.ReopenTicket || "0");
      }
    } catch (error) {
      console.log("dashboard/getdepartmentTicketreopendcount", error);
    }
  };

  const getRespondTicketApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId === "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmentTicketresolved",
        payload
      );
      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setRespondTicket(ticketValue.TicketSolved || "0");
      }
    } catch (error) {
      console.log("dashboard/getdepartmentTicketresolved", error);
    }
  };

  const getOnTimeTicketApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId === "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmentOntimecount",
        payload
      );
      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setOnTimeTicket(ticketValue.OnTimeCount || 0);
      }
    } catch (error) {
      console.log("getdepartmentOntimecount", error);
    }
  };

  const getSlaTicketApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId == "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmentSLAPercentage",
        payload
      );

      if (response.data.Status == 200) {
        let ticketValue = response.data.Message;
        setSlaTicket(ticketValue.SLA_Percentage || "0 %");
      }
    } catch (error) {
      console.log("getdepartmentSLAPercentage", error);
    }
  };

  const getAvgRespTimeApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId == "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };

      const response = await global.$axios.post(
        "dashboard/getdepartmentcompleteavgtimeCount",
        payload
      );
      if (response.data.Status == 200) {
        let totalSeconds = Number(response.data.Message);
        const hours = Math.floor(totalSeconds / 3600); // Get whole hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Get remaining minutes
        setAvgRespTime(
          hours == 0 ? `${minutes} minutes` : `${hours} hour ${minutes} minutes`
        );
      }
    } catch (error) {
      console.log("getdepartmentcompleteavgtimeCount", error);
    }
  };

  const getTicketPriorityApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId == "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmentPriorityLevelticketcount",
        payload
      );
      if (response.data.Status == 200) {
        setPriorityList(response.data.Message);
      }
    } catch (error) {
      console.log("getdepartmentPriorityLevelticketcount", error);
    }
  };

  const getIssueListApi = async (deptObj, days, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId == "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        filterDays: days,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmentIssueticketsount",
        payload
      );
      if (response.data.Status == 200) {
        let listData = response.data.Message.splice(0, 5);
        setIssueList(listData);
      }
    } catch (error) {
      console.log("getdepartmentIssueticketsount", error);
    }
  };

  // graph Api's
  const getTrendsGraphApi = async (deptObj, period, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId == "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
        timePeriod: period,
      };
      const response = await global.$axios.post(
        "dashboard/getdepartmenttickettrendcount",
        payload
      );

      console.log("getdepartmenttickettrendcount", response.data);

      if (response.data.Status == 200) {
        setTrendsData(response.data.Message);
      }
    } catch (error) {
      console.log("getproductticketTrends", error);
    }
  };

  const getAgentCOmparisonApi = async (deptObj, deptArray) => {
    try {
      let deptId = deptArray.map((item) => item.DepartmentId);
      let payload = {
        DepartmentId:
          deptObj.DepartmentId == "All" ? deptId : [deptObj.DepartmentId],
        AppCode: AppCode,
      };
      const response = await global.$axios.post(
        "dashboard/getTopPerformers",
        payload
      );
      if (response.data.Status == 200) {
        let arrayData = response.data.Message;
        setComparison(arrayData.Last15Performances);
        setComparison1(arrayData.LastMonthPerformance);

        const ticketData = arrayData.Last15Performances.map(
          (item) => item.TicketCount || 0
        );
        const userNames = arrayData.Last15Performances.map(
          (item) => item.UserName
        );

        setChartData((prev) => ({
          ...prev,
          series: [{ name: "Tickets", data: ticketData }],
          options: {
            ...prev.options,
            xaxis: { ...prev.options.xaxis, categories: userNames },
          },
        }));
      }
    } catch (error) {
      console.log("getTopPerformers", error);
    }
  };

  useEffect(() => {
    DepartementList();
  }, []);

  const handleApi = (Value, department) => {
    getTicketDetailsApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getOnTimeTicketApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getReopenedTicketApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getRespondTicketApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getSlaTicketApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getAvgRespTimeApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getTicketPriorityApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getIssueListApi(
      Value,
      tab == "overall" ? "" : tab == "recent" ? 30 : 1,
      department
    );
    getTrendsGraphApi(Value, "12months", department);
    getAgentCOmparisonApi(Value, department);
  };

  const handleGraph = (val) => {
    setGraphStatus(val);
    if (val == "overall") {
      const ticketData = comparison.map((item) => item.TicketCount || 0);

      setChartData((prev) => ({
        ...prev,
        series: [{ name: "On-Time Closed Tickets", data: ticketData }],
      }));
    } else {
      const ticketData = comparison1.map((item) => item.TicketCount || 0);
      setChartData((prev) => ({
        ...prev,
        series: [{ name: "On-Time Closed Tickets", data: ticketData }],
      }));
    }
  };

  return (
    <>
      <Grid container sx={styles.container}>
        <Grid item container xs={12} sm={6}>
          <div>
            <Typography sx={styles.txt}>Welcome Back , {Name}</Typography>
            <Typography sx={styles.txt1}>
              Every Metric, Every Insight – In One Place
            </Typography>
          </div>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <Grid item md={5.7} xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={DepartmentDrop}
                value={DepartmentValue}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.DepartmentName == value?.DepartmentName
                }
                getOptionLabel={(option) => option.DepartmentName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label=" Department" />
                )}
                onChange={(event, newvalue) => {
                  if (newvalue.DepartmentId == "All") {
                    SetDepartmentValue({
                      DepartmentName: "All",
                      DepartmentId: "All",
                    });
                    handleApi(
                      {
                        DepartmentName: "All",
                        DepartmentId: "All",
                      },
                      DepartmentDrop
                    );
                    getTodayTicketListApi(
                      {
                        DepartmentName: "All",
                        DepartmentId: "All",
                      },
                      DepartmentDrop
                    );
                  } else {
                    SetDepartmentValue(newvalue);
                    handleApi(newvalue, DepartmentDrop);
                    getTodayTicketListApi(newvalue, DepartmentDrop);
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Grid item xs={12} sm={8} sx={{ display: "flex", gap: "15px" }}>
          <div
            style={tab == "overall" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("overall")}
          >
            <span style={tab == "overall" ? styles.actTxt : styles.inactTxt}>
              Overall Ticket’s
            </span>
          </div>
          <div
            style={tab == "recent" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("recent")}
          >
            <span style={tab == "recent" ? styles.actTxt : styles.inactTxt}>
              Recent Ticket’s
            </span>
          </div>
          <div
            style={tab == "today" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("today")}
          >
            <span style={tab == "today" ? styles.actTxt : styles.inactTxt}>
              Today Updated Ticket’s
            </span>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          backgroundColor: "#fff",
          border: "1px solid #E3E3E3",
          padding: "10px",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      >
        <Grid item xs={12}>
          <Typography style={styles.htxt}>{todayTicket.TotalCount}</Typography>
          <Typography style={styles.txt1}>
            Today's updated ticket count is displayed
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
          <Typography style={styles.txt1}>Status</Typography>
        </Grid>
        <Grid item container xs={12} sx={styles.mainCard1}>
          <Grid item xs={12} sm={3.4} md={2.1} sx={styles.card1}>
            <Typography style={styles.txt2}>
              New : {todayTicket?.New}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3.4} md={2.1} sx={styles.card1}>
            <Typography style={styles.txt2}>
              Progress : {todayTicket?.Progress}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3.4} md={2.1} sx={styles.card1}>
            <Typography style={styles.txt2}>
              Holding : {todayTicket?.Holding}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3.4} md={2.1} sx={styles.card1}>
            <Typography style={styles.txt2}>
              Completed : {todayTicket?.Completed}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3.4} md={2.1} sx={styles.card1}>
            <Typography style={styles.txt2}>
              Solved : {todayTicket?.Solved}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          display: "flex",
          backgroundColor: "#0071BC20",
          border: "1px solid #E3E3E3",
          padding: "10px",
          borderRadius: "10px",
        }}
      >
        <Grid item xs={12}>
          <Typography style={styles.txt}>Ticket Overviews</Typography>
          <Typography style={styles.htxt}>
            {overAllTicket?.TotalCount}
          </Typography>
          <Typography style={styles.txt1}>Total Tickets </Typography>
        </Grid>

        {/* Total Tickets  */}
        <Grid item container xs={12} sx={styles.mainCard}>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>New</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{overAllTicket?.New}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Progress</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>
                {overAllTicket?.Progress}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Holding</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>
                {overAllTicket?.Holding}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Completed</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>
                {overAllTicket?.Completed}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Solved</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>
                {overAllTicket?.Solved}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Respond</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{respondTicket}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Resolved</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{resolvedTicket}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>On Time</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{onTimeTicket}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>SLA Breached</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{slaTicket}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3.6} md={2.7} sx={styles.card}>
            <Typography style={styles.txt2}>Avg Responses Time</Typography>
            <div style={styles.sub}>
              <Typography style={styles.txt}>{avgRespTime}</Typography>
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ margin: "10px 0px" }}>
          <Typography style={styles.txt1}>Ticket Priority</Typography>
        </Grid>

        <Grid item container xs={12} sx={styles.mainCard}>
          {priorityList.map((item, index) => {
            return (
              <Grid key={index} item xs={12} sm={3.6} md={2.7} sx={styles.card}>
                <Typography style={styles.txt2}>
                  {item.PriorityLevel}
                </Typography>
                <div style={styles.sub}>
                  <Typography style={styles.txt}>{item.Count}</Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>

        {/* Ticket priority  */}
        {/* <Grid container sx={styles.container1}>
          <Grid item xs={12}>
            <Typography style={styles.txt1}>Ticket Type </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {issueList.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  sm={5.5}
                  md={2.3}
                  style={styles.card}
                >
                  <div style={styles.box}>
                    <Typography style={styles.inactTxt}>
                      {item.IssueName}
                    </Typography>
                    <CustomerIcon3 />
                  </div>
                  <div style={styles.btnCard}>
                    <Typography style={styles.txt1}>{item.count}</Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid> */}
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0px",
          gap: "20px",
        }}
      >
        {/* Graph 1  */}
        <Grid item xs={12} sm={12} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={5}>
              <Typography style={styles.txt1}>Ticket Trends</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                onClick={() => handleTrendFreq("12months")}
                style={
                  trendsFreq == "12months"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>12 Months</span>
              </div>
              <div
                onClick={() => handleTrendFreq("6months")}
                style={
                  trendsFreq == "6months" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>6 Months</span>
              </div>
              <div
                onClick={() => handleTrendFreq("30days")}
                style={
                  trendsFreq == "30days" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>30 Days</span>
              </div>
              <div
                onClick={() => handleTrendFreq("7days")}
                style={
                  trendsFreq == "7days" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>7 Days</span>
              </div>
            </Grid>
          </Grid>
          <TicketTrendChart trendsData={trendsData} />
        </Grid>

        {/* Graph 2  */}
        <Grid item xs={12} sm={12} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>Ticket Status</Typography>
            </Grid>
          </Grid>
          <TicketStatusGraph statusList={ticketStatusGraphList} />
        </Grid>

        {/* Graph 3  */}
        <Grid item xs={12} sm={12} md={7.2} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>Agent Comparison </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                onClick={() => handleGraph("overall")}
                style={
                  graphStatus == "overall"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>OverAll</span>
              </div>
              <div
                onClick={() => handleGraph("30days")}
                style={
                  graphStatus == "30days" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>Last Month</span>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={300}
            />
          </Grid>
        </Grid>

        {/* Graph 4  */}
        <Grid item xs={12} sm={12} md={4.4} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>
                Ticket Type Distribution
              </Typography>
            </Grid>
          </Grid>
          <TicketTypeDistribution issueList={issueList} />
        </Grid>

        {/* Graph 5  */}
        <Grid item xs={12} sm={12} md={5.8} style={styles.graphCard}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>Ticket Priorities</Typography>
            </Grid>
          </Grid>
          <TicketPriority priorityGraphList={priorityList} />
        </Grid>
      </Grid>
    </>
  );
}
const styles = {
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  txt: {
    fontSize: "18px",
    color: "#7A7A7A",
    fontWeight: 600,
  },
  txt1: {
    fontSize: "14px",
    color: "#474747",
    fontWeight: 500,
  },
  txt2: {
    fontSize: "14px",
    color: "#7A7A7A",
    fontWeight: 600,
  },
  htxt: {
    fontSize: "40px",
    color: "#232323",
    fontWeight: 500,
  },
  mainCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  mainCard1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "18px",
    flexWrap: "wrap",
    backgroundColor: "#0071BC33",
    borderRadius: "14px",
    padding: "14px 16px",
  },
  card: {
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "12px",
  },
  card1: {
    display: "flex",
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "12px",
    alignItems: "center",
    justifyContent: "center",
  },
  sub: {
    display: "flex",
    width: "100%",
    gap: "12px",
    alignItems: "center",
    margin: "5px 0px 0px 0px",
  },
  percentageTxt: {
    fontSize: "12px",
    color: "#377E36",
    fontWeight: 500,
    backgroundColor: "#E0F0E4",
    padding: "3px 5px",
    borderRadius: "6px",
  },
  graphCard: {
    border: "1px solid #E3E3E3",
    padding: "10px",
    borderRadius: "10px",
  },
  trendCard: {
    border: "1px solid #B3B3BD",
    padding: "2px 4px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  trendCard1: {
    padding: "2px 4px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  container1: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px 0px",
    padding: "5px",
    borderRadius: "8px",
  },

  box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  btnCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFB6B6BF",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  actbtn: {
    backgroundColor: "#0071BC",
    borderRadius: "6px",
    border: "1px solid #D4D4D4",
    padding: "8px 16px",
    cursor: "pointer",
  },
  inactbtn: {
    backgroundColor: "#fff",
    borderRadius: "6px",
    border: "1px solid #D4D4D4",
    padding: "8px 16px",
    cursor: "pointer",
  },
  actTxt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#fff",
  },
  inactTxt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#232323",
  },
};

export default OverAllDashboard;
