import { Avatar, Button, Grid, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import { DeleteIcon, EditIcon } from "../Assets/Icon";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProfileEdit from "../Components/ProfileEdit";
import { toast, ToastContainer } from "react-toastify";

function Profile() {
  const dispatch = useDispatch();
  const sessiondata = useSelector((state) => state.user);
  const AppCode = sessiondata.logindata.AppCode;
  const userId = sessiondata.logindata.UserId;
  const config = sessiondata.logindata.crm_app_license_master;
  const userData = sessiondata.logindata;
  const [openProfile, setOpenProfile] = useState(false);
  const [AttachmentLink, setAttachmentLink] = useState("");
  const [configdailog, setconfigdailog] = useState(false);
  const [checked, setChecked] = useState(
    config.internal_ticket == "Yes" ? true : false
  );
  const [request, setrequest] = useState(false);
  const [company, setCompany] = useState(null);
  const [companyDrop, setCompanyDrop] = useState([]);
  const [contact, setContact] = useState(null);
  const [contactDrop, setContactDrop] = useState([]);

  const handleChange = () => {
    if (!checked) {
      setrequest(true);
      companyList();
    } else {
      configapi(true);
    }
    setChecked(!checked);
  };

  const configapi = async (value) => {
    if ((!value && contact == null) || (!value && company == null)) {
      toast.error("Please Fill The Required Field");
      return;
    }
    try {
      const { data } = await global.$axios.post(`/auth/internalConfig`, {
        AppCode: AppCode,
        UserId: userId,
        Config: !value ? "Yes" : "No",
        Intcompany: !value ? company.AccountsId : "",
        Intcontact: !value ? contact.ContactId : "",
      });
      if (data.Status === 200) {
        toast.success("Config Updated Successfully");
        setrequest(false);
        setCompany(null);
        setContact(null);
        dispatch({
          type: "SET_USER_IN",
          logindata: data.Message,
          payload: true,
        });
        if (value) {
          setconfigdailog(false);
        }
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (error) {
      console.error("configupdate", error);
    }
  };
  const handleFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e);
    handleUploadClick(chosenFiles);
  };
  const handleClose = () => {
    setconfigdailog(!configdailog);
  };

  const handleClose1 = () => {
    setconfigdailog(true);
    setChecked(config.internal_ticket == "Yes" ? true : false);
    setrequest(!request);
  };

  const handleUploadClick = async (file) => {
    {
      file.forEach(async (files, index) => {
        var formData = new FormData();
        formData.append("file", files);
        const response = await global.$axios
          .post("ticket/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then(async (response) => {
            if (response.data.Status === 200) {
              setAttachmentLink(response.data.Message);
              submitUpload(response.data.Message, "");
            } else {
              toast.error("Something Went Wrong");
            }
          })

          .catch((e) => {
            console.error("Upload API Issue", e);
          });
      });
    }
  };

  const submitUpload = async (link, from) => {
    try {
      const payload = {
        AppCode: AppCode,
        UserId: userId,
        ProfileImageLink: link,
      };
      const response = await global.$axios.post(
        "userinfo/uploadImage",
        payload
      );
      console.log("esponse.data", response.data);

      if (response.data.Status === 200) {
        if (from == "remove") {
          toast.success("Profile Removed Successfully");
        } else {
          toast.success("Profile Updated Successfully");
        }

        let payload1 = {
          AppCode: AppCode,
          UserId: userId,
          source: "Web",
        };
        const response1 = await $axios.post("auth/getuserauthInfo", payload1);
        if (response1.data.Status == 200) {
          dispatch({
            type: "SET_USER_IN",
            logindata: response1.data.Message,
            payload: true,
          });
        }
      }
    } catch (error) {
      console.log("submitUpload Issue", error);
    }
  };

  const companyList = async () => {
    try {
      const response = await global.$axios
        .get(`/ticket/companies/${AppCode}`)
        .then(async (response) => {
          setCompanyDrop(response.data.Message);
        })
        .catch((e) => {
          console.log("Company List Issue", e);
        });
    } catch (error) {
      console.error("compaylist", error);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      <Grid container style={styles.container}>
        <Grid item xs={6}>
          <Typography variant="h6">Profile</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            style={styles.rowRight}
            onClick={() => setOpenProfile(true)}
          >
            <EditIcon style={{ color: "#0071BC" }} height={20} width={20} />
            <Typography sx={{ fontSize: "16px", color: "#0071BC" }}>
              Edit
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12} style={styles.card1}>
          <Link target="blank" to={userData.ProfilePictureLink || ""}>
            <Avatar
              alt={userData.UserName}
              src={userData.ProfilePictureLink || ""}
              sx={{ width: 100, height: 100 }}
            />
          </Link>
          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={styles.txt}>{userData.Name}</span>
            <span style={styles.txt}>{userData.UserEmailId}</span>
            <span style={styles.txt}>{userData.UserMobileNumber}</span>
          </Grid>
        </Grid>
        <Grid item container xs={12} style={styles.card1}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            size="small"
          >
            Update new Profile
            <VisuallyHiddenInput
              type="file"
              onChange={(event) => handleFileChange(event.target.files)}
              mutiple
            />
          </Button>
          <Button
            variant="outlined"
            size="small"
            startIcon={
              <DeleteIcon style={{ color: "#d32f2f" }} height={18} width={18} />
            }
            color="error"
            onClick={() => submitUpload("", "remove")}
          >
            Remove
          </Button>
        </Grid>
      </Grid>
      {openProfile && (
        <ProfileEdit
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
        />
      )}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}

const styles = {
  container: {
    backgroundColor: "#F3FAFF",
    borderColor: "#E3F4FF",
    borderWidth: 1,
    borderRadius: "12px",
    padding: "10px",
    margin: "10px 0px",
  },
  rowRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "6px",
    backgroundColor: "#CCE6FA",
    borderRadius: "12px",
    padding: "5px 0px",
  },
  card1: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    margin: "10px 0px",
  },
  txt: {
    fontSize: "16px",
    color: "#232323",
  },
};

export default Profile;
