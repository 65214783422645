import { Box, Button, Grid, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDemoData } from "@mui/x-data-grid-generator";
import moment from "moment";
import { ViewIcon } from "../../Assets/Icon";
import { Navigate, useNavigate } from "react-router-dom";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";

function Taggedsolved({ Id, DepartmentValue }) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  //Usestate
  const [TagSolved, SetTagSolved] = useState([]);
  const [TagSolvedSearch, SetTagSolvedSearch] = useState([]);
  const [Active, SetActive] = useState(true);
  const Navigate = useNavigate();
  //Api
  const tableapi = async () => {
    const response = await global.$axios
      .post(`ticket/Tagusersolvedlist/${AppCode}`, {
        Taguser: [userId],
      })
      .then(async (response) => {
        SetTagSolved(response.data.Message);
        SetTagSolvedSearch(response.data.Message);
      });
  };
  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = TagSolved.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        // const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          // Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      SetTagSolvedSearch(ticketData);
    } else {
      SetTagSolvedSearch(TagSolved);
    }
  }
  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    maxColumns: 6,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "desc" }],
      },
    },
  });
  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "AttachmentFile" || "",
      headerName: "Actions",
      width: 80,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues) => {
        return (
          <>
            {/* <Tooltip title={"Clone Ticket"} style={{ paddingRight: "5px" }}>
              <RepeatSharpIcon size="small" />
            </Tooltip> */}
            <Tooltip title="View Ticket">
              <ViewIcon
                style={{ width: "20px", height: "20px" }}
                onClick={() =>
                  Navigate("TaggedView", {
                    state: { data: cellValues.row },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
      align: "center",
      headerAlign: "center",
    },

    // {
    //   field: "CompanyName",
    //   headerName: "Company Name",
    //   width: 300,
    //   headerClassName: "super-app-theme--header",
    //   valueGetter: (fields) => fields?.row?.contact?.account?.CompanyName,
    // },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },
    {
      field: "Status" || "",
      headerName: "Status",
      width: 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "ECAt",
      headerName: "Time To Complete",
      width: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.ECAt
          ? moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YY hh.mm A"
            )
          : "-",
    },

    {
      field: "TicketClosedTime" || "",
      headerName: "Closed Time",
      width: 190,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.TicketClosedTime !== ""
          ? moment(fields?.row?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
      align: "center",
      headerAlign: "center",
    },
    // {
    //     field: "created" || "",
    //     headerName: "Assigned By",
    //     width: 200,
    //     headerClassName: "super-app-theme--header",
    //     valueGetter: CreatedFullName,
    // },
  ];
  useEffect(() => {
    tableapi();
  }, []);

  return (
    <>
      <Grid item xs={12} sm={4.7} md={2.7} lg={2.7} style={{ padding: "5px" }}>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "5px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
              width: "100%",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <div style={{ height: 480, width: "100%" }}>
        <Paper style={{ marginTop: "10px" }}>
          <Box
            sx={{
              height: 500,
              width: "100%",
              "& .super-app-theme--header": {
                color: "#0071BC",
              },
            }}
          >
            <DataGridPremium
              sx={{
                ".MuiTablePagination-selectLabel": {
                  margin: "0px",
                },
                ".MuiTablePagination-displayedRows": {
                  margin: "0px",
                },
              }}
              rowHeight={34}
              pagination={true}
              pageSize={10}
              pageSizeOptions={[5, 10, 25, 50, 100]}
              {...data}
              apiRef={apiRef}
              columns={columns}
              rows={TagSolvedSearch ? TagSolvedSearch : []}
              getRowId={(row) => row.TicketId}
              loading={loading}
              disableRowSelectionOnClick
              initialState={initialState}
              slots={{ toolbar: GridToolbar }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            />
          </Box>
        </Paper>
      </div>
    </>
  );
}

export default Taggedsolved;
