import MainRoutes from "./Component/routes/MainRoutes";
import "react-toastify/dist/ReactToastify.css";
import "../src/Component/global";
import { useDispatch, useSelector } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";
import "./global.css";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

LicenseInfo.setLicenseKey(
  "8302d402dde21265ceb14b45663b02ddTz02MjI4NSxFPTE3MTA3NDc3MjMwNDgsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);
function App() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const sessiondata = useSelector((state) => state.user);
  global.$userData = sessiondata;

  useEffect(() => {
    if (sessiondata.logged_in) {
      (async () => {
        let payload1 = {
          AppCode: sessiondata.logindata.AppCode,
          UserId: sessiondata.logindata.UserId,
          source: "Web",
        };
        const response1 = await $axios.post("auth/getuserauthInfo", payload1);
        if (response1.data.Status == 200) {
          dispatch({
            type: "SET_USER_IN",
            logindata: response1.data.Message,
            payload: true,
          });
        }
      })();
    }
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await $axios.get(
          `auth/verifyToken/${sessiondata.logindata.token}`
        );
        if (response.data.Status !== 200) {
          dispatch({ type: "SET_USER_OUT", payload: false });
          localStorage.clear();
          Navigate("/auth");
        }
      } catch (error) {
        console.error("Token verification failed:", error);
        dispatch({ type: "SET_USER_OUT", payload: false });
        localStorage.clear();
        Navigate("/auth");
      }
    };

    if (sessiondata.logged_in) {
      verifyToken();
    }
  }, [sessiondata.logged_in, Navigate]);

  return (
    <div className="App">
      <MainRoutes />
    </div>
  );
}

export default App;
