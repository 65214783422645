import { Box, Grid, Paper, Tooltip } from "@mui/material";
import {
  DataGridPremium,
  GridSearchIcon,
  GridToolbar,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import moment from "moment";
import React, { memo } from "react";
import clsx from "clsx";
import { ViewIcon } from "../../../Assets/Icon";
import { useDemoData } from "@mui/x-data-grid-generator";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const CProgressTable = ({ AccountsId, FromD, ToD }) => {
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;

  const [CProgress, setCProgress] = useState([]);
  const [CProgressSearch, setCProgressSearch] = useState([]);

  //APIs

  const ProgressList = async () => {
    const response = await global.$axios
      .post(`/ticket/clientdaywisegetProgressTickets`, {
        AccountsId: [AccountsId],
        AppCode: AppCode,
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {
        setCProgress(response.data.Message);
        setCProgressSearch(response.data.Message);
      })
      .catch((e) => {
        console.error("Client Progress List Issue", e);
      });
  };

  //Mui Premium

  //Default Sorting
  const [sortModel, setSortModel] = React.useState([
    {
      field: "TicketId",
      sort: "desc",
    },
  ]);

  const columns = [
    {
      field: "TicketId",
      headerName: "ID",
      width: 80,
      headerClassName: "super-app-theme--header",
      align: "center",
      cellClassName: (params) => {
        let startDate = dayjs();
        var date = `${startDate.$d.getFullYear()}-${
          startDate.$d.getMonth() + 1 < 10
            ? "0" + (startDate.$d.getMonth() + 1)
            : startDate.$d.getMonth() + 1
        }-${
          startDate.$d.getDate() < 10
            ? "0" + startDate.$d.getDate()
            : startDate.$d.getDate()
        }`;
        var date3 = moment(params.row.ECAt, "YYYY-MM-DD hh:mm A").format(
          "YYYY-MM-DD"
        );
        var date4 = moment(
          params.row.TicketClosedTime,
          "YYYY-MM-DD hh:mm A"
        ).format("YYYY-MM-DD");
        return clsx("super-app", {
          Sla: date3 < date && date4 > date3,
        });
      },
    },
    // {
    //   field: "AttachmentFile" || "",
    //   headerName: "Actions",
    //   width: 80,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (cellValues) => {
    //     return (
    //       <>
    //         <Tooltip title="View Ticket">
    //           <ViewIcon
    //             style={{ width: "20px", height: "20px" }}
    //             onClick={() =>
    //               Navigate("/app/TicketView", {
    //                 state: { data: cellValues.row },
    //               })
    //             }
    //           />
    //         </Tooltip>
    //       </>
    //     );
    //   },
    //   align: "center",
    //   headerAlign: "center",
    // },

    {
      field: "CompanyName",
      headerName: "Company Name",
      width: 300,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.account?.CompanyName ?? "-",
    },
    {
      field: "ContactName",
      headerName: "Contact Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.contact?.ContactName ?? "-",
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.product?.ProductName || "",
    },
    {
      field: "IssueName",
      headerName: "Issue Name",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => fields?.row?.issue?.IssueName || "",
    },
    {
      field: "TicketDescription",
      headerName: "Description",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        var space = fields?.row?.TicketDescription.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },

    {
      field: "ECAt",
      headerName: "Time To Complete",
      width: 160,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.ECAt
          ? moment(fields?.row?.ECAt, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YY hh.mm A"
            )
          : "-",
    },

    {
      field: "TicketClosedTime" || "",
      headerName: "Closed Time",
      width: 190,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.TicketClosedTime !== ""
          ? moment(fields?.row?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "created" || "",
      headerName: "Assigned By",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) =>
        fields?.row?.created
          ? `${fields?.row?.created?.UserFirstName || ""} ${
              fields?.row?.created?.UserLastName || ""
            }`
          : fields?.row?.contact?.ContactName,
    },
    {
      field: "assigned" || "",
      headerName: "Assigned To",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return `${fields?.row?.assigned?.UserFirstName || ""} ${
          fields?.row?.assigned?.UserLastName || ""
        }`;
      },
    },
  ];
  const { data, loading } = useDemoData({
    dataSet: "Status",
    rowLength: 100,
    maxColumns: 6,
    editable: true,
    visibleFields: ["Status"],
  });
  const apiRef = useGridApiRef();

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...data.initialState,
      pagination: { paginationModel: { pageSize: 10 } },
      rowGrouping: {
        ...data.initialState?.rowGrouping,
        model: [""],
      },
      sorting: {
        sortModel: [{ field: "__row_group_by_columns_group__", sort: "desc" }],
      },
    },
  });

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = CProgress.filter((item) => {
        const TicketId = String(item.TicketId);
        const CompanyName = String(item?.contact?.account?.CompanyName);
        const ContactName = String(item?.contact?.ContactName);
        const ProductName = String(item?.product?.ProductName);
        const IssueName = String(item?.issue?.IssueName);
        const UpdatedBy = String(item?.updated?.UserFirstName);
        const ETC = String(
          moment(item?.ECAt, "YYYY-MM-DD hh:mm").format("DD-MM-YYYY hh:mm A")
        );
        const ClosedTime = String(
          moment(item?.TicketClosedTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );
        const Description = String(item?.TicketDescription);
        const AssignedBy = String(item?.created?.UserFirstName);
        const AssignedTo = String(item?.assigned?.UserFirstName);
        const Status = String(item?.Status);

        const text_data = term.toLowerCase();
        return (
          TicketId.indexOf(text_data) > -1 ||
          CompanyName.toLowerCase().indexOf(text_data) > -1 ||
          ContactName.toLowerCase().indexOf(text_data) > -1 ||
          ProductName.toLowerCase().indexOf(text_data) > -1 ||
          IssueName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          ClosedTime.toLowerCase().indexOf(text_data) > -1 ||
          Description.toLowerCase().indexOf(text_data) > -1 ||
          AssignedBy.toLowerCase().indexOf(text_data) > -1 ||
          AssignedTo.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1 ||
          Status.toLowerCase().indexOf(text_data) > -1
        );
      });
      // if (!ticketData.length) {
      //   ticketData = New.filter((item) => {
      //     const TicketId = String(item.TicketId);
      //     const OwnerName = item?.dept?.DepartementName;
      //     const text_data = term;
      //     return TicketId.includes(text_data) || OwnerName.includes(text_data);
      //   });
      // }
      setCProgressSearch(ticketData);
    } else {
      setCProgressSearch(CProgress);
    }
  }

  useEffect(() => {
    ProgressList();
  }, [AccountsId, FromD, ToD]);
  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <p style={{ margin: "0px", fontSize: "16px", fontWeight: 600 }}>
          Progress
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            List
          </span>
        </p>
        <Box
          id="searcBox"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />

          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <Paper>
        <Box
          sx={{
            height: 500,
            width: "100%",
            "& .super-app-theme--header": {
              color: "#0071BC",
            },
          }}
        >
          <DataGridPremium
            sx={{
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
              "& .super-app.Sla": {
                backgroundColor: "rgb(252,204,203)",
              },
              "& .super-app-theme--New": {
                backgroundColor: "rgb(229,243,253)",
                "&:hover": {
                  backgroundColor: "rgb(179,229,251)",
                },
              },
            }}
            rowHeight={34}
            pagination={true}
            pageSize={10}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            {...data}
            apiRef={apiRef}
            columns={columns}
            rows={CProgressSearch ? CProgressSearch : []}
            getRowId={(row) => row.TicketId}
            loading={loading}
            disableRowSelectionOnClick
            initialState={initialState}
            slots={{ toolbar: GridToolbar }}
            sortModel={sortModel}
            onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.Status}`
            }
          />
        </Box>
      </Paper>
    </>
  );
};

export default memo(CProgressTable);
