import { Card, Checkbox, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function AppConfig() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [category, setCategory] = useState(false);
  const [isSource, setIsSource] = useState(false);
  const dispatch = useDispatch();
  const [sourceList, setSourceList] = useState([]);

  const onChangecategory = async () => {
    setCategory(!category);
    let payload = { AppCode: AppCode, Ticketcategory: category ? "No" : "Yes" };
    const response = await global.$axios.post(
      "userinfo/updatecrmappcode",
      payload
    );
    if (response.data.Status == 200) {
      toast.success("Config Updated !");
      let payload1 = {
        AppCode: AppCode,
        UserId: userId,
      };

      const updateResp = await $axios.post("auth/getuserauthInfo", payload1);

      if (updateResp.data.Status == 200) {
        dispatch({
          type: "SET_USER_IN",
          logindata: updateResp.data.Message,
          payload: true,
        });
      }
    } else {
      toast.error(response.data.Message);
    }
  };

  const onChangeSource = async (id) => {
    setSourceList((prevList) =>
      prevList.map((item) => ({
        ...item,
        DefaultSource: item.TicketSourceId === id ? "Yes" : "No", // Set clicked item to Yes, all others to No
      }))
    );
    let payload = {
      DefaultSource: "Yes",
      AppCode: AppCode,
      TicketSourceId: id,
    };
    console.log("payload", payload);

    const response = await global.$axios.post(
      "auth/Ticketsourceconfig",
      payload
    );
    console.log("response", response.data);

    if (response.data.Status == 200) {
      toast.success("Ticket Source Updated !");
    } else {
      toast.error(response.data.Message);
    }
  };

  useEffect(() => {
    getConfigList();
    getTicketSourceApi();
  }, []);

  const getConfigList = async () => {
    try {
      const response = await global.$axios.get(
        `userinfo/getcrmappcode/${AppCode}`
      );
      if (response.data.Status == 200) {
        let configData = response.data.Message;

        setCategory(configData.Ticketcategory == "Yes" ? true : false);
      }
    } catch (error) {
      console.log("userinfo/getcrmappcode", error);
    }
  };

  const getTicketSourceApi = async () => {
    try {
      const response = await global.$axios.get(`source/activelist/${AppCode}`);
      // console.log("getTicketSourceApi", JSON.stringify(response.data.Message));

      if (response.data.status == 200) {
        setSourceList(response.data.Message);
      }
    } catch (error) {
      console.log("source/activelist", error);
    }
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <Grid
        container
        style={{
          // display: "flex",
          alignItems: "flex-start",
          margin: "10px 0px",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        <Grid xs={12} sm={6} md={4} style={styles.card1}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: 500, color: "#232323" }}
          >
            Ticket Source{" "}
          </Typography>
          <Grid item xs={12} height={250} style={{ overflowY: "scroll" }}>
            {sourceList.map((item, index) => {
              return (
                <div key={index} style={styles.row}>
                  <span>{item.TicketSourceName}</span>
                  <Checkbox
                    checked={item.DefaultSource === "Yes"}
                    onChange={() => onChangeSource(item.TicketSourceId)}
                  />
                </div>
              );
            })}
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} md={4} style={styles.card1}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: 500, color: "#232323" }}
          >
            Ticket Category{" "}
          </Typography>
          <Grid item xs={12} height={250}>
            <div style={styles.row}>
              <Typography variant="body1">Category </Typography>
              <Checkbox checked={category} onChange={onChangecategory} />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}

const styles = {
  card: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px",
    backgroundColor: "#F3FAFF",
    borderColor: "#EAECF0",
    borderWidth: "1px",
    borderRadius: "8px",
  },
  card1: {
    padding: "8px",
    backgroundColor: "#F3FAFF",
    borderColor: "#EAECF0",
    borderWidth: "1px",
    borderRadius: "8px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

export default AppConfig;
