import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const ProductSubCategoryPopup = ({
  Refresh,
  setRefresh,
  ProductSubCategoryOpen,
  SetProductSubCategoryOpen,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [age, setAge] = useState([]);
  const [dropdownvalue, setDropdownValue] = useState([]);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [RecordId, setRecordId] = useState("");

  const handleClose = () => SetProductSubCategoryOpen(false);

  var d = 1;
  function getTimestampInSeconds() {
    d++;
    setRecordId(Math.floor(Date.now() / 1000) + AppCode + d);
  }
  const subProduct = async () => {
    if (age == "" || age == null || subCategoryName == "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        CategoryId: age?.CategoryId,
        SubcategoryName: subCategoryName,
        UserId: userId,
        RecordId: RecordId,
      };
      const response = await global.$axios
        .post(`subcategory/add`, data)
        .then(async (response) => {
          setSubCategoryName("");
          setRefresh(!Refresh);
          handleClose();
          toast.success("SubCategory Added");
        })
        .catch((e) => {
          console.error("productsubdadd", e);
        });
    }
  };

  const categoryList = async () => {
    const dropdownResponse = await global.$axios
      .get(`subcategory/categorylist/${AppCode}`)
      .then(async (dropdownResponse) => {
        setDropdownValue(dropdownResponse.data.message);
      })
      .catch((e) => {
        console.error("productsub catagorydrop", e);
      });
  };
  useEffect(() => {
    categoryList();
    getTimestampInSeconds();
  }, []);
  const [err, seterr] = useState(0);

  const validation = async (val) => {
    // subcategory/productsubcategoryvalidate/:AppCode/:ProductSubcategoryName
    const response = await global.$axios
      .get(`/subcategory/productsubcategoryvalidate/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("productsub valudition", e);
      });
  };
  return (
    <div>
      <Modal
        open={ProductSubCategoryOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Add Product SubCategory
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container xs={12}>
            <p
              style={{
                fontSize: "14px",
                margin: "0px  ",
              }}
            >
              Product Category *
            </p>

            <Autocomplete
              fullWidth
              size="small"
              id="Designation"
              options={dropdownvalue}
              getOptionLabel={(option) => option.ProductCategoryName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Category "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setAge(newValue);
              }}
            />
          </Grid>
          <Grid container xs={12}>
            <p
              style={{
                fontSize: "14px",
                margin: "0px  ",
                paddingTop: "10px",
              }}
            >
              Product SubCategory *
            </p>
            <TextField
              fullWidth
              value={subCategoryName}
              onKeyUp={(e) => {
                e.target.value !== "" && validation(e.target.value);
              }}
              onChange={(r) => setSubCategoryName(r.target.value)}
              error={err === 1 ? true : false}
              helperText={
                err === 1 ? (
                  <span style={{ color: "red" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>KJHGF</span>
                )
              }
              placeholder="Add SubCategory Here"
              id="outlined-size-small"
              size="small"
              required
            />
          </Grid>
          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={err === 1 ? true : false}
              onClick={() => {
                subProduct();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default ProductSubCategoryPopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
