import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Logincolor,
  SolvedIcon,
  CompletedIcon,
  HoldIcon,
  NewIcon,
  ProgressIcon,
} from "../../Assets/Icon";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Delayedticket() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Navigate = useNavigate();

  let value = [1, 2, 34, 75];
  const [ticketData, setticketData] = useState([]);
  const delayedticket = async () => {
    const { data } = await global.$axios.get(
      `/dashboard/delayedTicket/${AppCode}/${userId}`
    );
    if (data.status === 200) {
      setticketData(data.message);
    } else {
      setticketData([]);
    }
  };
  function delayedtic(ECAt) {
    const value = new Date().toLocaleDateString().split("/").join("-");
    const today = new Date();
    const givenDate = new Date(ECAt);

    const difference = Math.abs(today - givenDate);
    const days = Math.floor(difference / (1000 * 3600 * 24));
    return days;
  }
  useEffect(() => {
    delayedticket();
  }, []);

  return (
    <>
      <Grid container>
        <Typography variant="body1" sx={{ py: 1, fontSize: 14, px: 1 }}>
          Delayed Ticket{" "}
          <span
            style={{
              borderRadius: "80px",
              color: "#fff",
              background: "#0071BC",
              padding: "1px 5px",
              fontSize: "14px",
            }}
          >
            {ticketData.length}
          </span>
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          borderRadius: 2,
          px: 1,
          overflowY: "scroll",
          maxHeight: { xs: "30.2vh", md: "17.5vh" },
        }}
      >
        {ticketData.map((x) => {
          return (
            <>
              <Grid
                container
                item
                xs={12}
                onClick={() =>
                  Navigate("ticketview", {
                    state: { data: x, Table: ticketData },
                  })
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  background: "#fff",
                  borderRadius: 1,
                  p: 0.6,
                  m: 0.2,
                  cursor: "pointer",
                  boxShadow: 1,
                }}
              >
                <Grid
                  item
                  xs={5}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Logincolor
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#0071BC",
                    }}
                  />
                  <Typography
                    sx={{ textOverflow: "ellipsis" }}
                    style={{
                      paddingLeft: "5px",
                      color: "#3A3A3A75",
                      fontSize: "14px",
                    }}
                  >
                    Ticket Id -{" "}
                    <span style={{ fontWeight: 600, color: "#232323" }}>
                      {x.TicketId}
                    </span>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={7}
                  sx={{ justifyContent: "end", display: "flex" }}
                >
                  <span
                    style={{
                      paddingRight: "5px",
                      color: "#3A3A3A75",
                      fontSize: "14px",
                    }}
                  >
                    <span style={{ color: "#232323", fontWeight: 600 }}>
                      {x.ECAt ? delayedtic(x.ECAt) : "-"}
                    </span>{" "}
                    Days
                    {/* {moment(x.ECAt).format("DD-MM-YYYY")} */}
                  </span>
                  {x.TicketStatus === 1 ? (
                    <NewIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#0071BC",
                      }}
                    />
                  ) : x.TicketStatus === 2 ? (
                    <ProgressIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#0071BC",
                      }}
                    />
                  ) : x.TicketStatus === 3 ? (
                    <HoldIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#0071BC",
                      }}
                    />
                  ) : x.TicketStatus === 4 ? (
                    <CompletedIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#0071BC",
                      }}
                    />
                  ) : (
                    <SolvedIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#0071BC",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
}

export default Delayedticket;
