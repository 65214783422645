import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import dayjs from "dayjs";
import DateRangeSharpIcon from "@mui/icons-material/DateRangeSharp";
import CalendarMonthSharpIcon from "@mui/icons-material/CalendarMonthSharp";
import TodayTwoToneIcon from "@mui/icons-material/TodayTwoTone";
import CAllTable from "./ReportComponents/Client Reports/CAllTable";
import CNewTable from "./ReportComponents/Client Reports/CNewTable";
import CProgressTable from "./ReportComponents/Client Reports/CProgressTable";
import CHoldTable from "./ReportComponents/Client Reports/CHoldTable";
import CCompletedTable from "./ReportComponents/Client Reports/CCompletedTable";
import CSolvedTable from "./ReportComponents/Client Reports/CSolvedTable";
import { DateRangePicker } from "@mui/x-date-pickers-pro";

function ClientReport() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  var CContact = localStorage.getItem("Client Contact");
  const [ContactShow, setContactShow] = useState(null);
  const [ContactDrop, setContactDrop] = useState([]);

  const [companyDrop, setCompanyDrop] = useState([]);
  const [companyValue, setCompanyValue] = useState({});
  const [companyShow, setCompanyShow] = useState([]);
  const [TCount, setTCount] = useState("");
  const [SLACount, setSLACount] = useState("");
  var CDate = localStorage.getItem("ClientTime");
  var spli = CDate && CDate.split(",");
  //Date Button
  var today = dayjs();
  var prevWeek = today.subtract(1, "year");
  var prevMonth = today.subtract(1, "month");

  const [Date, setDate] = useState([dayjs(), dayjs()]);

  const [Date1, setDate1] = useState([
    spli !== null ? dayjs(spli[0]) : dayjs(),
    spli !== null ? dayjs(spli[1]) : dayjs(),
  ]);

  console.log("companyValue", companyValue);

  var DForm =
    Date[0] &&
    `${Date[0].$d.getFullYear()}-${
      Date[0].$d.getMonth() + 1 < 10
        ? "0" + (Date[0].$d.getMonth() + 1)
        : Date[0].$d.getMonth() + 1
    }-${
      Date[0].$d.getDate() < 10
        ? "0" + Date[0].$d.getDate()
        : Date[0].$d.getDate()
    }`;
  var DForm1 =
    Date[1] &&
    `${Date[1].$d.getFullYear()}-${
      Date[1].$d.getMonth() + 1 < 10
        ? "0" + (Date[1].$d.getMonth() + 1)
        : Date[1].$d.getMonth() + 1
    }-${
      Date[1].$d.getDate() < 10
        ? "0" + Date[1].$d.getDate()
        : Date[1].$d.getDate()
    }`;

  const [ClientTab, setClientTab] = useState("All");
  //APIs

  const getCompanyListApi = async () => {
    try {
      const response = await global.$axios.get(`ticket/companies/${AppCode}`);
      if (response.data.Status == 200) {
        setCompanyDrop(response.data.Message);
        setCompanyValue(response.data.Message[0]);
        TotalCount(
          response.data.Message[0].AccountsId,
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
        SlaCount(
          response.data.Message[0].AccountsId,
          spli != null ? spli[0] : DForm,
          spli != null ? spli[1] : DForm1
        );
      }
    } catch (error) {
      console.log("ticket/companies", error);
    }
  };

  const TotalCount = async (id, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/daycountclient/${AppCode}`, {
        AccountsId: [id],
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {
        setTCount(response.data.Message[0]);
      })
      .catch((e) => {
        console.error("Client Total Count Issue", e);
      });
  };

  const SlaCount = async (id, FromD, ToD) => {
    const response = await global.$axios
      .post(`/ticket/clientdaySLA`, {
        AccountsId: [id],
        AppCode: AppCode,
        frequency: 0,
        fromday: FromD,
        currentday: ToD,
      })
      .then(async (response) => {
        setSLACount(response.data.Message[0]);
      })
      .catch((e) => {
        console.error("Client SLA Count Issue");
      });
  };

  useEffect(() => {
    setClientTab(
      localStorage.getItem("Client Tab") === null
        ? "All"
        : localStorage.getItem("Client Tab")
    );
    getCompanyListApi();
  }, []);
  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              color: "#0071BC",
              fontWeight: 600,
              fontSize: "16px",
              margin: "0px",
              padding: "0px",
              cursor: "pointer",
            }}
            onClick={() => {
              localStorage.setItem("Client Tab", "All");
              setClientTab("All");
            }}
          >
            Reports
          </span>
          <span style={{ padding: "0px 5px", color: "#0071BC" }}>/</span>{" "}
          <span
            style={{
              fontWeight: 600,
              fontSize: "22px",
              margin: "0px",
              padding: "0px",
              color: "red",
            }}
          >
            Client Reports
          </span>
        </div>
      </Grid>

      <Grid
        container
        item
        lg={8}
        md={10}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <Grid item md={5} xs={12} sx={{ marginBottom: "5px" }}>
          <Autocomplete
            fullWidth
            id="auto-complete"
            size="small"
            disableClearable
            options={companyDrop}
            value={companyValue}
            isOptionEqualToValue={(option, value) =>
              option.CompanyName == value?.CompanyName
            }
            onChange={(event, newValue) => {
              localStorage.setItem("Client Contact", JSON.stringify(newValue));
              setCompanyValue(newValue);
              TotalCount(
                newValue?.AccountsId,
                spli != null ? spli[0] : DForm,
                spli != null ? spli[1] : DForm1
              );
              SlaCount(
                newValue?.AccountsId,
                spli != null ? spli[0] : DForm,
                spli != null ? spli[1] : DForm1
              );
            }}
            getOptionLabel={(option) => option.CompanyName || ""}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.AccountsId}>
                {option.CompanyName}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                variant="outlined"
                required
              />
            )}
          />
        </Grid>
        <Grid
          container
          item
          md={6.7}
          xs={12}
          sx={{
            marginBottom: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item md={9} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={Date1}
                disableFuture
                format="DD-MM-YYYY"
                slotProps={{ textField: { size: "small" } }}
                onChange={(newValue) => {
                  localStorage.setItem(
                    "ClientTime",
                    `${newValue[0].$d},${
                      newValue[1] != null ? newValue[1].$d : newValue[0].$d
                    }`
                  );
                  setDate1(newValue);
                  setDate(newValue);
                  TotalCount(
                    companyValue?.AccountsId,
                    newValue[0] && newValue[0].$d,
                    newValue[1] && newValue[1].$d
                  );
                  SlaCount(
                    companyValue?.AccountsId,
                    newValue[0] && newValue[0].$d,
                    newValue[1] && newValue[1].$d
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid
            md={3}
            xs={12}
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ClientTime",
                  `${today.subtract(7, "day").$d},${
                    today != null ? today.$d : today.subtract(7, "day").$d
                  }`
                );

                TotalCount(
                  companyValue?.AccountsId,
                  today.subtract(7, "day").$d,
                  today.$d
                );
                SlaCount(
                  companyValue?.AccountsId,
                  today.subtract(7, "day").$d,
                  today.$d
                );
                setDate1([today.subtract(7, "day"), today]);
                setDate([today.subtract(7, "day"), today]);
              }}
            >
              <Tooltip title="Last 7 Days">
                <TodayTwoToneIcon sx={{ mt: "2px" }} color="secondary" />
              </Tooltip>
            </IconButton>

            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ClientTime",
                  `${prevMonth.startOf("month").$d},${
                    prevMonth.endOf("month") != null
                      ? prevMonth.endOf("month").$d
                      : prevMonth.startOf("month").$d
                  }`
                );

                TotalCount(
                  companyValue?.AccountsId,
                  prevMonth.startOf("month").$d,
                  prevMonth.endOf("month").$d
                );
                SlaCount(
                  companyValue?.AccountsId,
                  prevMonth.startOf("month").$d,
                  prevMonth.endOf("month").$d
                );
                setDate1([
                  prevMonth.startOf("month"),
                  prevMonth.endOf("month"),
                ]);
                setDate([prevMonth.startOf("month"), prevMonth.endOf("month")]);
              }}
            >
              <Tooltip title="Last Month">
                <CalendarMonthSharpIcon color="success" />
              </Tooltip>
            </IconButton>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                localStorage.setItem(
                  "ClientTime",
                  `${prevWeek.startOf("year").$d},${
                    prevWeek.endOf("year") != null
                      ? prevWeek.endOf("year").$d
                      : prevWeek.startOf("year").$d
                  }`
                );
                TotalCount(
                  companyValue?.AccountsId,
                  prevWeek.startOf("year").$d,
                  prevWeek.endOf("year").$d
                );
                SlaCount(
                  companyValue?.AccountsId,
                  prevWeek.startOf("year").$d,
                  prevWeek.endOf("year").$d
                );
                setDate1([prevWeek.startOf("year"), prevWeek.endOf("year")]);
                setDate([prevWeek.startOf("year"), prevWeek.endOf("year")]);
              }}
            >
              <Tooltip title="Last year">
                <DateRangeSharpIcon color="primary" />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px 0px 0px 0px",
        }}
      >
        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "New");
            setClientTab("New");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              New
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.New}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.New}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Progress");
            setClientTab("Progress");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Progress
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Progress}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Progress}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Hold");
            setClientTab("Hold");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Hold
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Holding}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Holding}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Completed");
            setClientTab("Completed");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Completed
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Completed}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Completed}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid
          item
          lg={1.9}
          md={3.9}
          sm={5.9}
          xs={12}
          style={{
            background: "#FFFFFF",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "15px",
            boxShadow: "5px 3px 5px rgb(0,0,0,0.1)",
            marginBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            localStorage.setItem("Client Tab", "Solved");
            setClientTab("Solved");
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: 500, margin: "0px" }}>
              Solved
              <span
                style={{
                  fontSize: "8px",
                  color: "darkred",
                  paddingLeft: "3px",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid rgba(0,0,0,0.04)",
              borderBottom: "1px solid rgba(0,0,0,0.04)",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "40px",
                fontWeight: 800,
                margin: "0px",
              }}
            >
              {TCount?.Solved}
              <span
                style={{ margin: "0px", fontSize: "12px", color: "darkred" }}
              >
                / {SLACount?.Solved}
              </span>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              padding: "5px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: 500,
                margin: "0px",
              }}
            >
              Total{" "}
              <span
                style={{
                  margin: "0px",
                  fontSize: "8px",
                  fontWeight: 500,
                  color: "darkred",
                }}
              >
                / Delay
              </span>
            </p>
          </div>
        </Grid>

        <Grid item md={3.9} sm={5.9} xs={12}></Grid>
      </Grid>
      {ClientTab == "All" && (
        <CAllTable
          AccountsId={companyValue?.AccountsId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "New" && (
        <CNewTable
          AccountsId={companyValue?.AccountsId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Progress" && (
        <CProgressTable
          AccountsId={companyValue?.AccountsId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Hold" && (
        <CHoldTable
          AccountsId={companyValue?.AccountsId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Completed" && (
        <CCompletedTable
          AccountsId={companyValue?.AccountsId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
      {ClientTab == "Solved" && (
        <CSolvedTable
          AccountsId={companyValue?.AccountsId}
          FromD={spli != null ? spli[0] : DForm}
          ToD={spli != null ? spli[1] : DForm1}
        />
      )}
    </>
  );
}

export default ClientReport;
