import React, { memo, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { styled } from "@mui/system";

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: "#CFCFCF",
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
  },
}));

const TicketPriority = ({ priorityGraphList }) => {
  const [priorities, setPriorities] = useState([
    { label: "High", value: 0, color: "#4CAF50" },
    { label: "Medium", value: 0, color: "#F57356" },
    { label: "Low", value: 0, color: "#42A5F5" },
  ]);

  useEffect(() => {
    setPriorities((prevPriorities) =>
      prevPriorities.map((item, index) => ({
        ...item,
        label: priorityGraphList[index]?.label || item.label,
        value: priorityGraphList[index]?.Count || item.value,
      }))
    );
  }, [priorityGraphList]);
  return (
    <div style={{ p: 2, width: "100%" }}>
      <CardContent>
        <Box sx={{ position: "relative", width: "100%" }}>
          <ProgressBar variant="determinate" value={100} />
          <Box
            display="flex"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            {priorities.map((priority, index) => (
              <Box
                key={index}
                sx={{
                  height: "100%",
                  width: `${priority.value}%`,
                  backgroundColor: priority.color,
                  borderRadius:
                    index === 0
                      ? "5px 0 0 5px"
                      : index === priorities.length - 1
                      ? "0 5px 5px 0"
                      : "none",
                }}
              />
            ))}
          </Box>
        </Box>
        <Box mt={2}>
          {priorities.map((priority, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    backgroundColor: priority.color,
                    borderRadius: 1,
                  }}
                />
                <Typography variant="body2">{priority.label}</Typography>
              </Box>
              <Typography variant="body2" color="gray">
                {priority.value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </div>
  );
};

export default memo(TicketPriority);
