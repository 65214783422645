import {
  Autocomplete,
  Avatar,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AgentIcon1,
  AgentIcon2,
  AgentIcon3,
  AgentIcon4,
  AgentIcon5,
} from "../../Assets/Icon";
import PerformanceCard from "./PerformanceGraph";
import Chart from "react-apexcharts";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function AgentDashboard() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const userData = global.$userData.logindata;

  //values
  const [DepartmentDrop, SetDepartmentDrop] = useState([]);
  const [DepartmentValue, SetDepartmentValue] = useState({});
  const [UserDrop, SetUserDrop] = useState([]);
  const [UserValue, SetUserValue] = useState({});
  const [agentId, setAgentId] = useState("");

  // tickets handled
  const [totalTickets, setTotalTickets] = useState(0);
  const [solvedTickets, setSolvedTickets] = useState(0);
  const [monthTickets, setMonthTickets] = useState(0);
  const [avgRespTime, setAvgRespTime] = useState(0);
  const [customerFeedback, setCustomerFeedback] = useState(0);
  const [sla, setSla] = useState(0);
  const [graphStatus, setGraphStatus] = useState("overall");
  const [comparison, setComparison] = useState([]);
  const [comparison1, setComparison1] = useState([]);

  const [date, setDate] = useState([dayjs().subtract(1, "year"), dayjs()]);

  const formatDate = (dateObj) => {
    return dateObj.format("YYYY-MM-DD");
  };

  const DForm = formatDate(date[0]);
  const DForm1 = formatDate(date[1]);

  console.log("DForm", DForm, DForm1);

  const [state, setState] = useState({
    series: [{ name: "Tickets", data: [] }],
    options: {
      chart: {
        type: "area",
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      dataLabels: { enabled: false },
      stroke: { curve: "smooth", width: 1, colors: ["#0071BC"] },
      grid: { row: { colors: ["#f3f3f3", "transparent"], opacity: 0.5 } },
      xaxis: { categories: [], labels: { show: false } },
      yaxis: { show: false },
    },
  });

  const [chartData, setChartData] = useState({
    series: [{ name: "On-Time Closed Tickets", data: [] }],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: { bar: { columnWidth: "50%", borderRadius: 4 } },
      xaxis: { categories: [], labels: { style: { fontSize: "12px" } } },
      yaxis: { title: { text: "Assigned Tickets" } },
      fill: {
        type: "gradient",
        gradient: { shade: "light", type: "vertical" },
      },
    },
  });

  const DepartementList = async () => {
    const response = await global.$axios.get(
      `ticket/team/department/${AppCode}/${userId}`
    );
    if (response.data.Status === 200) {
      let deptData = response.data.Message.filter(
        (item) => item.DepartmentId == userData.TicketDepartment
      );

      SetDepartmentDrop(response.data.Message);
      if (deptData.length > 0) {
        SetDepartmentValue(deptData[0]);
        UserApi(deptData[0].DepartmentId);
      }
    }
  };

  const UserApi = async (DeptId) => {
    let payload = {
      Department: DeptId,
      AppCode: AppCode,
    };
    console.log("payload", payload);

    const response = await global.$axios.post(
      "dashboard/depOfUserList",
      payload
    );
    if (response.data.Status === 200) {
      let userObj = response.data.Message.filter(
        (item) => item.UserId == userData.UserId
      );
      SetUserDrop(response.data.Message);
      if (userObj.length > 0) {
        SetUserValue(userObj[0]);
        setAgentId(userObj[0].UserId);
      }
    }
  };

  const totalTicketsApi = async () => {
    try {
      let payload = {
        DepartmentId: [DepartmentValue.DepartmentId],
        UserId: [UserValue.UserId],
        AppCode: AppCode,
        from: DForm,
        to: DForm1,
      };
      const response = await global.$axios.post(
        "dashboard/getagentTicketCount",
        payload
      );
      // console.log("response", response.data);
      if (response.data.Status == 200) {
        setTotalTickets(response.data.data.Total_ticketcount);
        setSolvedTickets(response.data.data.Solved);
      }
    } catch (error) {
      console.log("dashboard/getagentTicketCount", error);
    }
  };

  const getAvgRespTimeApi = async () => {
    try {
      let payload = {
        DepartmentId: [DepartmentValue.DepartmentId],
        UserId: [UserValue.UserId],
        AppCode: AppCode,
        from: DForm,
        to: DForm1,
      };
      console.log("getAvgRespTimeApi", payload);

      const response = await global.$axios.post(
        "dashboard/getagentavgtimeCount",
        payload
      );

      if (response.data.Status == 200) {
        let totalSeconds = response.data.Message.avgTime;
        const hours = Math.floor(totalSeconds / 3600); // Get whole hours
        const minutes = Math.floor((totalSeconds % 3600) / 60); // Get remaining minutes
        setAvgRespTime(
          hours == 0 ? `${minutes} minutes` : `${hours} hour ${minutes} minutes`
        );
      }
    } catch (error) {
      console.log("dashboard/getagentavgtimeCount", error);
    }
  };

  const customerSatisfactioApi = async () => {
    try {
      let payload = {
        DepartmentId: [DepartmentValue.DepartmentId],
        UserId: [UserValue.UserId],
        AppCode: AppCode,
        from: DForm,
        to: DForm1,
      };
      const response = await global.$axios.post(
        "dashboard/customerfeedbackcount",
        payload
      );
      if (response.data.Status == 200) {
        setCustomerFeedback(response.data.Message.CustomerSatisfaction);
      }
    } catch (error) {
      console.log("dashboard/customerfeedbackcount", error);
    }
  };

  const getSlaListApi = async () => {
    try {
      let payload = {
        DepartmentId: [DepartmentValue.DepartmentId],
        UserId: [UserValue.UserId],
        AppCode: AppCode,
        from: DForm,
        to: DForm1,
      };
      const response = await global.$axios.post(
        "dashboard/getagentSLACount",
        payload
      );
      if (response.data.Status == 200) {
        setSla(response.data.Message.Total_SLA_Breached);
      }
    } catch (error) {
      console.log("dashboard/getagentSLACount", error);
    }
  };

  const getTopPerformanceApi = async () => {
    try {
      let payload = {
        DepartmentId: [DepartmentValue.DepartmentId],
        AppCode: AppCode,
      };

      const response = await global.$axios.post(
        "dashboard/getTopPerformers",
        payload
      );

      if (response.data.Status == 200) {
        let arrayData = response.data.Message;
        setComparison(arrayData.Last15Performances);
        setComparison1(arrayData.LastMonthPerformance);

        const ticketData = arrayData.Last15Performances.map(
          (item) => item.TicketCount || 0
        );
        const ticketData1 = arrayData.LastMonthPerformance.map(
          (item) => item.TicketCount || 0
        );
        const userNames = arrayData.Last15Performances.map(
          (item) => item.UserName
        );

        const userNames1 = arrayData.LastMonthPerformance.map(
          (item) => item.UserName
        );

        setChartData((prev) => ({
          ...prev,
          series: [
            {
              name: "Tickets",
              data: graphStatus == "overall" ? ticketData : ticketData1,
            },
          ],
          options: {
            ...prev.options,
            xaxis: {
              ...prev.options.xaxis,
              categories: graphStatus == "overall" ? userNames : userNames1,
            },
          },
        }));
      }
    } catch (error) {
      console.log("dashboard/getTopPerformers", error);
    }
  };

  const getLastDataApi = async () => {
    try {
      let payload = {
        DepartmentId: [DepartmentValue.DepartmentId],
        UserId: [UserValue.UserId],
        AppCode: AppCode,
      };
      const response = await global.$axios.post(
        "dashboard/currentMonthPerOverallTicketList",
        payload
      );

      if (response.data.Status == 200) {
        let arrayData = response.data.Message;
        const totalCount = arrayData.TicketList.reduce(
          (sum, item) => sum + (item.count || 0),
          0
        );
        setMonthTickets(totalCount);
        const ticketData = arrayData.TicketList.map((item) => item.count || 0);
        const dates = arrayData.TicketList.map((item) => item.date);

        setState((prevState) => ({
          ...prevState,
          series: [{ name: "Tickets", data: ticketData }],
          options: { ...prevState.options, xaxis: { categories: dates } },
        }));
      }
    } catch (error) {
      console.log("dashboard/getTopPerformers", error);
    }
  };

  useEffect(() => {
    DepartementList();
  }, []);

  useEffect(() => {
    totalTicketsApi();
    getAvgRespTimeApi();
    customerSatisfactioApi();
    getSlaListApi();
  }, [UserValue, DForm, DForm1]);

  useEffect(() => {
    getTopPerformanceApi();
    getLastDataApi();
  }, [UserValue]);

  const handleGraph = (val) => {
    setGraphStatus(val);
    if (val == "overall") {
      const ticketData = comparison.map((item) => item.TicketCount || 0);
      const userNames = comparison.map((item) => item.UserName);

      setChartData((prev) => ({
        ...prev,
        series: [
          {
            name: "Tickets",
            data: ticketData,
          },
        ],
        options: {
          ...prev.options,
          xaxis: {
            ...prev.options.xaxis,
            categories: userNames,
          },
        },
      }));
    } else {
      const ticketData = comparison1.map((item) => item.TicketCount || 0);
      const userNames = comparison1.map((item) => item.UserName);
      setChartData((prev) => ({
        ...prev,
        series: [
          {
            name: "Tickets",
            data: ticketData,
          },
        ],
        options: {
          ...prev.options,
          xaxis: {
            ...prev.options.xaxis,
            categories: userNames,
          },
        },
      }));
    }
  };

  return (
    <>
      <Grid container sx={styles.container}>
        <Grid
          item
          container
          xs={12}
          sm={4.8}
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            height: "150px",
          }}
        >
          <div>
            <Typography sx={styles.txt}>Agent performance Dashboard</Typography>
            <Typography sx={styles.txt1}>
              Monitoring Individual Agent Metrics and performance
            </Typography>
          </div>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item md={6} xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={DepartmentDrop}
                value={DepartmentValue}
                disableClearable
                isOptionEqualToValue={(option, value) =>
                  option.DepartmentName == value.DepartmentName
                }
                getOptionLabel={(option) => option.DepartmentName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label=" Department" />
                )}
                onChange={(event, newvalue) => {
                  SetDepartmentValue(newvalue);
                  SetUserValue({});
                  UserApi(newvalue.DepartmentId);
                }}
              />
            </Grid>
            <Grid item md={5.5} xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={UserDrop}
                value={UserValue}
                disableClearable
                isOptionEqualToValue={(option, value) => {
                  option.UserFirstName == value?.UserFirstName;
                }}
                getOptionLabel={(option) => option.UserFirstName || ""}
                size="small"
                renderInput={(params) => (
                  <TextField {...params} label="Users" />
                )}
                onChange={(event, newvalue) => {
                  SetUserValue(newvalue);
                  setAgentId(newvalue.UserId);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={7} sx={styles.rightCard}>
          <Grid item xs={12}>
            <Typography sx={styles.txt1}>Agents</Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              overflowX: "scroll",
              marginTop: "20px",
              gap: "10px",
            }}
          >
            {UserDrop.map((item) => {
              return (
                <Grid
                  xs={11}
                  item
                  key={item.id}
                  style={
                    agentId == item.UserId ? styles.itemCard : styles.itemCard1
                  }
                  onClick={() => {
                    SetUserValue(item);
                    setAgentId(item.UserId);
                  }}
                >
                  <Avatar
                    alt={item.UserFirstName.slice(0, 1)}
                    src={item.ProfilePictureLink || ""}
                  />
                  <div>
                    <Typography style={styles.userTxt}>
                      {item.UserFirstName}
                    </Typography>
                    <Typography style={styles.userTxt1}>
                      {DepartmentValue.DepartmentName}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={styles.container1}>
        <Grid
          item
          xs={12}
          sx={{
            padding: "8px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography style={styles.headTxt}>Tickets Handled</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              value={date}
              disableFuture
              format="DD-MM-YYYY"
              slotProps={{ textField: { size: "small" } }}
              onChange={(newValue) => {
                setDate(newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item xs={12} sm={5.5} md={2.3} style={styles.card}>
            <div style={styles.box}>
              <Typography style={styles.headTxt1}>Ticket Handled</Typography>
              <AgentIcon1 />
            </div>
            <div style={styles.btnCard}>
              <Typography>{totalTickets}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.5} md={2.3} style={styles.card}>
            <div style={styles.box}>
              <Typography style={styles.headTxt1}>
                Avg Responses Time
              </Typography>
              <AgentIcon2 />
            </div>
            <div style={styles.btnCard1}>
              <Typography style={styles.headTxt1}>{avgRespTime}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.5} md={2.3} style={styles.card}>
            <div style={styles.box}>
              <Typography style={styles.headTxt1}>
                Customer Satisfaction
              </Typography>
              <AgentIcon3 />
            </div>
            <div style={styles.btnCard2}>
              <Typography style={styles.headTxt1}>
                {customerFeedback}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.5} md={2.3} style={styles.card}>
            <div style={styles.box}>
              <Typography style={styles.headTxt1}>SLA Breached</Typography>
              <AgentIcon4 />
            </div>
            <div style={styles.btnCard3}>
              <Typography style={styles.headTxt1}>{sla}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={5.5} md={2.3} style={styles.card}>
            <div style={styles.box}>
              <Typography style={styles.headTxt1}>Solved</Typography>
              <AgentIcon5 />
            </div>
            <div style={styles.btnCard4}>
              <Typography style={styles.headTxt1}>{solvedTickets}</Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={styles.container}>
        <Grid item container xs={12} sm={4} style={styles.leftRow}>
          <PerformanceCard
            UserValue={UserValue}
            DepartmentValue={DepartmentValue}
            totalTickets={monthTickets}
            data={state}
          />
        </Grid>
        <Grid item container xs={12} sm={7.8} style={styles.rightRow}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography style={styles.txt1}>Agent Comparison</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                onClick={() => handleGraph("overall")}
                style={
                  graphStatus == "overall"
                    ? styles.trendCard
                    : styles.trendCard1
                }
              >
                <span style={styles.txt1}>OverAll</span>
              </div>
              <div
                onClick={() => handleGraph("30days")}
                style={
                  graphStatus == "30days" ? styles.trendCard : styles.trendCard1
                }
              >
                <span style={styles.txt1}>Last Month</span>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={300}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const styles = {
  txt: {
    fontSize: "18px",
    color: "#7A7A7A",
    fontWeight: 600,
  },
  txt1: {
    fontSize: "14px",
    color: "#474747",
    fontWeight: 500,
  },
  headTxt: {
    fontSize: "15px",
    color: "#474747",
    fontWeight: 500,
  },
  headTxt1: {
    fontSize: "15px",
    color: "#3A3A3ABF",
    fontWeight: 600,
  },
  userTxt: {
    fontSize: "14px",
    color: "#344054",
    fontWeight: 400,
  },
  userTxt1: {
    fontSize: "14px",
    color: "#667085",
    fontWeight: 400,
  },
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  container1: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    margin: "10px 0px",
    padding: "5px",
    borderRadius: "8px",
  },
  rightCard: {
    backgroundColor: "#F3FAFF",
    BorderWidth: 1,
    border: "1px solid #EAECF0",
    padding: "12px 6px",
    borderRadius: "8px",
  },
  itemCard: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#E3F4FF",
    padding: "7px 16px",
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    cursor: "pointer",
  },
  itemCard1: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    backgroundColor: "#fff",
    padding: "7px 16px",
    borderRadius: "8px",
    border: "1px solid #EAECF0",
    cursor: "pointer",
  },
  card: {
    backgroundColor: "#fff",
    border: "1px solid #EAECF0",
    borderRadius: "8px",
    padding: "16px",
  },
  box: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  btnCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E2F4FC",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  btnCard1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#7ABB99BF",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  btnCard2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF9846A6",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  btnCard3: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#3BB39CBF",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  btnCard4: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#9661BDA6",
    borderRadius: "9px",
    padding: "9px 0px",
  },
  leftRow: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "9px",
    padding: "9px 0px",
    border: "1px solid #EAECF0",
    padding: "10px",
  },
  rightRow: {
    width: "100%",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "9px",
    padding: "9px 0px",
    border: "1px solid #EAECF0",
    padding: "10px",
  },
  trendCard: {
    border: "1px solid #B3B3BD",
    padding: "2px 4px",
    borderRadius: "6px",
    cursor: "pointer",
  },
  trendCard1: {
    padding: "2px 4px",
    borderRadius: "6px",
    cursor: "pointer",
  },
};

export default AgentDashboard;
