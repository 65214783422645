import React from "react";
import {
  Card,
  CardContent,
  Box,
  Avatar,
  Typography,
  Grid,
} from "@mui/material";
import Chart from "react-apexcharts";

const PerformanceCard = ({
  UserValue,
  DepartmentValue,
  totalTickets,
  data,
}) => {
  return (
    <Grid sx={{ width: "100%" }}>
      <CardContent>
        {/* Header */}
        <Typography>Current Month Performance</Typography>
        {/* Profile Section */}
        <Box display="flex" alignItems="center" gap={2} mt={1}>
          <Avatar
            alt={UserValue?.UserFirstName?.slice(0, 1)}
            src={UserValue?.ProfilePictureLink || ""}
            sx={{ width: 50, height: 50 }}
          />
          <Box>
            <Typography>{UserValue.UserFirstName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {DepartmentValue?.DepartmentName}
            </Typography>
          </Box>
        </Box>

        {/* Graph Section */}
        <Box mt={3} sx={{ backgroundColor: "#F5F5F5", borderRadius: 2, p: 2 }}>
          <Typography variant="body2" fontWeight="bold">
            Ticket Handled
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {totalTickets}
          </Typography>
          <Chart
            options={data.options}
            series={data.series}
            type="line"
            height={100}
          />
        </Box>
      </CardContent>
    </Grid>
  );
};

export default PerformanceCard;
