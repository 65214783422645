import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileEdit = ({ openProfile, setOpenProfile }) => {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(true);

  //Function

  const handleClose = () => {
    setOpenProfile(false);
  };

  //APIs
  const getProfileDetail = async () => {
    try {
      const { data } = await global.$axios.get(
        `/userinfo/view/${AppCode}/${userId}`
      );

      if (data.status == 200) {
        const respo = data.Message;
        setName(respo?.UserFirstName || "");
        setLastName(respo?.UserLastName || "");
        setMail(respo?.UserEmailId || "");
        setMobile(respo?.UserMobileNumber || "");
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("GetProfileDetail Issue", error);
      setLoading(false);
    }
  };

  const updateProfile = async () => {
    if (!name) {
      toast.error("Please Provide First Name");
      return;
    }
    try {
      const payload = {
        AppCode: AppCode,
        UserId: userId,
        UserFirstName: name,
        UserLastName: lastName,
        UserMobileNumber: mobile,
        UserEmailId: mail,
      };

      const response = await global.$axios.post(
        "userinfo/editProfile",
        payload
      );
      // console.log("userinfo/editProfil", response.data);

      if (response.data.Status === 200) {
        toast.success("Profile Updated Successfully");
        let payload1 = {
          AppCode: AppCode,
          UserId: userId,
          source: "Web",
        };
        const response1 = await $axios.post("auth/getuserauthInfo", payload1);
        if (response1.data.Status == 200) {
          dispatch({
            type: "SET_USER_IN",
            logindata: response1.data.Message,
            payload: true,
          });
        }
        setTimeout(() => {
          handleClose();
        }, 1500);
      } else {
        console.log("updateProfile Issue", data.Message);
      }
    } catch (error) {
      console.log("updateProfile Issue", error);
    }
  };

  useEffect(() => {
    getProfileDetail();
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={openProfile}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"sm"}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Profile</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        {loading ? (
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <CircularProgress color="inherit" />
          </DialogContent>
        ) : (
          <DialogContent sx={{}}>
            <TextField
              value={name}
              variant="outlined"
              fullWidth
              onChange={(e) => setName(e.target.value?.trim())}
              sx={{ my: 2 }}
              label={
                <p>
                  First Name <span style={{ color: "red" }}>*</span>
                </p>
              }
            />

            <TextField
              value={lastName}
              variant="outlined"
              fullWidth
              onChange={(e) => setLastName(e.target.value?.trim())}
              sx={{ my: 2 }}
              label="Last Name"
            />

            <TextField
              disabled
              value={mail}
              variant="outlined"
              fullWidth
              onChange={(e) => setMail(e.target.value?.trim())}
              sx={{ my: 2 }}
              label={
                <p>
                  Mail <span style={{ color: "red" }}>*</span>
                </p>
              }
            />

            <TextField
              disabled
              value={mobile}
              variant="outlined"
              fullWidth
              onChange={(e) => setMobile(e.target.value?.trim())}
              sx={{ my: 2 }}
              label={
                <p>
                  Mobile <span style={{ color: "red" }}>*</span>
                </p>
              }
            />

            <Grid container sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize", px: 3 }}
                onClick={updateProfile}
              >
                Save
              </Button>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </React.Fragment>
  );
};

export default ProfileEdit;
