import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CompletedIcon,
  HoldIcon,
  NewIcon,
  ProgressIcon,
  SolvedIcon,
} from "../../Assets/Icon";
import { useNavigate } from "react-router-dom";

function OverAllTicket() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const Admin = global?.$userData?.logindata?.AccessAdminTicket == "Yes";
  const Team = global?.$userData?.logindata?.TicketingDepartmentAdmin == "Yes";
  const Navigate = useNavigate();
  function routerfun(route, localkey, localvalue) {
    Navigate(`/app/${route}`, { replace: true });
    localStorage.setItem(localkey, localvalue);
  }
  function routevalue() {
    if (Admin) {
      return "All";
    } else if (Team) {
      return "TeamTickets";
    } else {
      return "MyWorkspace";
    }
  }
  function localkeyfun() {
    if (Admin) {
      return "All Tab";
    } else if (Team) {
      return "Team Tab";
    } else {
      return "MyWorkspace Tab";
    }
  }
  const [Data, setData] = useState([
    {
      Label: "New",
      Icon: <NewIcon />,
      value: "0",
      color: "#e2f4fc",
      Detailes: {
        route: routevalue(),
        localkey: localkeyfun(),
        localvalue: "New",
      },
    },
    {
      Label: "Progress",
      Icon: <ProgressIcon />,
      value: "0",
      color: "#EEBD57",
      Detailes: {
        route: routevalue(),
        localkey: localkeyfun(),
        localvalue: "Progress",
      },
    },
    {
      Label: "Hold",
      Icon: <HoldIcon />,
      value: "0",
      color: "#FF984660",
      Detailes: {
        route: routevalue(),
        localkey: localkeyfun(),
        localvalue: "Hold",
      },
    },
    {
      Label: "Completed",
      Icon: <CompletedIcon />,
      value: "0",
      color: "#3BB39C75",
      Detailes: {
        route: routevalue(),
        localkey: localkeyfun(),
        localvalue: "Completed",
      },
    },
    {
      Label: "Solved",
      Icon: <SolvedIcon />,
      value: "0",
      color: "#76E15165",
      Detailes: {
        route: routevalue(),
        localkey: localkeyfun(),
        localvalue: "Solved",
      },
    },
  ]);
  const overallticketcount = async () => {
    const { data } = await global.$axios.get(
      `/dashboard/overAllCount/${AppCode}/${userId}`
    );
    if (data.Status === 200) {
      let value = Object.keys(data?.msg[0]);
      value.filter((x) => {
        let index = Data.findIndex((y) => y.Label === x);
        if (index != -1) {
          Data[index].value = data.msg[0][x];
        }
      });
      setData([...Data]);
    }
  };
  useEffect(() => {
    overallticketcount();
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          boxShadow: 2,
          borderRadius: 2,
          px: 1,
          pb: 2,
          display: "flex",
          justifyContent: "center",
          background: "#fff",
          height: { xs: "auto", md: "24.4vh" },
        }}
      >
        <Grid container item xs={12}>
          <Typography variant="body1" sx={{ py: 1, fontSize: 14 }}>
            Overall tickets
          </Typography>
        </Grid>
        {Data?.map((x) => {
          return (
            <Grid
              container
              item
              xs={12}
              md={2.2}
              sx={{
                boxShadow: 2,
                borderRadius: 2,
                p: 1,
                background: "#fff",
                margin: 0.5,
                cursor: "pointer",
              }}
              onClick={() =>
                routerfun(
                  x.Detailes.route,
                  x.Detailes.localkey,
                  x.Detailes.localvalue
                )
              }
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">{x.Label}</Typography>
                {x.Icon}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  background: x.color,
                  borderRadius: 1,
                  p: 0.5,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {x.value}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default OverAllTicket;
