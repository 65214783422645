import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  ConvertUserIcon,
  ConvertContactIcon,
  ConvertIcon,
  RestoreIcon,
} from "../../Assets/Icon";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";

const MailTicketIndex = () => {
  const Navigate = useNavigate();
  const getMailSearch = localStorage.getItem("MailSearch");

  const AppCode = global.$userData.logindata.AppCode;
  const userData = global.$userData.logindata;

  const [mailRow, setMailRow] = useState([]);
  const [mailSearch, setMailSearch] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [ticketState, setTicketState] = useState("active");

  const [deletePop, setDeletePop] = useState(false);
  const [restorePop, setRestorePop] = useState(false);
  const [rowData, setRowData] = useState({});

  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "UserId",
      sort: "desc",
    },
  ]);

  //Functions
  async function filterList(data, term) {
    let ticketData = [];
    if (term.trim() !== "") {
      const text_data = term.toLowerCase();
      ticketData = data.filter((item) => {
        const emailTicketId = String(item?.EmailticketId || "").toLowerCase();
        const fromAddress = String(item?.Fromaddress || "").toLowerCase();
        const sourceName = String(
          item?.source?.TicketSourceName || ""
        ).toLowerCase();
        const userOrContactName =
          item?.For == 1
            ? String(item?.emailuser?.UserName || "").toLowerCase()
            : String(item?.emailcontact?.contactname || "").toLowerCase();

        return (
          emailTicketId.includes(text_data) ||
          fromAddress.includes(text_data) ||
          sourceName.includes(text_data) ||
          userOrContactName.includes(text_data)
        );
      });
      setMailSearch(ticketData);
    } else {
      setMailSearch(data);
    }
  }

  const columns = [
    {
      field: "EmailticketId",
      headerClassName: "super-app-theme--header",
      headerName: "Request Id",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <>
            {ticketState == "active" ? (
              <>
                <Tooltip
                  title="Convert"
                  onClick={() =>
                    Navigate("/app/create_mail_to_ticket", {
                      state: { data: cellValues?.row },
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <ConvertIcon height={20} width={20} />
                </Tooltip>
                {userData.AccessAdminTicket == "Yes" && (
                  <Tooltip title="Delete">
                    <DeleteIcon
                      onClick={() => {
                        setDeletePop(true);
                        setRowData(cellValues?.row);
                      }}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                        padding: "2px",
                      }}
                    />
                  </Tooltip>
                )}
              </>
            ) : (
              <Tooltip
                title="Restore"
                onClick={() => {
                  setRestorePop(true);
                  setRowData(cellValues?.row);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <RestoreIcon height={20} width={20} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "source",
      headerClassName: "super-app-theme--header",
      headerName: "Source",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div>
            {cellValues?.row?.source
              ? cellValues?.row?.source?.TicketSourceName
              : "-"}
          </div>
        );
      },
    },
    {
      field: "For",
      headerClassName: "super-app-theme--header",
      headerName: "From",
      flex: 0.4,
      align: "start",
      headerAlign: "start",
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "5px",
            }}
          >
            {cellValues?.row?.For == "1" ? (
              <ConvertUserIcon />
            ) : (
              <ConvertContactIcon />
            )}
            {cellValues?.row?.For == "1"
              ? cellValues?.row?.emailuser?.UserName
              : cellValues?.row?.emailcontact?.contactname}
          </div>
        );
      },
    },

    {
      field: "Fromaddress",
      headerClassName: "super-app-theme--header",
      headerName: "Mail",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return <div>{cellValues?.row?.Fromaddress ?? "-"}</div>;
      },
    },

    {
      field: "Summary",
      headerName: "Summary",
      headerClassName: "super-app-theme--header",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      //   renderCell: (cellValues) => {
      //     return (
      //       <div
      //         style={{ padding: "5px", fontSize: "14px" }}
      //         dangerouslySetInnerHTML={{
      //           __html: cellValues?.row?.Htmltag,
      //         }}
      //       />
      //     );
      //   },
    },
  ];

  const getMailList = async () => {
    try {
      setMailRow([]);
      setMailSearch([]);
      const response = await global.$axios.get(
        `ticket/getemailtickets/${AppCode}`
      );
      if (response.data.Status == 200) {
        setMailRow(response.data.Message);
        setMailSearch(response.data.Message);
        filterList(response.data.Message, getMailSearch || "");
      } else {
      }
    } catch (error) {
      console.log("getemailtickets", error);
    }
  };
  const getInActiveMailList = async () => {
    try {
      setMailRow([]);
      setMailSearch([]);
      const response = await global.$axios.get(
        `ticket/inactiveemailtickets/${AppCode}`
      );
      if (response.data.Status == 200) {
        setMailRow(response.data.Message);
        setMailSearch(response.data.Message);
        filterList(response.data.Message, getMailSearch || "");
      } else {
      }
    } catch (error) {
      console.log("inactiveemailtickets", error);
    }
  };

  useEffect(() => {
    getMailList();
  }, []);

  const deleteConvertTicket = async () => {
    try {
      let payload = {
        Deleted: "Yes",
        EmailticketId: rowData.EmailticketId,
        AppCode: AppCode,
      };
      const response = await global.$axios.post(
        "ticket/inactiveemail",
        payload
      );
      if (response.data.Status == 200) {
        toast.success("Ticket has moved to In Active!");
        getMailList();
      } else {
        toast.error(response.data.Message);
      }
    } catch (error) {
      console.log("delete convert to ticket api", error);

      toast.error("Something went wrong");
    }
  };

  const restoreTicketApi = async () => {
    try {
      let payload = {
        Deleted: "No",
        EmailticketId: rowData.EmailticketId,
        AppCode: AppCode,
      };
      const response = await global.$axios.post("ticket/restoreemail", payload);
      if (response.data.Status == 200) {
        toast.success("Ticket has moved to Active!");
        getInActiveMailList();
      } else {
        toast.error(response.data.Message);
      }
    } catch (error) {
      console.log("delete convert to ticket api", error);

      toast.error("Something went wrong");
    }
  };

  const handleButton = (val) => {
    setTicketState(val);
    if (val == "active") {
      getMailList();
    } else {
      getInActiveMailList();
    }
  };

  return (
    <Grid
      container
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        position: "relative",
      }}
    >
      <Grid item xs={12}>
        <span
          style={{
            color: "#0071BC",
            fontSize: "18px",
            fontWeight: "600",
          }}
        >
          Convert To Ticket
        </span>
      </Grid>
      <Grid item container xs={12}>
        <Grid
          item
          xs={6}
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <div
            style={ticketState == "active" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("active")}
          >
            <span
              style={ticketState == "active" ? styles.actTxt : styles.inactTxt}
            >
              Active
            </span>
          </div>
          <div
            style={ticketState == "inactive" ? styles.actbtn : styles.inactbtn}
            onClick={() => handleButton("inactive")}
          >
            <span
              style={
                ticketState == "inactive" ? styles.actTxt : styles.inactTxt
              }
            >
              Inactive
            </span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Box
            id="searcBox"
            sx={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "flex-start",
              padding: "3px",
              background: "#FFFFFF",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#2323234D",
              borderRadius: "10px",
              float: "right",
              margin: "10px 0px 10px 0px",
              textAlign: "center",
            }}
          >
            <input
              placeholder="Search..."
              type="text"
              className="searcInput"
              id="searchInput"
              style={styles.searchInput}
              autoComplete="off"
              value={searchText}
              onChange={(e) => {
                localStorage.setItem("MailSearch", e.target.value);
                filterList(mailRow, e.target.value);
                setSearchText(e.target.value);
              }}
            />
            <SearchIcon style={{ color: "#2323234D" }} />
          </Box>
        </Grid>
      </Grid>
      {/* <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          // marginTop: 40,
        }}
        md={3}
        sm={3}
        xs={12}
      ></Grid> */}
      <div style={{ height: "480", width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            width: "100%",
            height: "70vh",
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...mailRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={mailSearch}
          getRowId={(row) => row.EmailticketId}
          rowHeight={38}
          columns={columns}
          pageSize={10}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          //   checkboxSelection
        />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={deletePop}
        onClose={() => setDeletePop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontWeight: "400",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              margin: "0px",
            }}
          >
            Do You Want To Inactive This Ticket
          </p>{" "}
        </DialogTitle>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            onClick={() => {
              deleteConvertTicket();
              setDeletePop(false);
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            onClick={() => setDeletePop(false)}
          >
            No{" "}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth={"xs"}
        open={restorePop}
        onClose={() => setRestorePop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontWeight: "400",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "400",
              margin: "0px",
            }}
          >
            Do You Want To Active This Ticket
          </p>{" "}
        </DialogTitle>

        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            onClick={() => {
              restoreTicketApi();
              setRestorePop(false);
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            onClick={() => setRestorePop(false)}
          >
            No{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  actbtn: {
    backgroundColor: "#0071BC",
    borderRadius: "6px",
    border: "1px solid #D4D4D4",
    padding: "5px 16px",
    cursor: "pointer",
  },
  inactbtn: {
    backgroundColor: "#fff",
    borderRadius: "6px",
    border: "1px solid #D4D4D4",
    padding: "5px 16px",
    cursor: "pointer",
  },
  actTxt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#fff",
  },
  inactTxt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#232323",
  },
};

export default MailTicketIndex;
