import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

function Branchpopup() {
  const Navigate = useNavigate();
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  //Dropdown
  const [Country, SetCountry] = useState([]);
  const [State, SetState] = useState([]);
  const [City, SetCity] = useState([]);

  //values
  const [BranchName, SetBranchName] = useState("");
  const [Code, SetCode] = useState("");
  const [Email, SetEmail] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [Address, SetAddress] = useState("");
  const [Area, SetArea] = useState("");
  const [CountryValue, SetCountryValue] = useState([]);
  const [StateValue, SetStateValue] = useState([]);
  const [CityValue, SetCityvalue] = useState([]);
  const [Err, setErr] = useState(0);

  //Api

  const CountryDrop = async () => {
    const response = await global.$axios
      .get("/userinfo/branch/country")
      .then(async (response) => {
        SetCountry(response.data.message);
      })
      .catch((e) => {
        console.error("countrydrop", e);
      });
  };
  const StateDrop = async (id) => {
    const response = await global.$axios
      .get(`/userinfo/branch/states/${id}`)
      .then(async (response) => {
        SetState(response.data.message);
      })
      .catch((e) => {
        console.error("statedrop", e);
      });
  };
  const CityDrop = async (id) => {
    const response = await global.$axios
      .get(`/userinfo/branch/cities/${id}`)
      .then(async (response) => {
        SetCity(response.data.message);
      })
      .catch((e) => {
        console.error("citydrop", e);
      });
  };

  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };

  //Add
  const BranchAdd = async () => {
    if (BranchName == "" || MobileNumber == "") {
      toast.error("Please Fill Required Fields");
    } else if (MobileNumber != "" && MobileNumber.length != 10) {
      toast.error("Number Must be 10 Digits");
      return;
    } else if (Email != "" && NoValidEmail) {
      toast.error("Please Provide A Valid Mail");
    } else {
      const data = {
        AppCode: AppCode,
        BranchCode: Code,
        BranchName: BranchName,
        BranchEmail: Email,
        BranchMobileNumber: MobileNumber,
        Area: Area,
        Address: Address,
        Country: CountryValue?.CountryId,
        State: StateValue?.StateId,
        City: CityValue?.CityId,
        UserId: userId,
      };
      const response = await global.$axios
        .post("userinfo/branch/add", data)
        .then(async (response) => {
          if (response.data.status == 200) {
            toast.success("Branch added ");
            setTimeout(() => {
              Navigate(-1);
            }, [1500]);
          } else {
            toast.error("Something Went wrong");
          }
        })
        .catch((e) => {
          console.error("Brandadd", e);
        });
    }
  };
  useEffect(() => {
    CountryDrop();
  }, []);

  // error validation
  const Validate = async (val) => {
    const response = await global.$axios.get(
      `userinfo/branch/validatebranch/${AppCode}/${val}`
    );
    setErr(response.data.Message);
  };

  // error validation
  const [numberErr, setNumberErr] = useState(0);

  const contactValidation = async (val) => {
    const response = await global.$axios
      .get(`userinfo/branch/validatebranchmn/${AppCode}/${val}`)
      .then((response) => {
        setNumberErr(response.data.Message);
      })
      .catch((e) => {
        console.error("contactvaludation", e);
      });
  };

  const [mobileerr, setmobileerr] = useState(false);
  const mobile = () => {
    if (MobileNumber.length !== 10) {
      setmobileerr(true);
    } else {
      setmobileerr();
    }
  };

  return (
    <>
      <Grid container xs={12}>
        <div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => {
              Navigate(-1);
            }}
          >
            Users /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            Branch
          </span>
        </div>
        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Branch Name *</p>
            <TextField
              type="text"
              fullWidth
              value={BranchName}
              inputProps={{ maxLength: "10" }}
              placeholder="Enter Branch Name"
              size="small"
              onChange={(e) => SetBranchName(e.target.value)}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Code </p>
            <TextField
              fullWidth
              value={Code}
              placeholder="Enter Code"
              size="small"
              onChange={(e) => SetCode(e.target.value)}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Email Id </p>
            <TextField
              type="email"
              fullWidth
              value={Email}
              placeholder="Enter Email Id"
              size="small"
              onChange={(e) => SetEmail(e.target.value)}
              onKeyUp={(e) => {
                e.target.value != "" && MailValidate(e.target.value);
              }}
              helperText={
                NoValidEmail ? (
                  <span style={{ color: "darkred" }}>Not a Valid Mail</span>
                ) : (
                  <span style={{ color: "transparent" }}>Good</span>
                )
              }
            />
          </Grid>

          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Mobile Number *</p>
            <TextField
              fullWidth
              placeholder="Enter Mobile Number"
              size="small"
              type="number"
              inputProps={{
                sx: {
                  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                },
              }}
              onChange={(e) =>
                setMobileNumber(
                  Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                )
              }
              value={MobileNumber}
              onKeyUp={(e) => {
                e.target.value != "" && mobile(e.target.value);
                contactValidation(e.target.value);
              }}
              helperText={
                mobileerr === true ? (
                  <span style={{ color: "red" }}>10 Digit Required</span>
                ) : numberErr === 1 ? (
                  <span style={{ color: "red" }}>Already Exist</span>
                ) : (
                  <span style={{ color: "transparent" }}>"help"</span>
                )
              }
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Address</p>
            <TextField
              fullWidth
              value={Address}
              placeholder="Enter Address"
              size="small"
              onChange={(e) => SetAddress(e.target.value)}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Area</p>
            <TextField
              fullWidth
              value={Area}
              placeholder="Enter Area"
              size="small"
              onChange={(e) => SetArea(e.target.value)}
            />
          </Grid>

          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>Country </p>
            <Autocomplete
              id="Area"
              size="small"
              value={CountryValue}
              options={Country}
              getOptionLabel={(option) => option.CountryName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Country "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                SetCountryValue(newValue);
                StateDrop(newValue?.CountryId);
                SetStateValue([]);
                SetCityvalue([]);
              }}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>State </p>
            <Autocomplete
              id="Area"
              size="small"
              value={StateValue}
              options={State}
              getOptionLabel={(option) => option.StateName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select State "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                CityDrop(newValue.StateId);
                SetStateValue(newValue);
                SetCityvalue([]);
              }}
            />
          </Grid>
          <Grid item md={3.5} xs={12}>
            <p style={style1.txttitle}>City </p>
            <Autocomplete
              id="Area"
              size="small"
              value={CityValue}
              options={City}
              getOptionLabel={(option) => option.CityName || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select City "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                SetCityvalue(newValue);
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0px 30px 0px",
            borderTop: "1px solid rgb(0,0,0,0.2)",
            padding: "10px",
          }}
        >
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            size="small"
            variant="contained"
            onClick={() => {
              BranchAdd();
            }}
          >
            Save
          </Button>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </Grid>
    </>
  );
}

export default Branchpopup;
const style1 = {
  txttitle: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};
