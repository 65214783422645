import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { toast, ToastContainer } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";

const DepartmentAssign = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const getdata = location.state.data;
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  // dropdown
  const [headdrop, setheaddrop] = useState([]);
  //drop value
  const [headValue, SetHeadValue] = useState([]);
  const [Assign, SetAssign] = useState([]);
  //ratio value
  let roundget = localStorage.getItem("round");
  const [Round, SetRound] = useState(
    roundget != null ? JSON.parse(roundget) : "No"
  );

  //table value
  const [UserTableValue, SetUserTableValue] = useState([]);

  // api
  const departmentHead = async () => {
    const response = await global.$axios
      .get(`/department/getuser/${AppCode}/${getdata.DepartmentId}`)
      .then(async (response) => {
        setheaddrop(response.data.message);
      })
      .catch((e) => {
        console.error("departementassign", e);
      });
  };

  const UserTable = async () => {
    const response = await global.$axios
      .get(`/department/depprevilege/${AppCode}/${getdata.DepartmentId}`)
      .then(async (response) => {
        SetUserTableValue(response.data.Message);
      })
      .catch((e) => {
        console.error("departementassign", e);
      });
  };

  const editdepartement = async () => {
    const response = await global.$axios
      .get(`department/view/${AppCode}/${getdata.DepartmentId}`)
      .then(async (response) => {
        if (response.data.Message != null) {
          SetHeadValue(response.data.DepartmentHead);
          SetAssign(response.data.Message.user_master);
        } else {
          SetHeadValue([]);
        }
      })
      .catch((e) => {
        console.error("editdepartement", e);
      });
  };
  // round robin
  const RoundRobinapi = async (Forid, Value) => {
    const data = {
      Forid: Forid,
      Value: Value,
    };
    const response = await global.$axios
      .put(`/department/roundrobin/${AppCode}/${getdata.DepartmentId}`, data)
      .then(async (response) => {
        if (Value == "Yes") {
          toast.success("Round Robin Active");
        } else if (Value == "No") {
          toast.success("Please Select Default Assigned To");
        } else {
          toast.error("SomeThing Went Wrong");
        }
      })
      .catch((e) => {
        console.error("departementassign", e);
      });
  };

  const assigned = async (Forid, Value, id) => {
    const data = {
      Forid: Forid,
      Value: Value,
    };
    const response = await global.$axios
      .put(`department/assigned/${AppCode}/${id}`, data)
      .then(async (response) => {})
      .catch((e) => {
        console.error("departementassign", e);
      });
  };
  const ticketview = async (Forid, Value, id) => {
    const data = {
      Forid: Forid,
      Value: Value,
    };
    const response = await global.$axios
      .put(`department/ticketview/${AppCode}/${id}`, data)
      .then(async (response) => {})
      .catch((e) => {
        console.error("departementassign", e);
      });
  };

  useEffect(() => {
    departmentHead();
    UserTable();
    editdepartement();
  }, []);
  const department = async () => {
    const head = headValue.map((e) => e.UserId);
    if (headValue == "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = {
        AppCode: AppCode,
        DefaultAssignTo: Assign?.UserId,
        RoundRobin: Round,
        DepartmentHead: head,
        UserId: userId,
      };
      const response = await global.$axios
        .put(`department/updateuser/${getdata.DepartmentId}`, data)
        .then(async (response) => {
          if (response.data.Status == 200) {
            toast.success("Departement Added Sucessfully");
            setTimeout(() => {
              Navigate(-1);
            }, [1500]);
          } else {
            toast.error("SomeThing Went Wrong");
          }
        })
        .catch((e) => {
          console.error("departementassign", e);
        });
    }
  };

  const [err, seterr] = useState(0);

  const validation = async (val) => {
    const response = await global.$axios.get(
      `/category/validateproductcategory/${AppCode}/${val}`
    );
    seterr(response.data.Message);
  };
  const columns = [
    {
      field: "UserId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 120,
      align: "center",
      headerAlign: "center",
      valueGetter: (cellValues) => cellValues?.row.DepartmentUser?.UserId,
    },
    {
      field: "UserFirstName",
      headerClassName: "super-app-theme--header",
      headerName: "Users",
      width: 240,
      align: "center",
      headerAlign: "center",
      valueGetter: (cellValues) => cellValues?.row?.DepartmentUser?.username,
    },
    {
      field: "depart",
      headerClassName: "super-app-theme--header",
      headerName: "Assigned",
      width: 240,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return TicketAssignedRatio(cellValues);
      },
    },
    {
      field: "TicketView",
      headerClassName: "super-app-theme--header",
      headerName: "Ticket View",
      width: 240,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return TicketViewRatio(cellValues);
      },
    },
    {
      field: "Reporting",
      headerClassName: "super-app-theme--header",
      headerName: "Reporting To",
      width: 430,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return Reportingdrop(cellValues);
      },
    },
  ];

  function TicketViewRatio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={
            cellValues?.row?.TicketView === null
              ? "Yes"
              : cellValues?.row?.TicketView
          }
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio size="small" />}
            label="All"
            labelPlacement="end"
            onClick={() =>
              ticketview(1, "Yes", cellValues?.row?.DepartmentUser?.UserId)
            }
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio size="small" />}
            label="Self"
            labelPlacement="end"
            onClick={() =>
              ticketview(1, "No", cellValues?.row?.DepartmentUser?.UserId)
            }
          />
        </RadioGroup>
      </Grid>
    );
  }
  function TicketAssignedRatio(cellValues) {
    return (
      <Grid
        style={{
          display: "flex",
          padding: "0px 0px 0px 2px",
        }}
      >
        <RadioGroup
          sx={{ display: "flex", justifyContent: "center" }}
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={
            cellValues?.row?.Assigned === null
              ? "Yes"
              : cellValues?.row?.Assigned
          }
        >
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="Yes"
            control={<Radio size="small" />}
            label="Yes"
            labelPlacement="end"
            onClick={() =>
              assigned(1, "Yes", cellValues?.row?.DepartmentUser?.UserId)
            }
          />
          <FormControlLabel
            sx={{
              display: "flex",
              gap: "5px",
              ".css-ahj2mt-MuiTypography-root": { fontSize: "14px" },
            }}
            value="No"
            control={<Radio size="small" />}
            label="No"
            labelPlacement="end"
            onClick={() =>
              assigned(1, "No", cellValues?.row?.DepartmentUser?.UserId)
            }
          />
        </RadioGroup>
      </Grid>
    );
  }
  function Reportingdrop(cellValues) {
    return (
      <>
        <Autocomplete
          size="small"
          id="Departement"
          value={headValue}
          options={headdrop}
          fullWidth
          getOptionLabel={(option) => option.Username || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select Reporting To "
              variant="outlined"
            />
          )}
          onChange={(event, newValue) => {}}
        />
      </>
    );
  }
  return (
    <div>
      <Grid
        container
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0px",
              color: "#0071BC",
              cursor: "pointer",
            }}
            onClick={() => Navigate(-1)}
          >
            Assign Team /
          </span>{" "}
          <span style={{ color: "#0071BC", fontSize: "22px", fontWeight: 600 }}>
            {" "}
            {getdata.DepartmentName}
          </span>
        </div>
      </Grid>
      <Grid
        xs={12}
        container
        style={{
          display: "flex",
          // justifyContent: "space-between",
          alignContent: "center",
          gap: "10px",
        }}
      >
        <Grid item md={3.5} xs={12}>
          <p style={style5.title}>Department Head * </p>
          <Autocomplete
            size="small"
            id="Departement"
            multiple
            disableCloseOnSelect
            value={headValue}
            options={headdrop}
            isOptionEqualToValue={(option, value) =>
              option.Username === value.Username
            }
            getOptionLabel={(option) => option.Username || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Departement Head "
                variant="outlined"
                sx={{
                  ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                    padding: "0px",
                  },
                }}
              />
            )}
            onChange={(event, newValue) => {
              SetHeadValue(newValue);
            }}
          />
        </Grid>

        <Grid item md={2} xs={12}>
          <FormControl>
            <p style={{ margin: "10px 0px 5px 0px", fontSize: "14px" }}>
              Round Robin
            </p>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              defaultValue={Round}
              value={Round}
              onChange={(e) => {
                SetRound(e.target.value);
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio size="small" />}
                label="Yes"
                onClick={() => {
                  RoundRobinapi(1, "Yes");
                  localStorage.setItem("round", JSON.stringify("Yes "));
                }}
              />
              <FormControlLabel
                value="No"
                control={<Radio size="small" />}
                label="No"
                onClick={() => {
                  RoundRobinapi(1, "No");
                  localStorage.setItem("round", JSON.stringify("No"));
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {Round === "No" ? (
          <Grid item md={3.5} xs={12}>
            <p style={style5.title}>Default Assign To</p>
            <Autocomplete
              fullWidth
              size="small"
              id="Designation"
              value={Assign}
              options={headdrop}
              isOptionEqualToValue={(option, value) =>
                option.Username === value.Username
              }
              getOptionLabel={(option) => option.Username || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select "
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                SetAssign(newValue);
              }}
            />
          </Grid>
        ) : (
          ""
        )}
      </Grid>

      <Grid
        container
        xs={12}
        style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}
      >
        <div style={{ height: 450, width: "100%" }}>
          <DataGrid
            sx={{
              boxShadow: 2,
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .super-app-theme--header": {
                color: "#0071BC",
                fontSize: "16px",
              },
              ".MuiTablePagination-displayedRows": {
                margin: "0px",
              },
              ".MuiTablePagination-selectLabel": {
                margin: "0px",
              },
            }}
            initialState={{
              ...UserTableValue.initialState,
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            rows={UserTableValue}
            columns={columns}
            rowHeight={33}
            pageSize={10}
            getRowId={(row) => row.DepartmentUserId}
            // checkboxSelection
          />
        </div>
        <center style={{ marginTop: "20px" }}>
          <Button
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "12px",
            }}
            disabled={err === 1 ? true : false}
            size="small"
            variant="contained"
            onClick={() => {
              department();
              // window.location.reload(false);
            }}
          >
            Submit
          </Button>
        </center>
      </Grid>

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default DepartmentAssign;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "10px",
  },
};
const mainmenu = {
  width: "180px",
  height: "100%",
};
const style5 = {
  title: {
    fontSize: "14px",
    margin: "5px 0px 5px 0px",
  },
};
