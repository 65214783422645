import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function Dailyapex({ chartWidth }) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [width, setWidth] = useState(chartWidth);

  useEffect(() => {
    setWidth(chartWidth); // Update state when parent resizes
  }, [chartWidth]);

  const [value, setvalue] = useState(dayjs());
  const [state, setState] = useState({
    series: [
      {
        name: "Tickets",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "area",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
        colors: "#0071BC50",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 1,
        colors: ["#0071BC", "#50BC71"],
      },

      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        position: "top",
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        show: false,
      },
    },
  });
  const getdetails = async () => {
    const { data } = await global.$axios.get(
      `/dashboard/monthlyTicket/${AppCode}/${value.$y}`
    );
    if (data.status === 200) {
      setState({
        ...state,
        series: [
          {
            name: "Tickets",
            data: data.message.map((x) => x.ticketCount),
          },
        ],
      });
    }
  };
  useEffect(() => {
    getdetails();
  }, [value]);
  return (
    <Grid container item xs={12}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Typography sx={{ fontSize: "14px", px: 0.5 }}>
          Monthly Average Tickets
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            openTo="year"
            value={value}
            maxDate={dayjs()}
            onChange={(e) => {
              setvalue(e);
            }}
            views={["year"]}
            slotProps={{
              textField: {
                size: "small",
                variant: "standard",
                InputProps: {
                  disableUnderline: true, // <== added this
                },
                sx: {
                  width: 100,
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <div
        style={{
          width: "100%",
          overflowX: "hidden",
          maxWidth: `${width}`,
          margin: "0 auto",
        }}
      >
        <ReactApexChart
          key={width}
          options={state.options}
          series={state.series}
          type="area"
          height={260}
          width="100%"
        />
      </div>
    </Grid>
  );
}

export default Dailyapex;
