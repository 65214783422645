import Modal from "@mui/material/Modal";
import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  TextField,
  useTheme,
} from "@mui/material";
import { CloseIcon } from "../../../../Assets/Icon";

import { ToastContainer, toast } from "react-toastify";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Dialog, useMediaQuery } from "@material-ui/core";
import ReassignTicketPopup from "../ReassignTicketPopup";

const Edituserpopup = ({
  data,
  UserEditPopupOpen,
  setUserEditPopupOpen,
  active,
}) => {
  const AppCode = global.$userData.logindata.AppCode;
  const UserId = global.$userData.logindata.UserId;

  const handleClose = () => setUserEditPopupOpen(false);

  const [fristName, setFristName] = useState("");

  const [LastName, setLastName] = useState("");
  const [EmployeeId, setEmployeeId] = useState("");
  const [MobileNumber, setMobileNumber] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [Password, setPassword] = useState("");
  const [Department, setDepartment] = useState("");
  const [SecondaryDepartment, setSecondaryDepartment] = useState([]);

  const [branchId, setBranchId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [designation, setDesignation] = useState("");
  const [RowId, setRowId] = useState("");

  const [departmentDrop, setDepartmentDrop] = useState("");

  const [secondaryDeptDrop, setSecondaryDeptDrop] = useState([]);
  const [BranchDrop, SetBranchDropDrop] = useState([]);
  const [DesignationDrop, SetDesignationDrop] = useState([]);
  const [RoleDrop, setRoleDropDrop] = useState([]);
  const [Admin, setAdmin] = useState("");
  const [Resigned, setResigned] = useState("No");
  const [openReassign, setOpenReassign] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [userList, setUserList] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleResign = async (e) => {
    if (e.target.value) {
      setOpenReassign(true);
    } else {
      setResigned(e.target.value);
    }
  };

  const editview = async () => {
    const response = await global.$axios
      .get(`userinfo/view/${AppCode}/${data}`)
      .then(async (response) => {
        var res = response?.data?.Message;
        await Secondarydepartment(res?.departments?.DepartmentId);
        setFristName(res?.UserFirstName);
        setMobileNumber(res?.UserMobileNumber);
        setMN(res?.UserMobileNumber);
        setLastName(res?.UserLastName);
        setDepartment(res?.departments);
        setSecondaryDepartment(response.data.SecondaryDepartmentName);
        setBranchId(res?.branch_master);
        setDesignation(res?.Ticket_designation);
        setRoleId(res?.Ticket_role);
        setEmployeeId(res?.EmployeeId);
        setEmailId(res?.UserEmailId);
        setEM(res?.UserEmailId);
        setPassword(res?.Password);
        setAdmin(res?.AccessAdminTicket);
        setRowId(res?.UserId);
        setResigned(res?.Resigned);
      })
      .catch((e) => {
        console.error("usereditviewadd", e);
      });
  };

  const department = async () => {
    const response = await global.$axios
      .get(`userinfo/dept/${AppCode}`)
      .then(async (response) => {
        setDepartmentDrop(response.data.Message);
      })
      .catch((e) => {
        console.error("usereditdepartmentpopup", e);
      });
  };
  const Secondarydepartment = async (id) => {
    let data2 = {
      DepartmentId: id,
    };

    const response = await global.$axios
      .put(`userinfo/secdept/${AppCode}`, data2)
      .then(async (response) => {
        setSecondaryDeptDrop(response.data.message);
      })
      .catch((e) => {
        console.error("usersecondarydepartp", e);
      });
  };

  const Branchdrop = async () => {
    const response = await global.$axios
      .get(`userinfo/Branchlist/${AppCode}`)
      .then(async (response) => {
        SetBranchDropDrop(response?.data?.Message);
      })
      .catch((e) => {
        console.error("editusepop", e);
      });
  };
  const Desiginationdrop = async () => {
    const response = await global.$axios
      .get(`userinfo/Desingnationlist/${AppCode}`)
      .then(async (response) => {
        SetDesignationDrop(response?.data?.Message);
      })
      .catch((e) => {
        console.error("editdesigination", e);
      });
  };
  const Roledrop = async () => {
    const response = await global.$axios
      .get(`userinfo/roledrop/${AppCode}`)
      .then(async (response) => {
        setRoleDropDrop(response?.data?.Message);
      })
      .catch((e) => {
        console.error("edituserroledrop", e);
      });
  };

  const user = async () => {
    if (
      fristName == "" ||
      EmailId == "" ||
      Password == "" ||
      !(Department !== null && Object.values(Department).length !== 0) ||
      !(designation !== null && Object.values(designation).length !== 0) ||
      !(roleId !== null && Object.values(roleId).length !== 0)
    ) {
      toast.error("Please Fill Required Fields");
      return;
    } else if (MobileNumber.length !== 10) {
      toast.error("Mobile Number Must be 10 Digit");
      return;
    } else if (EmailId != "" && NoValidEmail) {
      toast.error("Please Provide Valid Mail");
      return;
    } else {
      const SecondaryId = SecondaryDepartment.map((e) => e.DepartmentId);
      let data1 = {
        AppCode: AppCode,
        UserFirstName: fristName,
        UserLastName: LastName,
        EmployeeId: EmployeeId,
        UserMobileNumber: MobileNumber,
        UserEmailId: EmailId,
        Password: Password,
        Department: Department?.DepartmentId,
        SecondaryDepartmentId: SecondaryId,
        BranchId: branchId?.BranchId,
        Designation: designation?.DesignationId,
        RoleId: roleId?.RoleId,
        UpdatedBy: UserId,
        UserId: RowId,
        AccessAdminTicket: Admin,
        Resigned: Resigned,
      };

      const response = await global.$axios
        .post("userInfo/edit", data1)
        .then(async (response) => {
          if (response.data.Status === 200) {
            if (Resigned === "Yes") {
              toast.success("User Moved To Resigned");
              handleClose();
              return;
            } else {
              toast.success("User Edited");
            }
            setUserEditPopupOpen(false);
            active();
            handleClose();
          } else {
            toast.error("SomeThing Went Wrong");
          }
        })
        .catch((e) => {
          console.error("edituserpop", e);
        });
    }
  };

  /// edit validation

  const [em, setEM] = useState("");
  const [mn, setMN] = useState("");

  // ContactNumber validation

  const [numberErr, setNumberErr] = useState(0);

  const contactValidation = async (val) => {
    const response = await global.$axios
      .get(`userinfo/validatemn/${AppCode}/${val}`)
      .then(async (response) => {
        setNumberErr(response.data.Message);
      })
      .catch((e) => {
        console.error("usereditcontactvaidationpopup", e);
      });
  };

  const [mobileerr, setmobileerr] = useState(false);
  const mobile = () => {
    if (MobileNumber.length !== 10) {
      setmobileerr(true);
    } else {
      setmobileerr(false);
    }
  };
  // emailid Validate
  const [emailErr, setEmailErr] = useState(0);

  const emailvalidation = async (val) => {
    const response = await global.$axios
      .get(`userinfo/validatemailid/${AppCode}/${val}`)
      .then(async (response) => {
        setEmailErr(response.data.Message);
      })
      .catch((e) => {
        console.error("emailvalidation", e);
      });
  };
  //Mail Format Validate
  const [NoValidEmail, setNoValidEmail] = useState(false);
  const MailValidate = (val) => {
    if (val.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/)) {
      setNoValidEmail(false);
    } else {
      setNoValidEmail(true);
    }
  };
  useEffect(() => {
    editview();
    department();
    Branchdrop();
    Desiginationdrop();
    Roledrop();
  }, [data]);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth={"md"}
        open={UserEditPopupOpen}
        onClose={handleClose}
      >
        <Box sx={{ padding: "10px 20px 20px 20px" }}>
          <Grid
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                color: "#0071BC",
                margin: "0px",
                fontWeight: 600,
              }}
            >
              Edit users
            </p>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid
            container
            spacing={0}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Grid
              container
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}> First Name *</p>
                <TextField
                  placeholder="Enter First Name"
                  id="First Name"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setFristName(e.target.value)}
                  value={fristName}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Last Name </p>
                <TextField
                  placeholder="Enter Last Name"
                  id="Last name"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setLastName(e.target.value)}
                  value={LastName}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Employee Id </p>
                <TextField
                  placeholder="Enter Employee Id"
                  id="Employee Id"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  value={EmployeeId}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Grid container md={3.5} xs={12}>
                <p style={style5.title}>Mobile Number * </p>
                <TextField
                  placeholder="Enter Mobile Number"
                  id="mobile Number"
                  variant="outlined"
                  type={Number}
                  inputProps={{ maxLength: "10" }}
                  size="small"
                  style={{ width: "100%" }}
                  onKeyUp={(e) => {
                    e.target.value !== "" && contactValidation(e.target.value);
                    mobile(e.target.value);
                  }}
                  helperText={
                    mobileerr === true ? (
                      <span style={{ color: "red" }}>10 Digit Required</span>
                    ) : numberErr === 1 ? (
                      <span style={{ color: "red" }}>Already Exist</span>
                    ) : (
                      <span style={{ color: "transparent" }}>"help"</span>
                    )
                  }
                  onChange={(e) => setMobileNumber(e.target.value)}
                  value={MobileNumber}
                  error={numberErr === 1 && MobileNumber != mn ? true : false}
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Email Id *</p>
                <TextField
                  type="EmailId"
                  placeholder="Enter Email Id"
                  id="Email Id"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                  onChange={(e) => setEmailId(e.target.value)}
                  error={emailErr === 1 && EmailId != em ? true : false}
                  value={EmailId}
                  onKeyUp={(e) => {
                    e.target.value !== "" && emailvalidation(e.target.value);
                    MailValidate(e.target.value);
                  }}
                  helperText={
                    emailErr === 1 && EmailId != em ? (
                      <span style={{ color: "red" }}>Already Exist</span>
                    ) : NoValidEmail ? (
                      <span style={{ color: "darkred" }}>Not a Valid Mail</span>
                    ) : (
                      <span style={{ color: "transparent" }}>Good</span>
                    )
                  }
                />
              </Grid>

              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Password *</p>
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    value={Password}
                    placeholder="Enter The Password"
                    size="small"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style6.title}>Department *</p>
                <Autocomplete
                  id="Department"
                  size="small"
                  options={departmentDrop}
                  isOptionEqualToValue={(option, value) =>
                    option.DepartmentName === value.DepartmentName
                  }
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  value={Department}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDepartment(newValue);
                    Secondarydepartment(newValue.DepartmentId);
                    setSecondaryDepartment([]);
                  }}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}> Secondary Department </p>
                <Autocomplete
                  size="small"
                  multiple
                  value={SecondaryDepartment}
                  id="Secondary Departement"
                  options={secondaryDeptDrop}
                  isOptionEqualToValue={(option, value) =>
                    option.DepartmentName === value.DepartmentName
                  }
                  getOptionLabel={(option) => option.DepartmentName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                      sx={{
                        ".css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root":
                          {
                            padding: "0px",
                          },
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSecondaryDepartment(newValue);
                  }}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Branch </p>

                <Autocomplete
                  size="small"
                  id="Branch"
                  options={BranchDrop}
                  value={branchId}
                  isOptionEqualToValue={(option, value) =>
                    option.BranchName === value.BranchName
                  }
                  getOptionLabel={(option) => option.BranchName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setBranchId(newValue);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Designation *</p>
                <Autocomplete
                  size="small"
                  id="Designation"
                  options={DesignationDrop}
                  value={designation}
                  isOptionEqualToValue={(option, value) =>
                    option.DesignationName === value.DesignationName
                  }
                  getOptionLabel={(option) => option.DesignationName || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDesignation(newValue);
                  }}
                />
              </Grid>
              <Grid item md={3.5} xs={12}>
                <p style={style5.title}>Role *</p>

                <Autocomplete
                  size="small"
                  id="Role"
                  options={RoleDrop}
                  isOptionEqualToValue={(option, value) =>
                    option.RoleName === value.RoleName
                  }
                  getOptionLabel={(option) => option.RoleName || ""}
                  value={roleId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Category "
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setRoleId(newValue);
                  }}
                />
              </Grid>

              <Grid item md={3.5} xs={12}></Grid>
            </Grid>
            <Grid
              container
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginTop: "10px",
              }}
            >
              <Grid item md={3.5} xs={12}>
                <FormControl>
                  <FormLabel id="Admin Access" style={style5.title}>
                    Admin Access
                  </FormLabel>
                  <RadioGroup
                    value={Admin}
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="No"
                    name="radio-buttons-group"
                    onChange={(e) => setAdmin(e.target.value)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={3.5} xs={12}>
                <FormControl>
                  <FormLabel id="Resigned" style={style5.title}>
                    Resigned
                  </FormLabel>
                  <RadioGroup
                    row
                    value={Resigned}
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="No"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      handleResign(e);
                    }}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item md={3.5} xs={12}></Grid>
            </Grid>
          </Grid>
          <center style={{ marginTop: "20px" }}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              size="small"
              variant="contained"
              disabled={mobileerr || NoValidEmail || numberErr ? true : false}
              onClick={() => {
                user();
              }}
            >
              Update
            </Button>
          </center>
        </Box>
      </Dialog>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
      {openReassign && (
        <ReassignTicketPopup
          openReassign={openReassign}
          setOpenReassign={setOpenReassign}
          setResigned={setResigned}
          Department={Department}
          AppCode={AppCode}
          UserId={UserId}
          RowId={RowId}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          userList={userList}
          setUserList={setUserList}
        />
      )}
    </div>
  );
};

export default Edituserpopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
};
const style5 = {
  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "5px 0px 5px 0px",
    color: "black",
  },
};
const style6 = {
  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "0px 0px 0px 0px",
    color: "black",
  },
};
