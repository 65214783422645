import React, { memo, useMemo } from "react";
import Chart from "react-apexcharts";
import { Box, Typography } from "@mui/material";

const TicketStatusGraph = ({ statusList = [] }) => {
  // Process API data
  const { labels, series } = useMemo(() => {
    return {
      labels: statusList.map((item) => item.Name),
      series: statusList.map((item) => Number(item.Count)),
    };
  }, [statusList]);

  const colors = [
    "#4CAF50",
    "#FFC107",
    "#2196F3",
    "#9C27B0",
    "#FF5722",
    "#00BCD4",
    "#8BC34A",
  ];

  const options = {
    chart: {
      type: "donut",
    },
    labels: ["New", "Progress", "Hold", "Completed", "Solved"],
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: false,
          },
        },
      },
    },
  };

  return (
    <Box display="flex" alignItems="center" gap={4}>
      {/* Donut Chart */}
      <Box width={"50%"}>
        <Chart options={options} series={series} type="donut" height={300} />
      </Box>

      {/* Labels */}
      <Box width={"50%"}>
        {labels.map((label, index) => (
          <Box key={index} display="flex" alignItems="center" mt={1} gap={1}>
            <Box
              sx={{
                width: 8,
                height: 8,
                backgroundColor: colors[index % colors.length], // Ensure colors cycle
                borderRadius: "50%",
              }}
            />
            <Typography variant="body2">{label}</Typography>
            <Typography
              variant="body2"
              fontWeight="bold"
              sx={{ marginLeft: "auto" }}
            >
              {series[index]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(TicketStatusGraph);
