import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Note } from "../Assets/Icon";
import { useNavigate } from "react-router-dom";
import { Card, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import PushPinIcon from "@mui/icons-material/PushPin";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

function Notes() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  //usestate
  const [screen, setscreen] = useState(false);
  const [Notemap, SetNoteMap] = useState([]);
  const [NoteSide, SetNoteSide] = useState([]);
  const [NoteId, SetNoteId] = useState("");

  const navigate = useNavigate();

  //Dialog
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //api

  const Notesget = async () => {
    const response = await global.$axios
      .put(`/ticket/getappNotes`, {
        AppCode: AppCode,
        For: 5,
        UserId: userId,
      })
      .then((response) => {
        if (response.data.Message.length >= 1) {
          setscreen(true);
        } else {
          setscreen(false);
        }
        SetNoteMap(response?.data?.Message);
        NotesView(response?.data?.Message[0].AppNotesId);
      })
      .catch((e) => {
        console.error("notesget", e);
      });
  };
  const NotesView = async (Id) => {
    const response = await global.$axios
      .put(`ticket/viewEditAppNotes`, {
        AppCode: AppCode,
        For: 5,
        NotesId: Id,
      })
      .then((response) => {
        SetNoteSide(response.data.Message);
      })
      .catch((e) => {
        console.error("notesget", e);
      });
  };
  const NotesDelete = async () => {
    const response = await global.$axios
      .put(`ticket/remove`, {
        AppCode: AppCode,
        For: 5,
        NotesId: NoteId?.AppNotesId,
      })
      .then((response) => {
        Notesget();
      })
      .catch((e) => {
        console.error("notesget", e);
      });
  };
  useEffect(() => {
    Notesget();
  }, []);
  return (
    <>
      <Grid
        container
        spacing={0}
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          position: "relative",
        }}
      >
        <p
          style={{
            margin: "0px 0px 0px 0px",
            color: "#0071BC",
            fontSize: "18px",
            fontWeight: "600",
            cursor: "pointer",
          }}
        >
          Notes
        </p>
        <Button
          variant="contained"
          size="small"
          onClick={() => navigate("AddNotes")}
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            margin: "5px 0px 0px 0px",
          }}
        >
          +Add Notes
        </Button>
      </Grid>
      <Grid container lg={12} style={{ display: "flex", marginTop: "20px" }}>
        {screen === false ? (
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              marginTop: "80px",
              flexDirection: "column",
            }}
          >
            <Note />
            <p
              style={{
                color: "#2323234D",
                fontSize: "18px",
                marginTop: "10px",
              }}
            >
              No Notes Yet Now
            </p>
          </Grid>
        ) : (
          <>
            <Grid
              item
              lg={5}
              md={5}
              style={{
                margin: "0px",
                padding: "5px",
                maxHeight: "72vh",
                overflow: "auto ",
              }}
            >
              {Notemap.map((notes, index) => {
                return (
                  <Card
                    style={{
                      Width: "80vw",
                      padding: "10px",
                      cursor: "pointer",
                      marginTop: "5px",
                    }}
                    onClick={() => NotesView(notes.AppNotesId)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          fontSize: "14px",
                          color: "#0071BC",
                          fontWeight: "600",
                        }}
                      >
                        {notes.Title}
                      </p>
                      <PushPinIcon fontSize="small" />
                    </div>
                    <div
                      style={{
                        padding: "2px",
                        fontSize: "12px",
                      }}
                    >
                      {notes.Description.substring(0, 220)}
                      <Button
                        size="small"
                        sx={{
                          color: "#0071BC",
                          padding: "0px 5px ",
                          textTransform: "capitalize",
                        }}
                      >
                        Read More
                      </Button>
                    </div>
                  </Card>
                );
              })}
            </Grid>
            <Grid item lg={7} md={7} style={{ padding: "10px" }}>
              <Card style={{ Width: "80vw", height: "72vh", padding: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      margin: "5px 0px",
                      color: "#0071BC",
                      fontWeight: "600",
                    }}
                  >
                    {NoteSide?.Title}
                  </p>
                  <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}
                  >
                    <Tooltip title="Edit">
                      <IconButton>
                        <EditIcon
                          fontSize="small"
                          onClick={() =>
                            navigate("EditNotes", {
                              state: { NoteSide },
                            })
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton>
                        <DeleteIcon
                          fontSize="small"
                          onClick={() => {
                            SetNoteId(NoteSide);
                            handleOpen();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div
                  style={{ padding: "5px", fontSize: "14px" }}
                  dangerouslySetInnerHTML={{
                    __html: NoteSide?.Description,
                  }}
                >
                  {/* <div style={{ padding: "5px" }}>

                            {NoteSide.Description} */}
                </div>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
      <div>
        <Dialog
          // fullWidth
          maxWidth={"xs"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "400",
              display: "flex",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "400",
                margin: "0px",
              }}
            >
              Sure You Want To Delete This Notes.
            </p>{" "}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                color: "darkred",
                fontSize: "18px",
              }}
            >
              {NoteId.Title}
            </div>
          </DialogTitle>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "0px 0px 10px 0px",
            }}
          >
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                NotesDelete();
                handleClose();
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={handleClose}
            >
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default Notes;
