import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { CloseIcon, SearchIcon } from "../Assets/Icon";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function GlobalSearch({ isSearch, setIsSearch }) {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const [text, setText] = useState("");
  const [searchData, setSearchData] = useState([]);
  const abortControllerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();

  const handleClose = () => {
    setIsSearch(false);
  };

  useEffect(() => {
    const trimmedText = text.trim(); // Trim the input

    if (!trimmedText) return; // Prevent API call for empty or spaces

    // Cancel the previous request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new abort controller
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    const searchFunc = async () => {
      setLoading(true);
      try {
        let payload = {
          SearchTerm: text,
          AppCode: AppCode,
          UserId: userId,
        };
        const response = await global.$axios.post(
          "ticket/OverAllsearchTicketList",
          payload,
          {
            signal: abortController.signal,
          }
        );
        // console.log("OverAllsearchTicketList", response.data);

        if (response.data.Status === 200) {
          setSearchData(response.data.Message || []);
        } else {
          setSearchData([]);
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Search request failed:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    // Debounce API Call
    const debounceTimer = setTimeout(() => {
      searchFunc();
    }, 500); // Adjust debounce delay as needed (e.g., 500ms)

    return () => {
      clearTimeout(debounceTimer);
      abortController.abort(); // Cleanup previous API call
    };
  }, [text]);

  const onClose = () => {
    setText("");
    setSearchData([]);
  };

  return (
    <React.Fragment>
      <Dialog
        open={isSearch}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 10px",
          }}
        >
          <Box
            id="searcBox"
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              padding: "3px",
              background: "#fff",
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#23232340",
              borderRadius: "10px",
              textAlign: "center",
              width: "60%",
              alignSelf: "center",
              margin: "10px 0px",
              gap: "10px",
            }}
          >
            <SearchIcon style={{ color: "#2323234D" }} />
            <input
              placeholder="Search tickets.."
              type="text"
              className="searcInput"
              id="searchInput"
              style={{
                outline: "none",
                backgroundColor: "#FFFFFF",
                border: "none",
                width: "85%",
              }}
              value={text}
              onChange={(e) => setText(e.target.value)}
              autoComplete="off"
            />
            {text && (
              <div onClick={onClose}>
                <Close />
              </div>
            )}
          </Box>
          <div onClick={handleClose}>
            <Close />
          </div>
        </div>

        <DialogContent sx={{ minHeight: 400 }} dividers={"paper"}>
          <DialogContentText id="scroll-dialog-description">
            {loading ? (
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 400,
                }}
              >
                <CircularProgress size="3rem" />
              </Grid>
            ) : (
              <Grid container>
                {searchData.length > 0 ? (
                  <>
                    {searchData.map((item, index) => {
                      const issue = item.issue;
                      const product = item?.product;
                      const department = item.dept;
                      const assigned = item.assigned;
                      return (
                        <Grid
                          container
                          xs={12}
                          item
                          key={item.TicketId}
                          style={{
                            marginBottom: "5px",
                            border: "1px solid #23232340",
                            padding: "5px 10px",
                            borderRadius: "8px",
                            cursor: "pointer",
                            backgroundColor: "#DDF0FF",
                          }}
                          onClick={() => {
                            Navigate("searchView", {
                              state: { ticketId: item.TicketId },
                            });
                            setIsSearch(false);
                          }}
                        >
                          <Grid item xs={3.8}>
                            <Typography sx={styles.txt}>
                              Ticket Id :{" "}
                              <span style={styles.txt1}> #{item.TicketId}</span>
                            </Typography>
                            <Typography sx={styles.txt}>
                              Issue:{" "}
                              <span style={styles.txt1}>
                                {issue?.IssueName}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={3.8}>
                            <Typography sx={styles.txt}>
                              Assigned To :
                              <span style={styles.txt1}>
                                {assigned?.UserFirstName}
                              </span>
                            </Typography>
                            <Typography sx={styles.txt}>
                              Product :{" "}
                              <span style={styles.txt1}>
                                {product?.ProductName}
                              </span>
                            </Typography>
                          </Grid>
                          <Grid item xs={3.8}>
                            <Typography sx={styles.txt}>
                              Department :{" "}
                              <span style={styles.txt1}>
                                {department?.DepartmentName}
                              </span>
                            </Typography>
                            <Typography sx={styles.txt}>
                              Status :{" "}
                              <span style={styles.txt1}>{item.Status}</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}{" "}
                  </>
                ) : (
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 400,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "#232323" }}>
                      No Tickets Found
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

const styles = {
  txt: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#23232380",
  },
  txt1: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#232323",
  },
};
