import React, { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Box,
} from "@mui/material";

export default function BrandInactiveTable() {
  const AppCode = global.$userData.logindata.AppCode;
  //table drop
  const [activelist, setActiveList] = useState([]);
  //popup
  const [Restoredata, setRestoredata] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //api
  const active = async () => {
    const response = await global.$axios
      .get(`product/brand/inactivelist/${AppCode}`)
      .then(async (response) => {
        setINActiveRow([...response.data.Message]);
        setINActiveSearch([...response.data.Message]);
      })
      .catch((e) => {
        console.error("brandinactive", e);
      });
  };
  useEffect(() => {
    active();
  }, []);

  const [INActiveRow, setINActiveRow] = useState([]);
  const [INActiveSearch, setINActiveSearch] = useState([]);

  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = INActiveRow.filter((item) => {
        const BrandId = String(item.BrandId);
        const BrandName = String(item?.BrandName);
        const UpdatedBy = String(item?.UpdatedBy);
        const UpdatedDateTime = String(item?.UpdatedDateTime);
        const ETC = String(
          moment(item?.UpdatedDateTime, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );

        const text_data = term.toLowerCase();
        return (
          BrandId.indexOf(text_data) > -1 ||
          BrandName.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedDateTime.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1
        );
      });

      setINActiveSearch(ticketData);
    } else {
      setINActiveSearch(INActiveRow);
    }
  }
  //Default Sorting
  const [sortModel, setSortModel] = useState([
    {
      field: "BrandId",
      sort: "desc",
    },
  ]);

  const restore = async (id) => {
    const response = await global.$axios
      .put(`product/brand/restore/${AppCode}/${id}`)
      .then(async (response) => {
        if (response.data.status === 200) {
          toast.success("Moved To Active");
          active();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("brandinactive", e);
      });
  };

  const columns = [
    {
      field: "BrandId",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "edit",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      width: 200,

      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Restore">
              <Tooltip
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => {
                  setRestoredata(cellValues.row);
                  handleOpen();
                }}
              >
                <RestoreIcon />
              </Tooltip>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "BrandName",
      headerClassName: "super-app-theme--header",
      headerName: "Brand Name",
      width: 290,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "UpdatedBy",
      headerClassName: "super-app-theme--header",
      headerName: " Updated By",
      width: 290,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "UpdatedDateTime",
      headerName: "Updated At",
      width: 290,
      headerClassName: "super-app-theme--header",
      valueGetter: (fields) => {
        return fields?.row?.UpdatedDateTime !== ""
          ? moment(fields?.row?.UpdatedDateTime, "YYYY-MM-DD hh:mm").format(
              "DD-MM-YYYY hh:mm A"
            )
          : "-";
      },
    },
  ];
  return (
    <>
      <Grid
        item
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
        md={3}
        sm={3}
        xs={12}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            textAlign: "center",
            margin: "10px 0px 10px 0px",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={styles.searchInput}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <SearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>

      <div style={{ height: 480, width: "100%" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...INActiveRow.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={INActiveSearch}
          columns={columns}
          rowHeight={38}
          pageSize={10}
          getRowId={(row) => row.BrandId}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          // checkboxSelection
        />
        <div>
          <Dialog
            fullWidth
            maxWidth={"xs"}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                fontWeight: "400",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  margin: "0px",
                }}
              >
                Do you want to Restore{" "}
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    margin: "0px",
                    color: "#0071BC",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {Restoredata.BrandName}
                </p>{" "}
              </p>
            </DialogTitle>

            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={() => {
                  restore(Restoredata.BrandId);
                  handleClose();
                }}
                autoFocus
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{
                  maxHeight: "22px",
                  minHeight: "22px",
                  textTransform: "none",
                  padding: "12px",
                }}
                onClick={handleClose}
              >
                No{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </div>
    </>
  );
}
const style1 = {
  img: {
    width: "23px",
    height: "23px",
    cursor: "pointer",
    padding: "2px",
  },
};

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
};
