import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";
import { CloseIcon } from "../../../Assets/Icon";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const MenuRolePopup = ({ Refresh, setRefresh, RoleOpen, setRoleOpen }) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setRoleOpen(false);
  const [age, setAge] = React.useState("");
  const [roleName, setRoleName] = useState("");
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;

  const role = async () => {
    if (roleName === "") {
      toast.error("Please Fill Required Fields");
      return;
    } else {
      let data = { AppCode: AppCode, RoleName: roleName, UserId: userId };
      const response = await global.$axios
        .post("role/add", data)
        .then(async (response) => {
          if (response.data.status === 200) {
            setRoleName("");
            handleClose();
            toast.success("Role Added");
            setRefresh(!Refresh);
          } else {
            toast.error("Something Went Wrong");
          }
        })
        .catch((e) => {
          console.error("meanroleadd", e);
        });
    }
  };
  const [err, seterr] = useState(0);

  const validation = async (val) => {
    // role/validaterole/:AppCode/:RoleName
    const response = await global.$axios
      .get(`/role/validaterole/${AppCode}/${val}`)
      .then(async (response) => {
        seterr(response.data.Message);
      })
      .catch((e) => {
        console.error("rolevaludation", e);
      });
  };
  return (
    <div>
      <Modal
        open={RoleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modal}>
          <Grid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{ color: "#0071BC", fontSize: "16px", fontWeight: 600 }}
            >
              {" "}
              Add Role
            </span>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </Grid>

          <TextField
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
            sx={{ minWidth: 330 }}
            onKeyUp={(e) => {
              e.target.value !== "" && validation(e.target.value);
            }}
            error={err === 1 ? true : false}
            helperText={
              err === 1 ? (
                <span style={{ color: "red" }}>Already Exist</span>
              ) : (
                <span style={{ color: "transparent" }}>iuftghj</span>
              )
            }
            placeholder="Add Role Here"
            id="outlined-size-small"
            size="small"
            required
          />
          <center style={{}}>
            <Button
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              disabled={err === 1 ? true : false}
              size="small"
              variant="contained"
              onClick={() => {
                role();
              }}
            >
              Submit
            </Button>
          </center>
        </Box>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
};

export default MenuRolePopup;
const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    pt: 2,
    borderRadius: "10px",
  },
};
