import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

function ChangePassword() {
  const userData = global.$userData.logindata;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);

  const updatePassword = async () => {
    if (!currentPassword) {
      toast.error("Enter Your current password !");
      return;
    } else if (!newPassword) {
      toast.error("Enter New password !");
      return;
    } else if (!confirmPassword) {
      toast.error("Enter Confirm password !");
      return;
    } else if (newPassword != confirmPassword) {
      toast.error("New password & Confirm password should be same !");
      return;
    } else {
      let payload = {
        EmailId: userData.UserEmailId,
        OldPassword: currentPassword,
        NewPassword: newPassword,
        AppCode: userData.AppCode,
      };

      const response = await global.$axios.post("auth/changePassword", payload);

      if (response.data.Status == 200) {
        toast.success("Your password has been updated successfully !");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        toast.error(response.data.Message);
      }
    }
    try {
    } catch (error) {
      console.log("updatePassword", error);
    }
  };

  return (
    <>
      <Grid container style={styles.container}>
        <Grid item xs={12}>
          <Typography variant="h6">Change Password</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px 0px",
          }}
        >
          <FormControl sx={{ width: "31%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Current Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ width: "31%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword1 ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword1
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword1}
                    edge="end"
                  >
                    {showPassword1 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl sx={{ width: "31%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword2 ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword2
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword2}
                    edge="end"
                  >
                    {showPassword2 ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label=" Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={styles.btn}
          onClick={() => updatePassword()}
        >
          <Button variant="contained">Save Changes</Button>
        </Grid>
      </Grid>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
}
const styles = {
  container: {
    backgroundColor: "#F3FAFF",
    borderColor: "#E3F4FF",
    borderWidth: 1,
    borderRadius: "12px",
    padding: "10px",
    margin: "10px 0px",
  },
  btn: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    margin: "10px 0px",
  },
};

export default ChangePassword;
