import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  ChangePassIcon,
  ConfigIcon,
  EditIcon,
  Logout,
  LogoutIcon,
} from "../Assets/Icon";
import AppConfig from "./AppConfig";
import Profile from "./Profile";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import LogoutConfirmPop from "../LogoutConfirmPop";
import ChangePassword from "./ChangePassword";

function Setting() {
  const [tab, setTab] = useState("profile");
  const [openLogout, setOpenLogout] = useState(false);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = global.$userData.logindata;

  const logout = () => {
    dispatch({ type: "SET_USER_OUT", payload: false });
    localStorage.clear();
    Navigate("/auth");
  };

  return (
    <>
      {/* First Card  */}
      <Grid
        container
        style={{
          backgroundColor: "#F3FAFF",
          borderColor: "#E3F4FF",
          borderWidth: 1,
          borderRadius: "12px",
          padding: "10px",
        }}
      >
        <Grid item xs={6}>
          <Typography variant="h6">Settings</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          onClick={() => setOpenLogout(true)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "6px",
            cursor: "pointer",
          }}
        >
          <LogoutIcon style={{ color: "#FB3D3DB2" }} height={26} width={26} />
          <Typography sx={{ fontSize: "16px", color: "#FB3D3DB2" }}>
            Sign Out
          </Typography>
        </Grid>

        {/* Tabs  */}
        <Grid item xs={12} container style={{ gap: "10px", marginTop: "10px" }}>
          <Grid
            item
            xs={5.5}
            sm={3}
            onClick={() => setTab("profile")}
            style={tab == "profile" ? styles.activeTab : styles.inactiveTab}
          >
            <EditIcon
              style={
                tab == "profile" ? { color: "#0071BC" } : { color: "#232323" }
              }
            />
            <Typography sx={tab == "profile" ? styles.tabTxt : styles.intabTxt}>
              Profile
            </Typography>
          </Grid>
          {userData?.AccessAdminTicket == "Yes" && (
            <Grid
              item
              xs={5.5}
              sm={3}
              onClick={() => setTab("config")}
              style={tab == "config" ? styles.activeTab : styles.inactiveTab}
            >
              <ConfigIcon
                style={
                  tab == "config" ? { color: "#0071BC" } : { color: "#232323" }
                }
              />
              <Typography
                sx={tab == "config" ? styles.tabTxt : styles.intabTxt}
              >
                Config
              </Typography>
            </Grid>
          )}

          <Grid
            item
            xs={5.5}
            sm={3}
            onClick={() => setTab("changepass")}
            style={tab == "changepass" ? styles.activeTab : styles.inactiveTab}
          >
            <ChangePassIcon
              style={
                tab == "changepass"
                  ? { color: "#0071BC" }
                  : { color: "#232323" }
              }
            />
            <Typography
              sx={tab == "changepass" ? styles.tabTxt : styles.intabTxt}
            >
              Change Password
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Body  */}
      <Grid container>
        {tab == "config" && <AppConfig />}
        {tab == "profile" && <Profile />}
        {tab == "changepass" && <ChangePassword />}
      </Grid>
      {openLogout && (
        <LogoutConfirmPop
          logout={logout}
          openLogout={openLogout}
          setOpenLogout={setOpenLogout}
        />
      )}
    </>
  );
}

const styles = {
  activeTab: {
    display: "flex",
    backgroundColor: "#E3F4FF",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px 0px",
    cursor: "pointer",
  },
  inactiveTab: {
    display: "flex",
    backgroundColor: "#fff",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    justifyContent: "center",
    borderRadius: "10px",
    padding: "10px 0px",
    cursor: "pointer",
  },
  tabTxt: {
    fontSize: "14px",
    color: "#0071BC",
  },
  intabTxt: {
    fontSize: "14px",
    color: "#232323",
  },
};

export default Setting;
