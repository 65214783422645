import React from "react";
import { useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import { useEffect } from "react";
import {
  Tooltip,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { DataGrid, GridSearchIcon } from "@mui/x-data-grid";
import moment from "moment";

const PredefinedInactiveTable = () => {
  const AppCode = global.$userData.logindata.AppCode;

  const [PreInactive, setPreInactive] = useState([]);
  const [Restore, setRestore] = useState("");

  //delete
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //Inactive List

  const InactiveList = async () => {
    const response = await global.$axios
      .get(`predefined_reply/inacivelist/${AppCode}`)
      .then(async (response) => {
        setPreInactive([...response.data.Message]);
        setPredifinedSearch(response.data.Message);
      })
      .catch((e) => {
        console.error("Predefined Inactive List issue", e);
      });
  };

  //Restore
  const restore = async (SNo) => {
    const response = await global.$axios
      .put(`predefined_reply/restore/${AppCode}/${SNo}`)
      .then(async (response) => {
        if (response.data.status === 200) {
          toast.success("Restore Success");
          InactiveList();
          setTimeout(() => {
            handleClose();
          }, 1500);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("Predefined Restore Issue", e);
      });
  };

  const columns = [
    {
      field: "SNo",
      headerClassName: "super-app-theme--header",
      headerName: "ID",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Restore",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <>
            <Tooltip title="Restore">
              <Tooltip
                style={{ padding: "0px", cursor: "pointer" }}
                onClick={() => {
                  setRestore(cellValues.row);
                  handleOpen();
                }}
              >
                <RestoreIcon />
              </Tooltip>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "Title",
      headerClassName: "super-app-theme--header",
      headerName: "Title",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Comments",
      headerClassName: "super-app-theme--header",
      headerName: "Replies",
      width: 400,
      align: "center",
      headerAlign: "center",
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) => {
        var space = fields?.row?.Comments.replace(/<[^>]+>/g, "");
        var format = space.replace(/\&nbsp;/g, "");
        return format || "";
      },
    },

    {
      field: "UpdatedBy",
      headerClassName: "super-app-theme--header",
      headerName: "UpdatedBy",
      width: 190,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "UpdatedAt",
      headerClassName: "super-app-theme--header",
      headerName: " Updated At",
      width: 190,
      align: "center",
      headerAlign: "center",
      valueGetter: (fields) =>
        moment(fields?.row?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
          "DD-MM-YYYY hh:mm A"
        ) || "",
    },
  ];

  const [PredifinedSearch, setPredifinedSearch] = useState([]);
  //Search
  async function filterList(term) {
    let ticketData = [];
    if (term !== "") {
      ticketData = PreInactive.filter((item) => {
        const Predefined_reply_Id = String(item?.Predefined_reply_Id);
        const Title = String(item?.Title);
        const Comments = String(item?.Comments);
        const UpdatedBy = String(item?.UpdatedBy);
        const UpdatedAt = String(item?.UpdatedAt);
        const ETC = String(
          moment(item?.UpdatedAt, "YYYY-MM-DD hh:mm").format(
            "DD-MM-YYYY hh:mm A"
          )
        );

        const text_data = term.toLowerCase();
        return (
          Predefined_reply_Id.indexOf(text_data) > -1 ||
          Title.toLowerCase().indexOf(text_data) > -1 ||
          Comments.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedBy.toLowerCase().indexOf(text_data) > -1 ||
          UpdatedAt.toLowerCase().indexOf(text_data) > -1 ||
          ETC.toLowerCase().indexOf(text_data) > -1
        );
      });

      setPredifinedSearch(ticketData);
    } else {
      setPredifinedSearch(PreInactive);
    }
  }

  useEffect(() => {
    InactiveList();
  }, []);
  return (
    <>
      <Grid
        item
        md={2.7}
        style={{
          marginBottom: "5px",
        }}
      >
        <Box
          id="searcBox"
          sx={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-start",
            padding: "3px",
            background: "#FFFFFF",
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#2323234D",
            borderRadius: "10px",
            float: "right",
            textAlign: "center",
          }}
        >
          <input
            placeholder="Search..."
            type="text"
            className="searcInput"
            id="searchInput"
            style={{
              outline: "none",
              backgroundColor: "#FFFFFF",
              border: "none",
            }}
            autoComplete="off"
            onChange={(e) => filterList(e.target.value)}
          />
          <GridSearchIcon style={{ color: "#2323234D" }} />
        </Box>
      </Grid>
      <div style={{ height: 480, width: "100%", paddingTop: "5px" }}>
        <DataGrid
          sx={{
            boxShadow: 2,
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .super-app-theme--header": {
              color: "#0071BC",
              fontSize: "16px",
            },
            ".MuiTablePagination-displayedRows": {
              margin: "0px",
            },
            ".MuiTablePagination-selectLabel": {
              margin: "0px",
            },
          }}
          initialState={{
            ...PreInactive.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          rows={PredifinedSearch}
          columns={columns}
          rowHeight={38}
          pageSize={10}
          getRowId={(row) => row.SNo}
          // checkboxSelection
        />
      </div>
      <div>
        <Dialog
          fullWidth
          maxWidth={"xs"}
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              fontWeight: "400",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: 400,
                margin: "0px",
              }}
            >
              Do you want to Restore{" "}
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  margin: "0px",
                  color: "#0071BC",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {Restore.Title}
              </p>{" "}
            </p>
          </DialogTitle>

          <DialogActions
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={() => {
                restore(Restore.SNo);
                handleClose();
              }}
              autoFocus
            >
              Yes
            </Button>
            <Button
              variant="contained"
              style={{
                maxHeight: "22px",
                minHeight: "22px",
                textTransform: "none",
                padding: "12px",
              }}
              onClick={handleClose}
            >
              No{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </>
  );
};

export default PredefinedInactiveTable;
