import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Box, Typography } from "@mui/material";

const TicketResolutionChart = ({ ticketResolutionGraphList }) => {
  const [chartData, setChartData] = useState({
    series: [{ name: "Resolution Time", data: [] }],
    options: {
      chart: { type: "bar", toolbar: { show: false } },
      plotOptions: {
        bar: {
          borderRadius: 6,
          columnWidth: "50%",
          colors: {
            ranges: [
              { from: 0, to: 2, color: "#007bff" },
              { from: 2, to: 5, color: "#87CEFA" },
            ],
          },
        },
      },
      xaxis: { categories: [], labels: { style: { colors: "#777" } } },
      yaxis: {
        min: 0,
        // max: 24,
        // tickAmount: 5,
        labels: { formatter: (val) => `${val}hr` },
      },
      tooltip: { enabled: true },
      fill: {
        type: "gradient",
        gradient: { shade: "light", type: "vertical", stops: [0, 100] },
      },
      grid: { borderColor: "#eee" },
    },
  });

  useEffect(() => {
    const ticketDate = ticketResolutionGraphList.map((item) => item.date);
    const ticketTime = ticketResolutionGraphList.map(
      (item) => (Number(item.avgTime) / 3600).toFixed(2) // Convert seconds to hours and round to 2 decimal places
    );

    setChartData((prev) => ({
      ...prev,
      series: [{ name: "Resolution Time (hours)", data: ticketTime }],
      options: {
        ...prev.options,
        xaxis: { ...prev.options.xaxis, categories: ticketDate },
      },
    }));
  }, [ticketResolutionGraphList]);

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        p: 1,
      }}
    >
      {/* Chart */}
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={300}
      />

      {/* Footer */}
      {/* <Typography variant="body2" color="textSecondary" align="center" mt={2}>
        Avg Resolution Time On Nov
        <strong style={{ color: "#007bff" }}>2h</strong>
      </Typography> */}
    </Box>
  );
};

export default TicketResolutionChart;
