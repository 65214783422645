import { ToastContainer, toast } from "react-toastify";
import {
  Grid,
  TextField,
  Button,
  Modal,
  Autocomplete,
  Tooltip,
  createFilterOptions,
  IconButton,
  useTheme,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, display } from "@mui/system";

import React, { useEffect, useState } from "react";
import { CloseIcon, PinImage, Uplodeimg } from "../../Assets/Icon";

import { Link, useNavigate } from "react-router-dom";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import { Close } from "@mui/icons-material";
import ProductAdd from "./ProductAdd";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Dialog, useMediaQuery } from "@material-ui/core";
import ImageIcon from "@mui/icons-material/Image";

function Newticket() {
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const config = global.$userData.logindata.crm_app_license_master;
  const AccessAdminTicket = global.$userData.logindata.AccessAdminTicket;
  var ticketconfig = localStorage.getItem("Ticketconfig");

  const isCategory = config.Ticketcategory == "Yes";

  //UseStates
  const [showDepartments, setShowDepartments] = useState(true);
  const [company, setCompany] = useState(null);
  const [companyDrop, setCompanyDrop] = useState([]);
  const [contact, setContact] = useState(null);
  const [contactDrop, setContactDrop] = useState([]);
  const [Department, setDepartment] = useState(null);
  const [tickettype, settickettype] = useState("External");
  // config.internal_ticket == "Yes" ? "Internal" : "External";
  const [DepartDrop, setDepartDrop] = useState([]);
  const deptDrop = [];
  const [ticketSource, setTicketSource] = useState({
    TicketSourceId: 5,
    TicketSourceName: "Call",
  });
  const [ticketDrop, setTicketDrop] = useState([]);
  const [Product, setProduct] = useState(null);
  const [productDrop, setProductDrop] = useState([]);
  const ProductSelect = [];

  // category
  const CategorySelect = [];
  const [showCategory, setShowCategory] = useState(false);
  const [Category, setCategory] = useState(null);
  const [categoryDrop, setCategoryDrop] = useState([]);

  const IssueSelect = [];
  const [showIssues, setShowIssues] = useState(false);
  const [Issues, setIssues] = useState(null);
  const [IssueDrop, setIssueDrop] = useState([]);

  const [AttachmentLink, setAttachmentLink] = useState([]);

  const filter = createFilterOptions();
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [assign, setAssign] = useState(null);
  const [assignId, setAssignId] = useState("");
  const [assignDrop, setAssignDrop] = useState([]);
  const [showProduct, setShowProduct] = useState(false);
  const [TimeError, setTimeError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  let value = dayjs();
  value = value.add(5, "minutes");
  const [DT, setDT] = useState(value);
  let year = `${DT.$d.getFullYear()}-${
    DT.$d.getMonth() + 1 < 10
      ? "0" + (DT.$d.getMonth() + 1)
      : DT.$d.getMonth() + 1
  }-${DT.$d.getDate() < 10 ? "0" + DT.$d.getDate() : DT.$d.getDate()} ${
    DT.$d.getHours() < 10 ? "0" + DT.$d.getHours() : DT.$d.getHours()
  }:${DT.$d.getMinutes() < 10 ? "0" + DT.$d.getMinutes() : DT.$d.getMinutes()}`;

  /* Jodit Editor*/
  const editor = useRef(null);
  const [content, setContent] = useState("");
  // const plainString = content.replace(/<[^>]+>/g, "");

  /*Department, product, issue onFocus */
  const [activeList, setActiveList] = useState("");
  const [productactiveList, setProductActiveList] = useState("");
  const [categoryActiveList, setActiveCategoyList] = useState("");
  const [IssueActiveList, setIssueActiveList] = useState("");

  //Dynamic Product Add PopupOpen
  const [ProductAddOpen, setProductAddOpen] = useState(false);

  //buttondisable
  const [Buttondisable, SetButtondisable] = useState(false);

  const valuechanges = (event, newAlignment) => {
    settickettype(newAlignment);
  };
  const Navigate = useNavigate();

  const handleDepartment = (val) => {
    setDepartment(val);
    setShowDepartments(val !== null ? false : true);
    if (isCategory) {
      setShowCategory(true);
    } else {
      setShowIssues(true);
    }
  };

  const DepartmentShow = () => {
    setShowDepartments(true);
    setShowProduct(false);
    setShowIssues(false);
  };

  const CategoryShow = () => {
    if (Department) {
      setShowCategory(true);
      setShowDepartments(false);
      setShowIssues(false);
    } else {
      setShowCategory(false);
    }
  };

  const handleCategory = (val) => {
    setCategory(val);
    setShowCategory(val !== null ? false : true);
    setShowIssues(true);
  };

  const handleProduct = (val) => {
    setProduct(val);
    setShowProduct(val !== null ? false : true);
    setShowIssues(false);
  };

  const ProductShow = () => {
    if (showProduct) {
      if (Department && Issues) {
        setShowProduct(true);
        setShowIssues(false);
        setShowDepartments(false);
      } else {
        setShowProduct(false);
      }
    } else {
      setShowProduct(true);
      setShowIssues(false);
      setShowDepartments(false);
    }
  };

  const handleIssues = (val) => {
    setIssues(val);
    setShowIssues(val !== null ? false : true);
  };

  const IssueShow = () => {
    if (Department) {
      setShowIssues(true);
      setShowDepartments(false);
      setShowProduct(false);
    } else {
      setShowIssues(false);
    }
  };

  const companyList = async () => {
    const response = await global.$axios
      .get(`/ticket/companies/${AppCode}`)
      .then(async (response) => {
        setCompanyDrop(response.data.Message);
        if (config.internal_ticket == "Yes") {
          let data = response.data.Message.findIndex(
            (x) => x.AccountsId == config.internal_company
          );
          if (data != -1) {
            setCompany(response.data.Message[data]);
          }
        }
      })
      .catch((e) => {
        console.log("Company List Issue", e);
      });
  };

  const contactList = async (AccountId) => {
    if (!AccountId) {
      setContactDrop([]);
      setContact(null);
      setProductDrop([]);
      setProduct(null);
    } else {
      const response = await global.$axios
        .get(`/ticket/contacts/${AppCode}/${AccountId}`)
        .then(async (response) => {
          setContactDrop(response.data.Message);
          productList(response.data.Message[0].ContactId);
          if (config.internal_ticket == "Yes") {
            let data = response.data.Message.findIndex(
              (x) => x.ContactId === config.internal_contact
            );
            if (data != -1) {
              setContact(response.data.Message[data]);
            }
          } else {
            setContact(response.data.Message[0]);
          }
        })
        .catch((e) => {
          console.log("Contact List Issue", e);
        });
    }
  };

  const ticketList = async () => {
    const response = await global.$axios
      .get(`/source/activelist/${AppCode}`)
      .then(async (response) => {
        let source = response.data.Message;
        let getSource = source.filter((item) => item.DefaultSource === "Yes");
        setTicketDrop(response.data.Message);

        setTicketSource(
          getSource.length > 0 ? getSource[0] : response.data.Message[0]
        );
      })
      .catch((e) => {
        console.log("Ticket Source Issue", e);
      });
  };

  const departmentList = async () => {
    const response = await global.$axios
      .get(`ticket/department/${AppCode}`)
      .then(async (response) => {
        setDepartDrop(response.data.Message);
      })
      .catch((e) => {
        console.log("Department List Issue", e);
      });
  };

  const productList = async (ContactId) => {
    if (!ContactId) {
      setProductDrop([]);
      setProduct(null);
    } else {
      const response = await global.$axios
        .get(`/ticket/products/${AppCode}/${ContactId}`)
        .then(async (response) => {
          setShowProduct(true);
          setProductDrop(
            response.data.Message.length > 0 ? response.data.Message : []
          );
          setProduct(response?.data?.Message[0]);
          setProductActiveList(response?.data?.Message[0]?.ProductName);
        })
        .catch((e) => {
          console.log("Product List Issue", e);
        });
    }
  };

  const categoryListApi = async (deptid) => {
    if (!deptid || deptid !== deptid) {
      setCategoryDrop([]);
      setCategory(null);
    } else {
      const response = await global.$axios
        .get(`/ticket/getissuecategoryList/${AppCode}/${deptid}`)

        .then(async (response) => {
          setCategoryDrop(response.data.Message);
          setCategory(null);
        })
        .catch((e) => {
          console.log("categoryList Issue", e);
        });
    }
  };

  const getIssueListCategory = async (catId) => {
    if (!catId || catId !== catId) {
      setIssueDrop([]);
      setIssues(null);
    } else {
      let payload = {
        AppCode: AppCode,
        IssueCategoryId: catId,
        DepartmentId: Department?.DepartmentId,
      };
      const response = await global.$axios
        .post(`/ticket/getissuecategoryList`, payload)
        .then(async (response) => {
          setIssueDrop(response.data.Message);
          setIssues(null);
          setIssueActiveList("");
        })
        .catch((e) => {
          console.log("ticket/getissuecategoryList", e);
        });
    }
  };

  const issueList = async (deptid) => {
    if (!deptid || deptid !== deptid) {
      setIssueDrop([]);
      setIssues(null);
    } else {
      const response = await global.$axios
        .get(`/ticket/issue/${AppCode}/${deptid}`)
        .then(async (response) => {
          setIssueDrop(response.data.Message);
          setIssues(null);
          setIssueActiveList("");
        })
        .catch((e) => {
          console.log("Issue List Issue", e);
        });
    }
  };

  const assignList = async (deptid) => {
    if (!deptid) {
      setAssignDrop([]);
      setAssign(null);
    } else {
      const response = await global.$axios
        .get(`/ticket/assignto/${AppCode}/${deptid}`)
        .then(async (response) => {
          setAssignDrop(response.data.Message);
          setAssign(null);
        })
        .catch((e) => {
          console.log("Assign List Issue", e);
        });
    }
  };

  //ETC
  const ETC = async (IssueId) => {
    const response = await global.$axios
      .post(`/ticket/getetc`, {
        AppCode: AppCode,
        IssueId: IssueId,
        FuctionFor: "Ticket",
        DepartmentId: Department?.DepartmentId,
      })
      .then(async (response) => {
        const res = dayjs(response.data.Message);
        setDT(res);
      })
      .catch((e) => {
        console.log("ETC Issue", e);
      });
  };

  //Tag
  const TagArray = selected.map((e) => e.TagId);
  const AddTag = async () => {
    let data = {
      AppCode: AppCode,
      TagName: selected,
      UserId: userId,
    };
    const response = await global.$axios.post("/ticket/tag/add", data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      AddTag();
    }
  };

  const TagList = async () => {
    const response = await global.$axios.get("/ticket/tag/activelist/1");
    setOptions(response.data.Message);
  };

  //Ticket Create

  const createTicket = async () => {
    setTimeout(() => {
      SetButtondisable(false);
    }, 5000);
    if (
      AppCode === "" ||
      !(company != null && Object.values(company).length !== 0) ||
      !(contact != null && Object.values(contact).length !== 0) ||
      !(Department != null && Object.values(Department).length !== 0) ||
      !(Issues != null && Object.values(Issues).length !== 0) ||
      !(assign != null && Object.values(assign).length !== 0) ||
      !(Product != null && Object.values(Product).length !== 0) ||
      content === ""
    ) {
      toast.error("You Missed Required Fields");
      return;
    } else if (TimeError) {
      toast.error("Please Provide Valid Time");
      return;
    } else {
      let payload = {
        AppCode: AppCode,
        AccountId: company?.AccountsId,
        ContactId: contact?.ContactId,
        DepartmentId: Department?.DepartmentId,
        IssueId: Issues?.IssueId,
        AssignedTo: assignId,
        Tags: TagArray,
        ECAt: year,
        AttachmentFile: AttachmentLink,
        TicketDescription: content,
        RecordId: "",
        ProductId: Product?.ProductId,
        TicketSourceId: ticketSource?.TicketSourceId,
        TIcketShow: "",
        TicketOwner: assign?.UserId,
        UserId: userId,
        IssueCategoryId: Category?.IssueCategoryId ?? "",
      };
      const response = await global.$axios
        .post("/ticket/create", payload)
        .then(async (response) => {
          if (response.data.Status == 200) {
            toast.success("Ticket Assigned To The Respective Person");
            setTimeout(() => {
              window.location.reload(false);
            }, 1500);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((e) => {
          toast.error("Something Went Wrong");
        });
    }
  };

  const ClosecreateTicket = async () => {
    setTimeout(() => {
      SetButtondisable(false);
    }, 5000);
    if (
      AppCode === "" ||
      !(company != null && Object.values(company).length !== 0) ||
      !(contact != null && Object.values(contact).length !== 0) ||
      !(Department != null && Object.values(Department).length !== 0) ||
      !(Issues != null && Object.values(Issues).length !== 0) ||
      !(assign != null && Object.values(assign).length !== 0) ||
      content === ""
    ) {
      toast.error(`You Missed Required Fields`);
      return;
    } else if (TimeError) {
      toast.error("Please Provide Valid Time");
      return;
    } else {
      let payload = {
        AppCode: AppCode,
        AccountId: company?.AccountsId,
        ContactId: contact?.ContactId,
        DepartmentId: Department?.DepartmentId,
        IssueId: Issues.IssueId,
        AssignedTo: assignId,
        Tags: TagArray,
        ECAt: year,
        AttachmentFile: AttachmentLink,
        TicketDescription: content,
        RecordId: "",
        ProductId: Product?.ProductId,
        TicketSourceId: ticketSource?.TicketSourceId,
        TIcketShow: "",
        TicketOwner: assign?.UserId,
        UserId: userId,
      };
      const response = await global.$axios
        .post("/ticket/create", payload)
        .then(async (response) => {
          if (response.data.Status == 200) {
            toast.success("Ticket Created Successfully");
            return setTimeout(() => {
              Navigate("/app/Created");
            }, 1500);
          } else {
            toast.error("something went wrong");
          }
        })
        .catch((e) => {
          toast.error("Something Went Wrong");
        });
    }
  };

  useEffect(() => {
    companyList();
    ticketList();
    departmentList();
    TagList();
    if (
      global.$userData.logindata.crm_app_license_master.internal_ticket == "Yes"
    ) {
      settickettype(ticketconfig != null ? ticketconfig : "Internal");
      localStorage.setItem(
        "Ticketconfig",
        ticketconfig != null ? ticketconfig : "Internal"
      );
      contactList(
        global.$userData.logindata.crm_app_license_master.internal_company
      );

      productList(
        global.$userData.logindata.crm_app_license_master.internal_contact
      );
    }
  }, [refresh, Buttondisable]);
  return (
    <>
      <Grid container>
        <Grid
          container
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{
              color: "#0071BC",
              fontSize: "22px",
              fontWeight: 600,
              padding: "0px 0px 0px 5px",
            }}
          >
            New Ticket
          </span>

          {config.internal_ticket == "Yes" && (
            <ButtonGroup
              color="primary"
              size="small"
              sx={{ textTransform: "none" }}
            >
              <Button
                variant={tickettype == "Internal" ? "contained" : "outlined"}
                onClick={() => {
                  settickettype("Internal");
                  localStorage.setItem("Ticketconfig", "Internal");
                  companyList();
                  contactList(
                    global.$userData.logindata.crm_app_license_master
                      .internal_company
                  );

                  productList(
                    global.$userData.logindata.crm_app_license_master
                      .internal_contact
                  );
                }}
                sx={{ textTransform: "capitalize", px: 3 }}
              >
                Internal
              </Button>
              <Button
                variant={tickettype == "External" ? "contained" : "outlined"}
                onClick={() => {
                  settickettype("External");
                  localStorage.setItem("Ticketconfig", "External");
                }}
                sx={{ textTransform: "capitalize", px: 3 }}
              >
                External
              </Button>
            </ButtonGroup>
          )}
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px 0px 0px",
          }}
        >
          {tickettype == "External" && (
            <>
              <Grid
                item
                md={3.5}
                sm={7}
                xs={12}
                style={{ marginBottom: "5px" }}
              >
                <span style={{ fontSize: "14px", fontWeight: 500 }}>
                  Company Name
                </span>
                <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

                <Autocomplete
                  id="auto-complete"
                  size="small"
                  options={companyDrop}
                  value={company}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      contactList(null);
                    } else {
                      contactList(newValue.AccountsId);
                    }
                    setCompany(newValue);
                  }}
                  getOptionLabel={(option) => option.CompanyName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.AccountsId}>
                      {option.CompanyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Company Name"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                md={3.5}
                sm={7}
                xs={12}
                style={{ marginBottom: "5px" }}
              >
                <span style={{ fontSize: "14px", fontWeight: 500 }}>
                  Contact Name
                </span>
                <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

                <Autocomplete
                  size="small"
                  id="combo-box-demo"
                  value={contact}
                  options={contactDrop}
                  getOptionLabel={(option) => option.contactname}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.ContactId}>
                      {option.contactname}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Contact Name"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (!newValue) {
                      productList(null);
                    } else {
                      productList(newValue?.ContactId);
                    }
                    setContact(newValue);
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item md={3.5} sm={7} xs={12} style={{ marginBottom: "5px" }}>
            <span style={{ fontSize: "14px", fontWeight: 500 }}>
              Ticket Source
            </span>
            <Autocomplete
              size="small"
              defaultValue={ticketDrop.TicketSourceId}
              id="combo-box-demo"
              value={ticketSource}
              options={ticketDrop}
              getOptionLabel={(option) => option.TicketSourceName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select"
                  variant="outlined"
                />
              )}
              onChange={(event, newValue) => {
                setTicketSource(newValue);
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            cursor: "pointer",
            margin: "5px 0px 0px 0px",
          }}
        >
          <span
            style={style5.title}
            onClick={() => {
              DepartmentShow();
            }}
          >
            Department{" "}
          </span>
          <span style={{ color: "darkred" }}>*</span>
          {Department ? (
            <span
              style={style5.title2}
              onClick={() => setShowDepartments(false)}
            >
              / {Department.DepartmentName}
            </span>
          ) : null}
        </div>
        {showDepartments ? (
          <Grid container xs={12} style={{}}>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {DepartDrop.map((Department, index) => {
                if (index <= 5) {
                  return (
                    <Grid
                      item
                      md={1.7}
                      sm={8}
                      xs={12}
                      style={
                        activeList === Department.DepartmentName
                          ? style5.cardsActive
                          : style5.cards
                      }
                      onClick={() => {
                        if (!Department.DepartmentId) {
                          assignList(null);
                          issueList(null);
                          categoryListApi(null);
                        } else {
                          assignList(Department.DepartmentId);
                          isCategory
                            ? categoryListApi(Department.DepartmentId)
                            : issueList(Department.DepartmentId);
                        }
                        handleDepartment(Department);
                        setActiveList(Department.DepartmentName);
                      }}
                      key={index}
                    >
                      {Department.DepartmentName.substr(0, 20)}
                    </Grid>
                  );
                } else {
                  deptDrop.push(Department);
                }
              })}
            </Grid>
            <Grid
              item
              md={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
                margin: "5px 0px 0px 0px",
              }}
            >
              {deptDrop.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  value={Department}
                  options={deptDrop}
                  getOptionLabel={(option) => option.DepartmentName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.DepartmentId}>
                      {option.DepartmentName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Department"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setDepartment(newValue);
                    setActiveList(newValue);
                    handleDepartment(newValue);
                    assignList(newValue.DepartmentId);
                    isCategory
                      ? categoryListApi(newValue.DepartmentId)
                      : issueList(newValue.DepartmentId);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}

        {/* category  */}
        {isCategory && (
          <Grid container style={{ cursor: "pointer", marginTop: "5px" }}>
            <div>
              <span
                style={style5.title}
                onClick={() => {
                  CategoryShow();
                }}
              >
                Category <span style={{ color: "darkred" }}>*</span>
              </span>

              {Category ? (
                <span
                  style={style5.title2}
                  onClick={() => setShowCategory(false)}
                >
                  / {Category.IssueCategroyName}
                </span>
              ) : null}
            </div>
          </Grid>
        )}

        {showCategory ? (
          <Grid container>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {categoryDrop?.length > 0 ? (
                categoryDrop.map((categ, index) => {
                  if (index < 6) {
                    return (
                      <Grid
                        item
                        md={1.7}
                        sm={8}
                        xs={12}
                        style={
                          categoryActiveList === categ.IssueCategroyName
                            ? style5.cardsActive
                            : style5.cards
                        }
                        onClick={() => {
                          handleCategory(categ);
                          getIssueListCategory(categ.IssueCategoryId);
                          setActiveCategoyList(categ.IssueCategroyName);
                        }}
                        key={index}
                      >
                        {categ.IssueCategroyName}
                      </Grid>
                    );
                  } else {
                    CategorySelect?.push(categ);
                  }
                })
              ) : (
                <span style={{ color: "darkred" }}>
                  No Category Found In This Department.
                </span>
              )}
            </Grid>
            <Grid
              item
              lg={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {CategorySelect.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  value={Issues}
                  options={CategorySelect}
                  getOptionLabel={(option) => option.IssueCategroyName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.IssueCategoryId}>
                      {option.IssueCategroyName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Category"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setCategory(newValue);
                    handleIssues(newValue);
                    setActiveCategoyList(newValue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}

        {/* issues  */}
        <Grid container style={{ cursor: "pointer", marginTop: "5px" }}>
          <div>
            <span
              style={style5.title}
              onClick={() => {
                IssueShow();
              }}
            >
              Issue <span style={{ color: "darkred" }}>*</span>
            </span>

            {Issues ? (
              <span style={style5.title2} onClick={() => setShowIssues(false)}>
                / {Issues.IssueName}
              </span>
            ) : null}
          </div>
        </Grid>
        {showIssues ? (
          <Grid container>
            <Grid
              container
              item
              lg={8.5}
              xs={12}
              style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
            >
              {IssueDrop?.length > 0 ? (
                IssueDrop.map((Issues, index) => {
                  if (index < 6) {
                    return (
                      <Grid
                        item
                        md={1.7}
                        sm={8}
                        xs={12}
                        style={
                          IssueActiveList === Issues.IssueName
                            ? style5.cardsActive
                            : style5.cards
                        }
                        onClick={() => {
                          handleIssues(Issues);
                          setIssueActiveList(Issues.IssueName);
                          ETC(Issues.IssueId);
                        }}
                        key={index}
                      >
                        {Issues.IssueName}
                      </Grid>
                    );
                  } else {
                    IssueSelect?.push(Issues);
                  }
                })
              ) : (
                <span style={{ color: "darkred" }}>
                  No Issues Found In This Department.
                </span>
              )}
            </Grid>
            <Grid
              item
              lg={3.5}
              xs={5}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {IssueSelect.length > 0 && (
                <Autocomplete
                  fullWidth
                  size="small"
                  id="combo-box-demo"
                  value={Issues}
                  options={IssueSelect}
                  getOptionLabel={(option) => option.IssueName}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.IssueId}>
                      {option.IssueName}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Issue"
                      variant="outlined"
                      required
                    />
                  )}
                  onChange={(event, newValue) => {
                    setIssues(newValue);
                    handleIssues(newValue);
                    ETC(newValue.IssueId);
                    setIssueActiveList(newValue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        ) : null}

        <Grid
          container
          xs={12}
          style={{
            marginTop: "8px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item style={{ cursor: "pointer" }}>
            <span
              style={style5.title}
              onClick={() => {
                ProductShow();
              }}
            >
              Product <span style={{ color: "darkred" }}>*</span>
            </span>
            {Product ? (
              <span style={style5.title2} onClick={() => setShowProduct(false)}>
                / {Product.ProductName}
              </span>
            ) : null}
          </Grid>

          {showProduct ? (
            <Grid container>
              <Grid
                container
                item
                lg={8.5}
                xs={12}
                style={{ gap: "10px", display: "flex", margin: "5px 0px" }}
              >
                {productDrop?.length > 0 ? (
                  productDrop.map((Product, index) => {
                    if (index <= 5) {
                      return (
                        <Grid
                          item
                          md={1.7}
                          sm={8}
                          xs={12}
                          style={
                            productactiveList === Product?.ProductName
                              ? style5.cardsActive
                              : style5.cards
                          }
                          onClick={() => {
                            handleProduct(Product);
                            setProductActiveList(Product?.ProductName);
                          }}
                          key={index}
                        >
                          {Product?.ProductName.substr(0, 20)}
                        </Grid>
                      );
                    } else {
                      ProductSelect.push(Product);
                    }
                  })
                ) : (
                  <Grid
                    item
                    style={{
                      display: "flex",
                    }}
                  >
                    <span style={{ color: "darkred" }}>
                      Sorry! No Products Available For This Company.
                    </span>
                  </Grid>
                )}
                <Tooltip title="Add Product" style={{ cursor: "pointer" }}>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      if (company != null && contact != null) {
                        setProductAddOpen(true);
                      } else {
                        alert(
                          "Seems Like Company or Contact Detail Is Missing"
                        );
                      }
                    }}
                  />
                </Tooltip>
              </Grid>

              <Grid
                item
                lg={3.5}
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {ProductSelect.length > 0 && (
                  <Autocomplete
                    fullWidth
                    size="small"
                    id="combo-box-demo"
                    value={Product}
                    options={ProductSelect}
                    getOptionLabel={(option) => option.ProductName}
                    renderOption={(props, option) => (
                      <Box component="li" {...props} key={option.ProductId}>
                        {option.ProductName}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Product"
                        variant="outlined"
                        required
                      />
                    )}
                    onChange={(event, newValue) => {
                      handleProduct(newValue);
                      setProduct(newValue);
                      setProductActiveList(newValue);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
        </Grid>

        <Grid container>
          <Grid
            item
            md={3.5}
            sm={7}
            xs={12}
            style={{ margin: "15px 0px 5px 0px" }}
          >
            <span
              style={{
                fontSize: "14px",

                fontWeight: 500,
              }}
            >
              Assign To
            </span>
            <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>

            <Autocomplete
              size="small"
              id="auto-complete"
              options={assignDrop}
              value={assign}
              onChange={(event, newValue) => {
                setAssign(newValue);
                setAssignId(newValue.UserId);
              }}
              getOptionLabel={(option) => option.UserName}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select" />
              )}
            />
          </Grid>
        </Grid>

        <Grid container xs={12}>
          <p
            style={{
              fontWeight: 500,
              fontSize: "14px",
              margin: "10px 0px 0px 0px",
            }}
          >
            {" "}
            ETC <span style={{ color: "darkred" }}>*</span>
          </p>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDateTimePicker
                value={DT}
                onError={(newError) => setTimeError(newError)}
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                onChange={(newValue) => {
                  setDT(newValue);
                }}
                disablePast={true}
                views={["day", "month", "year", "hours", "minutes"]}
                format="DD-MM-YYYY hh:mm A"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        <Grid container md={7.7} xs={12}>
          <p
            style={{
              fontSize: "14px",
              fontWeight: 500,
              margin: "10px 0px 10px 0px",
            }}
          >
            Tags
          </p>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              multiple
              value={selected}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setSelected({
                    TagName: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setSelected({
                    TagName: newValue.inputValue,
                  });
                } else {
                  setSelected(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.TagName
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    TagName: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={options}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  onkeydown = { handleKeyDown }; // setOptions(option);
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                onkeydown = { handleKeyDown };
                return option.TagName;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.TagName}</li>
              )}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Or Add Tags" />
              )}
            />
          </Grid>
        </Grid>

        <Grid
          md={7.7}
          xs={12}
          container
          item
          style={{
            margin: "20px 0px 10px 0px",
          }}
        >
          <Grid item xs={12}>
            <p
              style={{
                padding: "10px 15px",
                background: "rgba(0, 113, 188, 0.1)",
                margin: "0px",
              }}
            >
              Description
              <span style={{ color: "darkred", paddingLeft: "2px" }}>*</span>
            </p>

            <JoditEditor
              ref={editor}
              value={content}
              onBlur={(e) =>
                setContent(
                  e === "<p><br></p>" || e === "<p>&nbsp;<br></p>"
                    ? ""
                    : e.trim()
                )
              }
            />
          </Grid>
          <Grid item style={{ margin: "10px 0px 0px 0px" }}>
            <p
              style={{
                fontSize: "14px",
                margin: "0px 0px 5px 0px",
              }}
            >
              Upload Attachment Here, If Any...
            </p>{" "}
            <Attachment
              setAttachmentLink={setAttachmentLink}
              AttachmentLink={AttachmentLink}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Grid>
        </Grid>
        <Grid
          container
          md={11}
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
            margin: "10px 0px",
            borderTop: "1px solid rgb(0,0,0,0.1)",
          }}
        >
          <Button
            size="small"
            disabled={Buttondisable}
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "10px 20px",
              margin: "5px 0px 0px 0px",
            }}
            onClick={() => {
              createTicket();
              SetButtondisable(true);
            }}
          >
            Save & New Ticket
          </Button>
          <Button
            size="small"
            disabled={Buttondisable}
            variant="contained"
            style={{
              maxHeight: "22px",
              minHeight: "22px",
              textTransform: "none",
              padding: "10px 20px",
              margin: "5px 0px 0px 0px",
            }}
            onClick={() => {
              ClosecreateTicket();
              SetButtondisable(true);
            }}
          >
            Save & Close
          </Button>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          fontSize={"14px"}
          fontWeight={"600"}
          color={"#232323"}
        />
      </Grid>
      {ProductAddOpen && (
        <ProductAdd
          AccountsId={company.AccountsId}
          ContactId={contact.ContactId}
          ProductAddOpen={ProductAddOpen}
          setProductAddOpen={setProductAddOpen}
          setProductDrop={setProductDrop}
        />
      )}
    </>
  );
}

export default Newticket;

const style5 = {
  cards: {
    background: "#F4F4F4",
    boxShadow:
      "1px 1px 2px rgba(255, 255, 255, 0.3), -1px -1px 2px rgba(176, 176, 176, 0.5), inset -3px 3px 6px rgba(176, 176, 176, 0.2), inset 3px -3px 6px rgba(176, 176, 176, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(176, 176, 176, 0.9)",
    borderRadius: "10px",
    fontSize: "14px",
    border: "hidden",
    textTransform: "none",
    fontWeight: 400,

    cursor: "pointer",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  cardsActive: {
    boxShadow:
      "1px 1px 2px rgba(255, 255, 255, 04), -1px -1px 2px rgba(176, 176, 176, 0.5), inset -3px 3px 6px rgba(176, 176, 176, 0.2), inset 3px -3px 6px rgba(176, 176, 176, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.9), inset 3px 3px 8px rgba(176, 176, 176, 0.9)",
    borderRadius: "10px",
    borderBottom: "4px solid #0071BC",
    background: "rgba(10, 124, 198, 0.1)",
    fontSize: "14px",
    textTransform: "none",
    fontWeight: 500,
    cursor: "pointer",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  title: {
    fontSize: "14px",
    fontWeight: 500,
    margin: "10px 0px 10px 0px",
  },
  title2: {
    color: "#0071BC",
    fontSize: "22px",
    fontWeight: 600,
    paddingLeft: "5px",
  },
  Commentimg: {
    height: "29px",
    width: "29px",
  },
};
const formControlLabelStyle = {
  "& .MuiFormControlLabel-label": {
    fontSize: "14px",
  },
};

const stylebox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "white",
  boxShadow: 24,
  pt: 2,
  pb: 2,
  pl: 4,
  pr: 4,
  borderRadius: "10px",
  outline: "transparent",
  maxHeight: "60vh",
  overflowY: "auto",
  overflowX: "hidden",
};
function Attachment({
  setAttachmentLink,
  AttachmentLink,
  setRefresh,
  refresh,
}) {
  const [pin, setpin] = useState(false);
  const handleOpenpin = () => setpin(true);
  const handleClosepin = () => setpin(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [vision, setvision] = useState("");
  var d = 0;
  function getTimestampInSeconds() {
    d++;
    setvision(d);
  }

  const [file, setFile] = useState([]);

  const handleFileChange = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleUploadFiles = (files) => {
    const Files = [...file];

    let limitExceeded = false;
    files.some((file) => {
      if (Files.findIndex((f) => f.name === file.name) === -1) {
        Files.push(file);
        if (Files.length > 5) {
          alert(`You can add only maximum of 5 files per Upload`);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setFile(Files);
  };

  const handleUploadClick = async () => {
    {
      file.forEach(async (files, index) => {
        var formData = new FormData();
        formData.append("file", files);
        const response = await global.$axios
          .post("ticket/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then(async (response) => {
            if (response.data.Status === 200) {
              setAttachmentLink((AttachmentLink) => [
                ...AttachmentLink,
                response.data.Message,
              ]);
              handleClosepin();
              getTimestampInSeconds();
            } else {
              toast.error("Something Went Wrong");
            }
          })

          .catch((e) => {
            console.error("Upload API Issue", e);
          });
      });
    }
    if (AttachmentLink.length == vision) {
      toast.success("File uploded Successfully");
    }
  };

  const removeFileAtIndex = (index) => {
    file.splice(index, 1);
    setFile(file);
    setRefresh(!refresh);
    document.getElementById("file").value = "";
  };
  const removeAttachementIndex = (index) => {
    AttachmentLink.splice(index, 1);
    setAttachmentLink(AttachmentLink);
    setRefresh(!refresh);
  };
  return (
    <>
      {AttachmentLink.length >= 1 ? (
        <span>
          {file.map((item, index) => {
            return (
              <>
                <ImageIcon
                  style={{
                    width: "20px",
                    height: "20px",
                    color: " rgb(45, 113, 188)",
                  }}
                />
                <span>{item.name}</span>
                <IconButton
                  onClick={() => {
                    removeAttachementIndex(index);
                    removeFileAtIndex(index);
                  }}
                >
                  {" "}
                  <Close />
                </IconButton>
              </>
            );
          })}
        </span>
      ) : (
        <>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={handleOpenpin}
          >
            <PinImage style={{ width: "18px", height: "18px" }} />
            <span style={{ fontSize: "12px" }}>Insert a File...</span>
          </div>
          <Dialog
            maxWidth={"sm"}
            fullWidth={true}
            fullScreen={fullScreen}
            open={pin}
            onClose={handleClosepin}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 0px",
                  borderBottom: "1px solid rgba(0,0,0,0.04)",
                }}
              >
                <span style={{ color: "#0071BC", fontWeight: 500 }}>
                  Attachment File
                </span>
                <IconButton
                  onClick={() => {
                    handleClosepin();
                    setFile([]);
                  }}
                  sx={{ padding: "0px" }}
                >
                  <CloseIcon style={{ width: "25px", height: "25px" }} />
                </IconButton>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Uplodeimg />
              </div>
              <Grid
                container
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "none" }}>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    id="file"
                    multiple
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                  />
                </div>
                <label
                  for="file"
                  style={{
                    color: "#0071BC",
                    fontSize: "12px",
                    fontWeight: 500,
                    cursor: "pointer",
                    background: "rgb(45,113,188)",
                    color: "#fff",
                    padding: "5px",
                    borderRadius: "10px",
                  }}
                >
                  Select File
                </label>
                {/* <input type="file" id="file" /> */}
                {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="file"
                      accept="text"
                      id="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleFileChange(e);
                      }}
                    />
                    <button
                      style={{
                        cursor: "pointer",
                        padding: 1,
                        background: "rgb(45,113,188)",
                        color: "white",
                        border: "none",
                        width: "70",
                      }}
                    >
                      Select File
                    </button>
                  </div> */}
                <Grid
                  container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file.length !== 0 && (
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <span style={{ fontSize: "10px" }}>
                        Tab on image to preview
                      </span>
                      {file.map((file, index) => {
                        return (
                          <>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: "5px",
                              }}
                            >
                              <Link
                                target="blank"
                                to={URL.createObjectURL(file)}
                              >
                                <img
                                  src={URL.createObjectURL(file)}
                                  style={{ maxWidth: 200, maxHeight: 200 }}
                                  alt="Thumb"
                                />
                              </Link>
                              <button
                                // onClick={() => setFile("")}
                                onClick={() => removeFileAtIndex(index)}
                                style={{
                                  cursor: "pointer",
                                  padding: 1,
                                  background: "red",
                                  color: "white",
                                  border: "none",
                                }}
                              >
                                {" "}
                                Remove This Image
                              </button>
                            </Grid>
                          </>
                        );
                      })}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                style={{
                  margin: "5px",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "13px",
                }}
              >
                <span>
                  Maximum size: <span style={{ color: "darkred" }}>5MB</span>
                </span>

                <Grid
                  container
                  item
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "5px 0px",
                  }}
                ></Grid>

                <Button
                  style={{
                    maxHeight: "22px",
                    minHeight: "22px",
                    textTransform: "none",
                  }}
                  disabled={file.length >= 1 ? false : true}
                  variant="contained"
                  onClick={() => {
                    handleUploadClick();
                  }}
                >
                  Upload
                </Button>
              </Grid>
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
}
