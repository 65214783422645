import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import {
  AppBar,
  Drawer,
  Box,
  CssBaseline,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";

import {
  TreeOne,
  Myworkspace,
  Setting,
  Recurring,
  Teamticket,
  Master,
  Tags,
  DashBoardIcon,
  CustomerIcon,
  DashBoardActive,
  CustomerActiveIcon,
  MyWorkspaceActive,
  TeamTicketActive,
  RecurringActiveIcon,
  TagsActive,
  MasterActive,
  SettingActive,
  CreatedActive,
  Created,
  TaggedActive,
  Tagged,
  ReportMain,
  Daily,
  Client,
  Product,
  NotesI,
  NoteActive,
  TagActive,
  Tag,
  SentryIcon,
} from "../Assets/Icon";

import TreeView from "@material-ui/lab/TreeView";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Content from "./Content";
import { useDispatch } from "react-redux";
import { TreeItem } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import LogoutConfirmPop from "../LogoutConfirmPop";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import GlobalSearch from "../Components/GlobalSearch";

const drawerWidth = 205;
function MainLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSearch, setIsSearch] = useState(false);

  const DH = new Date().getHours();
  const TT =
    DH >= 0 && DH < 12
      ? "Good Morning"
      : DH >= 12 && DH <= 15
      ? "Good Afternoon"
      : "Good Evening";

  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const open = Boolean(anchorElUser);

  var STab = localStorage.getItem("SideTab");
  const [activeLink, setActiveLink] = React.useState(
    STab !== null ? STab : "dashboard"
  );

  const logout = () => {
    dispatch({ type: "SET_USER_OUT", payload: false });
    localStorage.clear();
    Navigate("/auth");
  };

  //TreeView
  const [expanded, setExpanded] = React.useState([]);
  const [expanded1, setExpanded1] = React.useState([]);
  const [openLogout, setOpenLogout] = useState(false);

  const [toggleDashboard, setToggleDashboard] = useState(false);
  const [toggleReports, setToggleReports] = useState(false);

  const [dashName, setDashName] = useState("Dashboard");

  const handleChange = (event, nodes) => {
    localStorage.setItem("SideTab", nodes);
    setActiveLink(nodes);
    setExpanded(nodes);
  };

  const handleChange1 = (value) => {
    localStorage.setItem("SideTab", value);
    setActiveLink(value);
    setToggleDashboard(false);
  };

  const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 150,
      background: "none",
      color: "#FFF",
    },
    content: {
      flexDirection: "row-reverse",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(2.5),
      fontSize: 1,
    },
    labelText: {
      fontWeight: 600,
      flexGrow: 1,
    },
  }));

  function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, ...other } = props;

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon
              color="action"
              className={classes.labelIcon}
              style={{ fontSize: "12px" }}
            />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        classes={{
          content: classes.content,
        }}
        {...other}
      />
    );
  }

  //TreeView

  const classes = useTreeItemStyles();

  const drawer = (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#fff",
          width: `${drawerWidth}px`,
          paddingBottom: "3px",
        }}
      >
        <TreeOne style={{ width: "60%" }} />
      </div>
      <Box
        style={{
          height: "calc(100vh - 100px)",
          maxHeight: "calc(100vh - 100px)",
        }}
      >
        <List>
          {global?.$userData?.logindata?.AccessAdminTicket == "Yes" ? (
            <>
              <div
                style={
                  activeLink === "Dashboard" ||
                  activeLink === "app" ||
                  activeLink === "AgentDashboard" ||
                  activeLink === "CustomerDashboard" ||
                  activeLink === "DepartmentDashboard"
                    ? styles.dashBtn
                    : styles.dashBtn1
                }
                onClick={() => setToggleDashboard(!toggleDashboard)}
              >
                {activeLink === "Dashboard" ||
                activeLink === "app" ||
                activeLink === "AgentDashboard" ||
                activeLink === "CustomerDashboard" ||
                activeLink === "DepartmentDashboard" ? (
                  <DashBoardActive width={"30px"} height={"25px"} />
                ) : (
                  <DashBoardIcon width={"30px"} height={"25px"} />
                )}

                <span style={{}}>Dashboard</span>
                {toggleDashboard ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </div>
              {toggleDashboard && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      padding: "5px 15px",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleChange1("Dashboard");
                      navigate("Dashboard");
                    }}
                  >
                    <Typography
                      sx={activeLink === "Dashboard" ? styles.row : styles.row1}
                    >
                      Dashboard
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      padding: "5px 15px",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleChange1("AgentDashboard");
                      navigate("AgentDashboard");
                    }}
                  >
                    <Typography
                      sx={
                        activeLink === "AgentDashboard"
                          ? styles.row
                          : styles.row1
                      }
                    >
                      Agent
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      padding: "5px 15px",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleChange1("CustomerDashboard");
                      navigate("CustomerDashboard");
                    }}
                  >
                    <Typography
                      sx={
                        activeLink === "CustomerDashboard"
                          ? styles.row
                          : styles.row1
                      }
                    >
                      Customer
                    </Typography>
                  </div>
                  <div
                    style={{
                      width: "70%",
                      display: "flex",
                      padding: "5px 15px",
                      alignSelf: "center",
                    }}
                    onClick={() => {
                      handleChange1("DepartmentDashboard");
                      navigate("DepartmentDashboard");
                    }}
                  >
                    <Typography
                      sx={
                        activeLink === "DepartmentDashboard"
                          ? styles.row
                          : styles.row1
                      }
                    >
                      Department
                    </Typography>
                  </div>
                </div>
              )}
            </>
          ) : (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("Dashboard");
                // navigate("AgentDashboard");
                // navigate("CustomerDashboard");
                // navigate("DepartmentDashboard");
              }}
            >
              <ListItemButton
                onClick={() => {
                  localStorage.setItem("SideTab", "dashboard");
                  setActiveLink("dashboard");
                }}
                style={
                  activeLink === "dashboard"
                    ? styles.touchbtn
                    : styles.untouchbtn
                }
                align={"center"}
              >
                <ListItemIcon>
                  {activeLink === "dashboard" || activeLink === "app" ? (
                    <DashBoardActive width={"30px"} height={"25px"} />
                  ) : (
                    <DashBoardIcon width={"30px"} height={"25px"} />
                  )}
                </ListItemIcon>
                Dashboard
              </ListItemButton>
            </ListItem>
          )}

          {(global?.$userData?.logindata?.TicketCompaniesAccess == "1" ||
            global?.$userData?.logindata?.TicketCompaniesAccess == "2") && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("Company");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "company" ? styles.touchbtn : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "company");
                  setActiveLink("company");
                }}
                align={"center"}
              >
                <ListItemIcon>
                  {activeLink === "company" ? (
                    <CustomerActiveIcon width={"30px"} height={"25px"} />
                  ) : (
                    <CustomerIcon width={"30px"} height={"25px"} />
                  )}
                </ListItemIcon>
                Companies
              </ListItemButton>
            </ListItem>
          )}

          <ListItem
            disablePadding
            onClick={() => {
              navigate("Created");
            }}
          >
            <ListItemButton
              style={
                activeLink === "created" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "created");
                setActiveLink("created");
              }}
              align={"center"}
            >
              <ListItemIcon>
                {activeLink === "created" ? (
                  <CreatedActive width={"30px"} height={"25px"} />
                ) : (
                  <Created width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              Created
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => {
              navigate("MyWorkspace");
            }}
          >
            <ListItemButton
              style={
                activeLink === "myworkspace"
                  ? styles.touchbtn
                  : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "myworkspace");
                setActiveLink("myworkspace");
              }}
              align={"center"}
            >
              <ListItemIcon>
                {activeLink === "myworkspace" ? (
                  <MyWorkspaceActive width={"30px"} height={"25px"} />
                ) : (
                  <Myworkspace width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              My Workspace
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={() => {
              navigate("TaggedTickets");
            }}
          >
            <ListItemButton
              style={
                activeLink === "Tagged" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "Tagged");
                setActiveLink("Tagged");
              }}
            >
              <ListItemIcon>
                {activeLink === "Tagged" ? (
                  <TaggedActive width={"30px"} height={"25px"} />
                ) : (
                  <Tagged width={"30px"} height={"25px"} />
                )}
              </ListItemIcon>
              Tagged Tickets
            </ListItemButton>
          </ListItem>

          {(global?.$userData?.logindata?.TicketingDepartmentAdmin == "Yes" ||
            global?.$userData?.logindata?.AccessAdminTicket == "Yes") && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("TeamTickets");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "teamtickets"
                    ? styles.touchbtn
                    : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "teamtickets");
                  setActiveLink("teamtickets");
                }}
              >
                <ListItemIcon>
                  {activeLink === "teamtickets" ? (
                    <TeamTicketActive width={"30px"} height={"25px"} />
                  ) : (
                    <Teamticket width={"30px"} height={"25px"} />
                  )}
                </ListItemIcon>
                Team Tickets
              </ListItemButton>
            </ListItem>
          )}

          {global?.$userData?.logindata?.AccessAdminTicket === "Yes" && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("All");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "all" ? styles.touchbtn : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "all");
                  setActiveLink("all");
                }}
              >
                <ListItemIcon>
                  {activeLink === "all" ? (
                    <RecurringActiveIcon width={"30px"} height={"25px"} />
                  ) : (
                    <Recurring width={"30px"} height={"25px"} />
                  )}
                </ListItemIcon>
                All
              </ListItemButton>
            </ListItem>
          )}

          {global?.$userData?.logindata?.AccessAdminTicket == "Yes" && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("Master");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "master" ? styles.touchbtn : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "master");
                  setActiveLink("master");
                }}
              >
                <ListItemIcon>
                  {activeLink === "master" ? (
                    <MasterActive width={"30px"} height={"25px"} />
                  ) : (
                    <Master width={"30px"} height={"25px"} />
                  )}
                </ListItemIcon>
                Masters
              </ListItemButton>
            </ListItem>
          )}

          <div
            style={
              activeLink === "DailyReports" ||
              activeLink === "ClientReports" ||
              activeLink === "ProductReports"
                ? styles.dashBtn
                : styles.dashBtn1
            }
            onClick={() => setToggleReports(!toggleReports)}
          >
            {activeLink === "DailyReports" ||
            activeLink === "ClientReports" ||
            activeLink === "ProductReports" ? (
              <ReportMain
                width={"30px"}
                height={"25px"}
                style={{ color: "#232323" }}
              />
            ) : (
              <ReportMain
                width={"30px"}
                height={"25px"}
                style={{ color: "#fff" }}
              />
            )}

            <span style={{}}>Reports</span>
            {toggleReports ? <ExpandMoreIcon /> : <ChevronRightIcon />}
          </div>
          {toggleReports && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                backgroundColor: "#fff",
                cursor: "pointer",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  padding: "5px 15px",
                  alignSelf: "center",
                }}
                onClick={() => {
                  setActiveLink("DailyReports");
                  navigate("DailyReports");
                  setToggleReports(false);
                }}
              >
                <Typography
                  sx={activeLink === "DailyReports" ? styles.row : styles.row1}
                >
                  Ticket Status
                </Typography>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  padding: "5px 15px",
                  alignSelf: "center",
                }}
                onClick={() => {
                  setActiveLink("ClientReports");
                  navigate("ClientReports");
                  setToggleReports(false);
                }}
              >
                <Typography
                  sx={activeLink === "ClientReports" ? styles.row : styles.row1}
                >
                  Client
                </Typography>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "flex",
                  padding: "5px 15px",
                  alignSelf: "center",
                }}
                onClick={() => {
                  setActiveLink("ProductReports");
                  navigate("ProductReports");
                  setToggleReports(false);
                }}
              >
                <Typography
                  sx={
                    activeLink === "ProductReports" ? styles.row : styles.row1
                  }
                >
                  Product
                </Typography>
              </div>
            </div>
          )}

          <ListItem
            disablePadding
            onClick={() => {
              navigate("Notes");
            }}
          >
            <ListItemButton
              style={
                activeLink === "Notes" ? styles.touchbtn : styles.untouchbtn
              }
              onClick={() => {
                localStorage.setItem("SideTab", "Notes");
                setActiveLink("Notes");
              }}
            >
              <ListItemIcon>
                {activeLink === "Notes" ? (
                  <NoteActive width={"30px"} height={"25px"} />
                ) : (
                  <NotesI />
                )}
              </ListItemIcon>
              Notes
            </ListItemButton>
          </ListItem>

          {(global?.$userData?.logindata?.TicketingDepartmentAdmin == "Yes" ||
            global?.$userData?.logindata?.AccessAdminTicket == "Yes") && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("mail_to_ticket");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "MailTicket"
                    ? styles.touchbtn
                    : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "MailTicket");
                  setActiveLink("MailTicket");
                }}
              >
                <ListItemIcon>
                  {activeLink === "MailTicket" ? (
                    <UnsubscribeIcon
                      sx={{ color: "black", width: "30px", height: "25px" }}
                    />
                  ) : (
                    <UnsubscribeIcon
                      sx={{ color: "white", width: "30px", height: "25px" }}
                    />
                  )}
                </ListItemIcon>
                Convert To Ticket
              </ListItemButton>
            </ListItem>
          )}

          {/* {(global?.$userData?.logindata?.TicketingDepartmentAdmin == "Yes" ||
            global?.$userData?.logindata?.AccessAdminTicket == "Yes") && (
            <ListItem
              disablePadding
              onClick={() => {
                navigate("sentryTickets");
              }}
            >
              <ListItemButton
                style={
                  activeLink === "sentryTickets"
                    ? styles.touchbtn
                    : styles.untouchbtn
                }
                onClick={() => {
                  localStorage.setItem("SideTab", "sentryTickets");
                  setActiveLink("sentryTickets");
                }}
              >
                <ListItemIcon>
                  {activeLink === "sentryTickets" ? (
                    <SentryIcon
                      height={24}
                      width={24}
                      style={{ color: "#232323" }}
                    />
                  ) : (
                    <SentryIcon
                      height={24}
                      width={24}
                      style={{ color: "#fff" }}
                    />
                  )}
                </ListItemIcon>
                Sentry Tickets
              </ListItemButton>
            </ListItem>
          )} */}
        </List>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          background: "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
          >
            <MenuIcon />
          </IconButton>

          <Box item sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                sx={{
                  position: "relative",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Box
                  id="searcBox"
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "3px",
                    background: "#fff",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "#23232340",
                    borderRadius: "10px",
                    float: "right",
                    textAlign: "center",
                  }}
                  onClick={() => setIsSearch(true)}
                >
                  <input
                    placeholder="Search..."
                    type="text"
                    className="searcInput"
                    id="searchInput"
                    style={styles.searchInput}
                    autoComplete="off"
                  />
                  <SearchIcon style={{ color: "#2323234D" }} />
                </Box>
              </Grid>

              <Grid
                item
                xl={8}
                lg={8}
                md={6}
                sm={12}
                xs={12}
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <NotificationsNoneIcon
                  sx={{
                    color: "#000",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                  fontSize="large"
                />
                <div
                  style={{
                    textAlign: "end",
                    marginRight: 15,
                    alignSelf: "center",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    style={{ color: "#000", alignSelf: "center" }}
                  >
                    {global?.$userData?.logindata?.Name}
                  </Typography>
                  <Typography
                    variant="subtitle2  "
                    style={{ color: "#000", alignSelf: "center" }}
                  >
                    {TT}
                  </Typography>
                </div>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={global?.$userData?.logindata?.Name}
                    src={global?.$userData?.logindata?.ProfilePictureLink || ""}
                  />
                </IconButton>

                <Menu
                  id="account-menu"
                  open={open}
                  anchorEl={anchorElUser}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      Navigate("Setting");
                    }}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      setOpenLogout(true);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>

      {/*Mobile*/}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <TreeOne style={{ width: 0, padding: 0 }} />

        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#0071BC",
            },
          }}
        >
          {drawer}
        </Drawer>

        {/*Web*/}
        <Drawer
          variant="permanent"
          className="Main"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              border: "unset",
              overflow: "auto",
              background: "#0071BC",
              overflowY: "auto",
              height: "100vh",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/*Main Content*/}
      <Box
        component="main"
        className="Main"
        sx={{
          flexGrow: 1,
          p: 3,
          pb: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "calc(110vh - 100px)",
          maxHeight: "calc(110vh - 100px)",
          overflow: "auto",

          // backgroundImage:
          //   "linear-gradient(-180deg, #FFF 0%, rgba(10, 124, 198, 0.2) 100%)",
          background: "#FFFFFF",
        }}
      >
        <Toolbar />
        <Content>
          <Outlet />
        </Content>
        {openLogout && (
          <LogoutConfirmPop
            logout={logout}
            openLogout={openLogout}
            setOpenLogout={setOpenLogout}
          />
        )}

        {isSearch && (
          <GlobalSearch isSearch={isSearch} setIsSearch={setIsSearch} />
        )}
      </Box>
    </Box>
  );
}

export default MainLayout;

const styles = {
  searchInput: {
    outline: "none",
    backgroundColor: "#FFFFFF",
    border: "none",
  },
  touchbtn: {
    color: "black",
    background: "#FFFFFF",
    fontWeight: 600,
    letterSpacing: "0.5px",
    padding: "10px 5px",
    margin: "5px 0px",
  },
  untouchbtn: {
    color: "#FFFFFF",
    padding: "10px 5px",
  },
  touchbtn1: {
    dispay: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
    background: "#FFFFFF",
    fontWeight: 600,
    letterSpacing: "0.5px",
    padding: "10px 5px",
    margin: "5px 0px",
  },
  checkboxLabel: {
    border: "1px solid black",
    fontWeight: "100",
    fontSize: "20px",
  },
  dashBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 5px",
    background: "#FFFFFF",
    cursor: "pointer",
  },
  dashBtn1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 5px",
    cursor: "pointer",
    color: "#FFFFFF",
  },
  row: {
    color: "#0071BC",
    fontSize: "14px",
    fontWeight: 500,
  },
  row1: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#232323",
  },
};
