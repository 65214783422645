import { Button, Grid, Rating, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import { NudgeRed, TicketIdIcon, ViewIcon } from "../../Assets/Icon";
import {
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Avatar, makeStyles } from "@material-ui/core";
import moment from "moment";

function SearchView() {
  const location = useLocation();
  const ticketId = location.state.ticketId;
  const AppCode = global.$userData.logindata.AppCode;
  const userId = global.$userData.logindata.UserId;
  const valuedate = new Date();

  const Navigate = useNavigate();

  const [AttachmentLink, setAttachmentLink] = useState([]);
  const [ticketDetails, setTicketDetails] = useState({});
  const [tagList, setTagList] = useState([]);
  const [rating, setRating] = useState(0);
  const [Comment, setComment] = useState("");
  const [TemplateArray, setTemplateArray] = useState([]);
  const [TemplateComment, setTemplateComment] = useState(null);

  const [ShowDescription, setShowDescription] = useState([]);

  //Timeline
  const useStyles = makeStyles({
    timeline: {
      transform: "rotate(-90deg)",
    },
    timelineContentContainer: {
      textAlign: "left",

      margin: "0px",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(90deg)",
      textAlign: "center",
      background: "yellow",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
  });
  const classes = useStyles();

  const Process = [
    { ProcessId: "2", ProcessName: "Progress" },
    { ProcessId: "3", ProcessName: "Hold" },
    { ProcessId: "4", ProcessName: "Completed" },
  ];

  const [status, setStatus] = useState(
    ticketDetails.TicketStatus == 3
      ? Process[1].ProcessId
      : ticketDetails.TicketStatus == 4
      ? Process[2].ProcessId
      : Process[0].ProcessId
  );

  const GetUpdate = async (ticketId) => {
    const response = await global.$axios
      .put(`/ticket/updatestatus`, {
        AppCode: AppCode,
        ForId: ticketId,
        TicketStatus: status,
      })
      .then(async (response) => {
        setShowDescription(response.data.Message);
      })
      .catch((e) => {
        console.error("Discussion List Issue");
      });
  };

  const [TimeList, setTimeList] = useState({
    New: { Time: null, visible: true },
    Progress: { Time: null, visible: true },
    Hold: { Time: null, visible: false },
    Completed: { Time: null, visible: true },
    Solved: { Time: null, visible: false },
  });

  //Get Time
  const GetTime = async (ticketId, createdAt) => {
    const response = await global.$axios
      .get(`/ticket/statustime/${AppCode}/${ticketId}`)
      .then(async (response) => {
        if (response.data.Status === 200) {
          var apidata = response.data.Message;
          // Latest Progress time fetching
          const progressList = apidata.filter((x) => x.ToStatusId == 2);
          let progressTime = null;
          if (progressList.length > 0) {
            progressTime = progressList[0].StatusToTime;
          }
          // Latest Hold time fetching
          const HoldList = apidata.filter((x) => x.ToStatusId == 3);
          let HoldTime = null;
          if (HoldList.length > 0) {
            HoldTime = HoldList[0].StatusToTime;
          }
          // Latest Completed time fetching
          const CompletedList = apidata.filter((x) => x.ToStatusId == 4);
          let completedTime = null;
          if (CompletedList.length > 0) {
            completedTime = CompletedList[0].StatusToTime;
          }

          // Latest Completed time fetching
          const SolvedList = apidata.filter((x) => x.ToStatusId == 4);
          let solvedTime = null;
          if (SolvedList.length > 0) {
            solvedTime = SolvedList[0].StatusToTime;
          }
          console.log("createdAt", createdAt);

          setTimeList({
            New: { Time: createdAt, visible: true },
            Progress: { Time: progressTime, visible: true },
            Hold: { Time: HoldTime, visible: false },
            Completed: { Time: completedTime, visible: true },
            Solved: { Time: solvedTime, visible: false },
          });

          // setStatusTime(response.data.Message);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((e) => {
        console.error("Get Time Issue", e);
      });
  };

  //Get Feedback
  const GetFeedback = async (ticketId) => {
    const response = await global.$axios
      .get(`/ticket/getFeedback/${AppCode}/${ticketId}`)
      .then(async (response) => {
        var res = response?.data?.Message[0];
        setRating(res?.CreatorScore);
        setComment(res?.CreatorFeedback);
      })
      .catch((e) => {
        console.error("Get FeedBack Issue", e);
      });
  };

  //Get Template
  const TemplateList = async () => {
    const response = await global.$axios
      .get(`/predefined_reply/list/${AppCode}`)
      .then(async (response) => {
        setTemplateArray(response.data.Message);
      });
  };

  const makeTemplate = (templateString) => {
    return (templateData) =>
      new Function(
        `{${Object.keys(templateData).join(",")}}`,
        "return `" + templateString + "`"
      )(templateData);
  };

  useEffect(() => {
    const getTiketDataApi = async () => {
      try {
        const response = await $axios.get(`ticket/view/${AppCode}/${ticketId}`);
        console.log("ticket/view", response.data);

        if (response.data.Status == 200) {
          let ticketData = response.data.ticketdetails;
          setTicketDetails(ticketData);
          setTagList(response.data.Message.taglistuser);
          GetUpdate(ticketData.TicketId);
          GetTime(ticketData.TicketId, ticketData.CreatedAt);
          {
            ticketData.TicketStatus === 5 && GetFeedback();
          }
          TemplateList();
        }
      } catch (error) {
        console.log("ticket/view", error);
      }
    };
    getTiketDataApi();
  }, []);

  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item lg={3} xs={12} style={{ margin: "0px 0px" }}>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              cursor: "pointer",
            }}
          >
            <Tooltip title="Back">
              <ArrowBackIosNewIcon
                sx={{
                  width: "20px",
                  height: "15px",
                  marginBottom: "3px",
                }}
              />
            </Tooltip>
          </span>
          <span
            onClick={() => Navigate(-1)}
            style={{
              color: "#0071BC",
              fontSize: "16px",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Search /{" "}
          </span>
          <span style={styles.activehdr}>Ticket View</span>
        </Grid>
        {/* <Grid
          container
          item
          lg={9}
          xs={12}
          sx={{ gap: 2, display: "flex", margin: "5px 0px" }}
        >
          <Button
            style={{
              textTransform: "none",
              maxHeight: "26px",
              minHeight: "26px",
              padding: "12px",
              fontSize: "12px",
              borderRadius: "15px",
            }}
            variant="contained"
          >
            Internal Chat
          </Button>
          <Button
            style={{
              textTransform: "none",
              maxHeight: "26px",
              minHeight: "26px",
              padding: "12px",
              fontSize: "12px",
              borderRadius: "15px",
            }}
            variant="contained"
          >
            Recording
          </Button>

          <Button
            // onClick={() => setButton("recording")}
            style={{
              textTransform: "none",
              maxHeight: "26px",
              minHeight: "26px",
              padding: "12px",
              fontSize: "12px",
              borderRadius: "15px",
            }}
            variant="contained"
          >
            Time Log
          </Button>
          <Button
            onClick={() => setNotesOpen(true)}
            style={{
              textTransform: "none",
              maxHeight: "26px",
              minHeight: "26px",
              padding: "12px",
              fontSize: "12px",
              borderRadius: "15px",
            }}
            variant="contained"
          >
            Notes
          </Button>
          <Button
            href="TicketView/CaseHistory"
            target="_blank"
            onClick={() => {
              localStorage.setItem("pending", JSON.stringify(ticketDetails));
            }}
            style={{
              textTransform: "none",
              maxHeight: "26px",
              minHeight: "26px",
              padding: "12px",
              fontSize: "12px",
              borderRadius: "15px",
              color: "#FFF",
            }}
            variant="contained"
          >
            Case History
          </Button>
        </Grid> */}
      </Grid>
      <Grid
        container
        style={{
          margin: "0px 0px 5px 0px",
          background:
            "linear-gradient(90deg, #C4C4C4 1.08%, rgba(231, 231, 231, 0.411458) 75.27%, rgba(245, 245, 245, 0.177083) 99.99%, rgba(255, 255, 255, 0) 100%)",
          borderTopLeftRadius: "20px",
          borderBottomLeftRadius: "20px",
          padding: "5px ",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <TicketIdIcon />
          <span
            style={{ fontSize: "14px", fontWeight: 600, paddingLeft: "10px" }}
          >
            Ticket ID
          </span>
          <span
            style={{
              paddingLeft: "15px",
              fontSize: "14px",
              fontWeight: 500,
              color: "red",
            }}
          >
            {ticketDetails?.TicketId}
          </span>
        </div>
      </Grid>

      <Grid
        xs={12}
        container
        style={{
          margin: "0px 0px 5px 0px",
          border: "1px solid rgba(0,0,0,0.04)",
          padding: "0px 5px 5px 5px",
          borderRadius: "15px",
          boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/*New TimeLine*/}
        <Grid
          xs={10.5}
          container
          item
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketDetails?.TicketStatus >= 1 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>New</p>{" "}
            <span>
              {moment(TimeList.New.Time, "YYYY-MM-DD hh:mm A").format(
                "DD-MM-YYYY hh:mm A"
              )}
            </span>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketDetails?.TicketStatus >= 2
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketDetails?.TicketStatus >= 2 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Progress</p>{" "}
            {TimeList.Progress.Time != null && (
              <span>
                {moment(TimeList.Progress.Time, "YYYY-MM-DD hh:mm A").format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </span>
            )}
          </Grid>

          {ticketDetails?.TicketStatus == 3 && (
            <>
              <Grid
                item
                sx={{
                  margin: "3px 0px 0px 0px",
                }}
              >
                <TimelineItem className={classes.timeline}>
                  <TimelineConnector
                    style={{
                      background:
                        ticketDetails?.TicketStatus >= 3
                          ? "#0071BC"
                          : "rgba(0,0,0,0.3)",
                    }}
                  />
                </TimelineItem>
              </Grid>

              <Grid
                item
                style={{
                  margin: "0px 10px 0px 10px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <TimelineSeparator>
                  {ticketDetails?.TicketStatus >= 3 ? (
                    <TaskAltIcon
                      fontSize="small"
                      sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                    />
                  ) : (
                    <TimelineDot />
                  )}
                </TimelineSeparator>
                <p style={{ margin: "0px" }}>Hold</p>{" "}
                {TimeList.Hold.Time != null && (
                  <span>
                    {moment(TimeList.Hold.Time, "YYYY-MM-DD hh:mm A").format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                  </span>
                )}
              </Grid>
            </>
          )}

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketDetails?.TicketStatus >= 4
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketDetails?.TicketStatus >= 4 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Completed</p>{" "}
            <span>
              {ticketDetails?.TicketClosedTime &&
                moment(
                  ticketDetails?.TicketClosedTime,
                  "YYYY-MM-DD hh:mm A"
                ).format("DD-MM-YYYY hh:mm A")}
            </span>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TimelineItem className={classes.timeline}>
              <TimelineConnector
                style={{
                  background:
                    ticketDetails?.TicketStatus >= 5
                      ? "#0071BC"
                      : "rgba(0,0,0,0.3)",
                }}
              />
            </TimelineItem>
          </Grid>

          <Grid
            item
            style={{
              margin: "0px 10px 0px 10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <TimelineSeparator>
              {ticketDetails?.TicketStatus >= 5 ? (
                <TaskAltIcon
                  fontSize="small"
                  sx={{ margin: "4px 0px 3px 0px", color: "#0071BC" }}
                />
              ) : (
                <TimelineDot />
              )}
            </TimelineSeparator>
            <p style={{ margin: "0px" }}>Solved</p>{" "}
            <span>
              {ticketDetails?.TicketSolvedTime &&
                moment(
                  ticketDetails?.TicketSolvedTime,
                  "YYYY-MM-DD hh:mm A"
                ).format("DD-MM-YYYY hh:mm A")}
            </span>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={1.4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {ticketDetails.nudgeTime == "" ? (
            ""
          ) : new Date(ticketDetails.nudgeTime).getDate() !=
            valuedate.getDate() ? (
            ""
          ) : (
            <NudgeRed />
          )}
        </Grid>
      </Grid>

      {/*List*/}
      <Grid xs={12} container>
        <Grid
          container
          item
          style={{
            display: "flex",
            justifyContent: "space-between",
            lineHeight: "50px",
          }}
          md={7}
          xs={12}
        >
          <Grid
            item
            style={{ padding: "3px", lineHeight: "27px" }}
            md={6.2}
            xs={12}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Company Name</p>
              <p style={styles.txt}>
                {ticketDetails?.account?.CompanyName.slice(0, 30)}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Contact Name</p>
              <p style={styles.txt}>
                {ticketDetails?.contact?.ContactName?.slice(0, 20)}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Department</p>
              <p style={styles.txt}>{ticketDetails?.dept?.DepartmentName}</p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Product</p>
              <p style={styles.txt}>{ticketDetails?.product?.ProductName}</p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Issue</p>
              <p style={styles.txt}>{ticketDetails?.issue?.IssueName}</p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Phone</p>
              <p style={styles.txt}>{ticketDetails?.contact?.MobileNumber}</p>
            </Grid>
            {ticketDetails.ReopenReason && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <p style={styles.hdr}>Reopen Reason</p>
                <p style={styles.txt}>{ticketDetails?.ReopenReason}</p>
              </Grid>
            )}
            {ticketDetails.TicketStatus === 5 && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <p style={styles.hdr}>Rating</p>
                <Rating name="read-only" value={rating} readOnly />
              </Grid>
            )}
          </Grid>

          {/*List RHS*/}

          <Grid
            item
            style={{ padding: "3px", lineHeight: "27px" }}
            md={5.6}
            xs={12}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Email</p>
              <p style={styles.txt}>
                {ticketDetails?.contact?.EmailId
                  ? ticketDetails?.contact?.EmailId
                  : "-"}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Assigned By</p>
              {ticketDetails?.assigned !== null ? (
                <p style={styles.txt}>{ticketDetails?.assigned?.UserName}</p>
              ) : (
                <p style={styles.txt}>{ticketDetails?.contact?.ContactName}</p>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Created On</p>
              <p style={styles.txt}>
                {moment(ticketDetails.CreatedAt, "YYYY-MM-DD hh:mm").format(
                  "DD-MM-YYYY hh:mm A"
                )}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Due Date</p>
              <p style={styles.txt}>
                {ticketDetails.ECAt
                  ? moment(ticketDetails.ECAt, "YYYY-MM-DD hh:mm").format(
                      "DD-MM-YYYY hh:mm A"
                    )
                  : "-"}
              </p>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>Ticket Source</p>
              <p style={styles.txt}>
                {ticketDetails?.ticket_source?.TicketSourceName ?? "-"}
              </p>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <p style={styles.hdr}>View Attachment</p>
              <p style={styles.txt}>
                {ticketDetails?.AttachmentFile === ""
                  ? "-"
                  : ticketDetails?.AttachmentFile &&
                    ticketDetails?.AttachmentFile.split(",").map(
                      (Attachment, index) => {
                        return (
                          <Tooltip
                            style={{ paddingRight: "5px" }}
                            title={
                              <div style={{ height: "102px", width: "180px" }}>
                                <img
                                  src={Attachment}
                                  style={{
                                    width: "180px",
                                    position: "absolute",
                                    rigth: "80%",
                                  }}
                                />
                              </div>
                            }
                          >
                            <Link to={Attachment} target="blank">
                              <ViewIcon
                                style={{ width: "20px", height: "20px" }}
                              />
                            </Link>
                          </Tooltip>
                        );
                      }
                    )}
              </p>
            </Grid>

            {ticketDetails.TicketStatus === 5 && (
              <Grid
                container
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <p style={styles.hdr}>Comments</p>
                <Grid
                  item
                  style={{
                    border: "3px solid rgba(0,0,0,0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                    fontSize: "13px",
                  }}
                >
                  {Comment !== null ? Comment : "-"}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "5px 0px 0px 0px",
            }}
          >
            <Grid item sx={4}>
              <span
                style={{
                  margin: "0px",
                  padding: "0px 20px 0px 0px",
                  fontWeight: "700",
                  fontSize: "14px",
                }}
              >
                {tagList.length != 0 ? "Tagged Users" : "Tag User"}
              </span>
            </Grid>

            <Grid
              item
              xs={8}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {tagList?.map((e) => (
                <div
                  style={{
                    fontSize: "14px",
                    margin: "0px",
                    fontWeight: 700,
                    // padding: "5px",
                    cursor: "pointer",
                  }}
                >
                  {e?.UserName},
                </div>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "5px 0px 0px 0px",
            }}
          >
            <span
              style={{
                margin: "0px",
                padding: "0px 20px 0px 0px",
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              Comments
            </span>
            <Grid
              item
              xs={12}
              style={{
                background: "#FFF",
                padding: "5px",
                maxHeight: "30vh",
                boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
                overflowY: "auto",
                borderRadius: "15px",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: ticketDetails.TicketDescription,
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          style={{
            padding: "10px 5px 0px 15px",
            maxHeight: "74vh",
            marginBottom: "10px",
          }}
          md={5}
          xs={12}
        >
          <Grid
            item
            xs={12}
            style={{
              border: "1px solid rgba(0,0,0,0.05",
              boxShadow: "3px 5px 3px rgb(0,0,0,0.2)",
              padding: "10px 5px",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                fontSize: "16px",
                margin: "5px 0px",
                color: "#0071BC",
              }}
            >
              Discussions
            </span>

            {ShowDescription.length !== 0 ? (
              <Grid
                sx={{
                  maxHeight: "68vh",
                  overflowY: "scroll",
                }}
                container
                item
              >
                {ShowDescription?.map((item) => {
                  if (item?.For == 7 || item?.For == 9) {
                    return (
                      <Grid
                        xs={12}
                        item
                        style={{
                          margin: "0px",
                          padding: "10px",
                        }}
                      >
                        <Grid
                          item
                          xs={11.5}
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            padding: "2px",
                          }}
                        >
                          {" "}
                          <Grid
                            container
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "2px 5px",
                              background:
                                item.For == 9
                                  ? "rgba(47,207,47,0.1)"
                                  : "rgba(0,0,0,0.1)",
                            }}
                          >
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px",
                                  // color:item.For == 9 ?'#232323' : "#0071BC",
                                  fontSize: "12px",
                                }}
                              >
                                <span style={{ paddingRight: "5px" }}>
                                  {item.For == 9
                                    ? item?.contact?.FirstName.substr(0, 20)
                                    : ticketDetails?.updated?.UserFirstName}
                                </span>
                                {moment(
                                  item.CreatedAt,
                                  "YYYY-MM-DD hh:mm A"
                                ).format("DD-MM-YYYY hh:mm A")}
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px 0px 0px 0px",
                                  fontSize: "12px",
                                }}
                              >
                                {item?.InternalStatusId === 1
                                  ? "New"
                                  : item?.InternalStatusId === 2
                                  ? "Progress"
                                  : item?.InternalStatusId === 3
                                  ? "Hold"
                                  : item?.InternalStatusId === 4
                                  ? "Completed"
                                  : "Solved"}
                              </p>
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              margin: "5px 0px 0px 0px",
                              alignItems: "flex-start",
                              gap: "5px",
                              padding: "5px",
                            }}
                          >
                            <Avatar
                              alt={
                                item?.contact
                                  ? item?.contact?.FirstName
                                  : item?.create?.CreatedBy
                              }
                              src="/static/images/avatar/2.jpg"
                            />
                            <div
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: item?.Description,
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    );
                  } else if (item.For == 8) {
                    return (
                      <Grid
                        xs={12}
                        container
                        item
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        <Grid
                          item
                          xs={11.5}
                          style={{
                            border: "1px solid rgba(0,0,0,0.1)",
                            padding: "2px",
                          }}
                        >
                          {" "}
                          <Grid
                            container
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              background: "#0071BC1A",
                              padding: "2px 5px",
                            }}
                          >
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px",
                                  fontSize: "12px",
                                  color: "#0071BC",
                                }}
                              >
                                {item?.InternalStatusId === 1
                                  ? "New"
                                  : item?.InternalStatusId === 2
                                  ? "Progress"
                                  : item?.InternalStatusId === 3
                                  ? "Hold"
                                  : item?.InternalStatusId === 4
                                  ? "Completed"
                                  : "Solved"}
                              </p>
                            </Grid>
                            <Grid item>
                              <p
                                style={{
                                  margin: "0px",
                                  color: "#0071BC",
                                  fontSize: "12px",
                                  textAlign: "end",
                                }}
                              >
                                <span style={{ paddingRight: "5px" }}>
                                  {ticketDetails?.updated?.UserFirstName
                                    ? ticketDetails?.updated?.UserFirstName.substr(
                                        0,
                                        20
                                      )
                                    : ""}
                                </span>
                                {moment(
                                  item.CreatedAt,
                                  "YYYY-MM-DD hh:mm A"
                                ).format("DD-MM-YYYY hh:mm A")}
                              </p>
                            </Grid>
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              margin: "5px 0px 0px 0px",
                              alignItems: "flex-start",
                              justifyContent: "flex-end",
                              padding: "5px",
                            }}
                          >
                            <div
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: item?.predefinereply,
                              }}
                            />

                            <Avatar
                              alt={ticketDetails?.updated?.UserFirstName}
                              src="/static/images/avatar/2.jpg"
                              style={{ marginLeft: "5px" }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              margin: "0px 0px 0px 5px",
                            }}
                          >
                            <span style={{ fontSize: "12px", fontWeight: 500 }}>
                              Comments :
                            </span>
                            <div
                              style={{ fontSize: "12px" }}
                              dangerouslySetInnerHTML={{
                                __html: item?.Description,
                              }}
                            />
                          </div>
                          {item.json && (
                            <span
                              style={{
                                fontSize: "12px",
                                paddingRight: "3px",
                                fontWeight: 500,
                              }}
                            >
                              Attachments -
                            </span>
                          )}
                          {item.json &&
                            item.json.split(",").map((Attachment) => {
                              return (
                                <>
                                  <Tooltip
                                    style={{ paddingRight: "5px" }}
                                    title={
                                      <div
                                        style={{
                                          height: "102px",
                                          width: "180px",
                                        }}
                                      >
                                        <img
                                          src={Attachment}
                                          style={{
                                            width: "180px",
                                            position: "absolute",
                                            rigth: "80%",
                                          }}
                                        />
                                      </div>
                                    }
                                  >
                                    <Link to={Attachment} target="blank">
                                      <ViewIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </>
                              );
                            })}
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "35%",
                  marginBottom: "10px",
                }}
              >
                <ScreenSearchDesktopIcon
                  fontSize="large"
                  sx={{ width: "100px", height: "100px", color: "#c2c2c2" }}
                />
                <p
                  style={{
                    fontSize: "16",
                    fontWeight: 500,
                    color: "rgba(0,0,0,0.5)",
                    margin: "0px",
                    padding: "0px 10px",
                  }}
                >
                  No Discussions
                </p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        fontSize={"14px"}
        fontWeight={"600"}
        color={"#232323"}
      />
    </div>
  );
}
const styles = {
  SearchInput: {
    background: "transparent",
    outline: "none",
    border: "none",
    borderRadius: "20px",
    height: "15px",
  },
  activehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "20px",
    margin: "0px",
    padding: "0px",
  },
  unactivehdr: {
    color: "#0071BC",
    fontWeight: 600,
    fontSize: "16px",
    margin: "0px",
    padding: "0px",
  },
  tablehdr: {
    margin: "0px",
    fontSize: "14px",
  },
  img: {
    margin: 10,
  },
  txt: {
    fontSize: "13px",
    margin: "0px",
  },

  hdr: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 700,
  },
  txt1: {
    fontSize: "14px",
    margin: "0px",
    fontWeight: 800,
    color: "#0071BC",
  },
};

export default SearchView;
