import React from "react";
import { Routes, Route } from "react-router-dom";
import Customer from "../screens/Customer";
import Dashboard from "../screens/Dashboard";
import Master from "../screens/master/Master";
import MyWorkSpace from "../screens/MyWorkSpace";
import Tags from "../screens/Tags";
import Setting from "../screens/Setting";
import MainLayout from "../layout/MainLayout";
import All from "../screens/All";
import TeamTickets from "../screens/TeamTickets";
import TicketTransfer from "../screens/Team Components/TicketTransfer";
import ProductCategory from "../screens/Master Component/ProductCategory";
import ProductSubCategory from "../screens/Master Component/ProductSubCategory";
import Department from "../screens/Master Component/Department";
import Designation from "../screens/Master Component/Designation";
import Ticket from "../screens/Master Component/Ticket";
import SolutionConnect from "../screens/Master Component/SolutionConnect";
import Predefined from "../screens/Master Component/Predefined";
import TicketSource from "../screens/Master Component/TicketSource";
import TicketCategory from "../screens/Master Component/TicketCategory";
import AddEditStaticIp from "../screens/Master Component/AddEditStaticIp";
import UserPolicyScreen from "../screens/Master Component/UserPolicyScreen";
import CustomerDepartment from "../screens/Master Component/CustomerDepartment";
import CustomerDesignation from "../screens/Master Component/CustomerDesignation";
import MenuRole from "../screens/Master Component/MenuRole";
import DayHoursPolicy from "../screens/Master Component/DayHoursPolicy";
import Addmorecompanys from "../screens/Customer screens/Addmorecompanys";
import Newticket from "../screens/ticketComponent/NewTicket";
import EditTicket from "../screens/ticketComponent/EditTicket";
import Product from "../screens/Master Component/Product";
import EditProductPopup from "../screens/Master Component/PopupScreens/EditPopupscreeen/EditProductPopup";
import Users from "../screens/Master Component/Users";
import Brand from "../screens/Master Component/Brand";
import Unit from "../screens/Master Component/Unit";
import EditBranch from "../screens/Customer screens/EditBranch";
import Branch from "../screens/Master Component/Branch";
import Created from "../screens/Created";
import EditBranchPopup from "../screens/Master Component/PopupScreens/EditPopupscreeen/EditBranchPopup";
import Branchpopup from "../screens/Master Component/PopupScreens/Branchpopup";
import EditCompanyPopup from "../screens/Customer screens/EditCompanyPopup";
import CreatedViewTicket from "../screens/CreatedComponents/CreatedViewTicket";
import CloneTicket from "../screens/ticketComponent/CloneTicket";
import CustomerCategory from "../screens/Master Component/CustomerCategory";
import DepartmentAssign from "../screens/Master Component/PopupScreens/DepartmentAssign";
import CustomerCode from "../screens/Master Component/CustomerCode";
import TeamTicketView from "../screens/Team Components/TeamTicketView";
import AllView from "../screens/Allcomponents/AllView";
import AddNotes from "../screens/Notes/AddNotes";
import EditNotes from "../screens/Notes/EditNotes ";
import Notes from "../screens/Notes";
import PredefinedPopup from "../screens/Master Component/PopupScreens/PredefinedPopup";
import EditPredefinedPopup from "../screens/Master Component/PopupScreens/EditPopupscreeen/EditPredefinedPopup";
import Profile from "../screens/Profile";
import CaseHistory from "../screens/View Components/CaseHistory";
import TicketView from "../screens/myworkspaceComponent/TicketView";
import CompanyTicket from "../screens/View Components/CompanyTIcket";
import TaggedTickets from "../screens/TaggedTickets";
import DailyReport from "../screens/DailyReport";
import ClientReport from "../screens/ClientReports";
import ProductReport from "../screens/Product Reports";
import TaggedTicketView from "../screens/View Components/TagTIcketView";
import Whatsapp from "../screens/Communications/Whatsapp";
import Sms from "../screens/Communications/Sms";
import EmailLog from "../screens/Communications/EmailLog";
import Conversation from "../screens/Communications/Conversation";
import MailTicketIndex from "../screens/MailTicket/MailTicketIndex";
import CreateMailTicket from "../screens/MailTicket/CreateMailTicket";
import DashBoardview from "../screens/DashBoard Components/DashBoardview";
import AppConfig from "../screens/AppConfig";
import OverAllDashboard from "../screens/dashobard/OverAllDashboard";
import AgentDashboard from "../screens/dashobard/AgentDashboard";
import CustomerDashboard from "../screens/dashobard/CustomerDashboard";
import SentryPage from "../screens/SentryPage";
import SearchView from "../screens/Search/SearchView";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          <Route index element={<Dashboard />} />

          <Route path="/Profile" element={<Profile />} />
          <Route path="/appconfig" element={<AppConfig />} />
          <Route path="/Setting" element={<Setting />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/DepartmentDashboard" element={<OverAllDashboard />} />
          <Route path="/AgentDashboard" element={<AgentDashboard />} />
          <Route path="/CustomerDashboard" element={<CustomerDashboard />} />
          <Route path="/Dashboard/ticketview" element={<DashBoardview />} />
          <Route
            path="/Dashboard/ticketview/AllCompanyTickets"
            element={<CompanyTicket />}
          />
          <Route
            path="/Dashboard/ticketview/CaseHistory"
            element={<CaseHistory />}
          />

          <Route path="/sentryTickets" element={<SentryPage />} />
          <Route path="/searchView" element={<SearchView />} />

          {(global?.$userData?.logindata?.TicketCompaniesAccess == "1" ||
            global?.$userData?.logindata?.TicketCompaniesAccess == "2") && (
            <Route path="/Company" element={<Customer />} />
          )}
          <Route path="/Created" element={<Created />} />
          <Route path="/MyWorkspace" element={<MyWorkSpace />} />
          {(global?.$userData?.logindata?.TicketingDepartmentAdmin == "Yes" ||
            global?.$userData?.logindata?.AccessAdminTicket === "Yes") && (
            <Route path="/TeamTickets" element={<TeamTickets />} />
          )}
          <Route path="/TeamTickets/TeamView" element={<TeamTicketView />} />
          <Route
            path="/TeamTickets/TeamView/TeamCompanyTickets"
            element={<CompanyTicket />}
          />
          <Route
            path="/TeamTickets/TeamView/CaseHistory"
            element={<CaseHistory />}
          />
          {global?.$userData?.logindata?.AccessAdminTicket === "Yes" && (
            <Route path="/All" element={<All />} />
          )}
          <Route path="/All/AllView" element={<AllView />} />
          <Route path="/All/AllView/CaseHistory" element={<CaseHistory />} />
          <Route
            path="/All/AllView/AllCompanyTickets"
            element={<CompanyTicket />}
          />
          {global?.$userData?.logindata?.AccessAdminTicket == "Yes" && (
            <Route path="/Master" element={<Master />} />
          )}
          <Route path="/TaggedTickets" element={<TaggedTickets />} />
          <Route
            path="/TaggedTickets/TaggedView"
            element={<TaggedTicketView />}
          />
          <Route path="/DailyReports" element={<DailyReport />} />
          <Route path="/ClientReports" element={<ClientReport />} />
          <Route path="/ProductReports" element={<ProductReport />} />
          {/*Communications */}
          <Route path="/Communication/WhatsApp" element={<Whatsapp />} />
          <Route path="/Communication/SMSLog" element={<Sms />} />
          <Route path="/Communication/EmailLog" element={<EmailLog />} />
          <Route
            path="/Communication/Conversation"
            element={<Conversation />}
          />
          <Route path="/Notes" element={<Notes />} />
          <Route path="/Notes/AddNotes" element={<AddNotes />} />
          <Route path="/Notes/EditNotes" element={<EditNotes />} />
          <Route path="/mail_to_ticket" element={<MailTicketIndex />} />
          <Route path="/create_mail_to_ticket" element={<CreateMailTicket />} />
          <Route path="/Tags" element={<Tags />} />
          <Route path="/TicketTransfer" element={<TicketTransfer />} />
          <Route path="/TicketView" element={<TicketView />} />
          <Route
            path="/TicketView/CompanyTickets"
            element={<CompanyTicket />}
          />
          <Route path="/TicketView/CaseHistory" element={<CaseHistory />} />
          <Route path="/CreatedView" element={<CreatedViewTicket />} />
          <Route path="/EditTicket" element={<EditTicket />} />
          <Route path="/CloneTicket" element={<CloneTicket />} />
          <Route path="/AddTicket" element={<Newticket />} />
        </Route>
      </Routes>

      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/Master/Product" element={<Product />} />
          <Route path="/Master/ProductCategory" element={<ProductCategory />} />
          <Route
            path="/Master/ProductSubCategory"
            element={<ProductSubCategory />}
          />
          <Route
            path="/Master/Product/EditProduct"
            element={<EditProductPopup />}
          />
          <Route path="/Master/Brand" element={<Brand />} />
          <Route path="/Master/Unit" element={<Unit />} />
          <Route path="/Master/Department" element={<Department />} />
          <Route
            path="/Master/Department/AssignDepartment"
            element={<DepartmentAssign />}
          />
          <Route path="/Master/Designation" element={<Designation />} />
          <Route path="/Master/Role" element={<MenuRole />} />
          <Route path="/Master/Ticket" element={<Ticket />} />
          <Route path="/Master/SolutionConnect" element={<SolutionConnect />} />
          <Route path="/Master/PredefinedReply" element={<Predefined />} />
          <Route
            path="/Master/PredefinedReply/AddPredefinedReply"
            element={<PredefinedPopup />}
          />
          <Route
            path="/Master/PredefinedReply/EditPredefinedReply"
            element={<EditPredefinedPopup />}
          />
          <Route path="/Master/TicketSource" element={<TicketSource />} />
          <Route path="/Master/TicketCategory" element={<TicketCategory />} />
          <Route path="/Master/AddEditStaticIp" element={<AddEditStaticIp />} />
          <Route path="/Master/DayHourPolicy" element={<DayHoursPolicy />} />
          <Route path="/Master/UserPolicy" element={<UserPolicyScreen />} />
          {/* <Route path="/Master/CustomerUpload" element={<CustomerUpload />} /> */}
          {/* <Route path="/Master/Menu" element={<Menu />} /> */}
          {/* <Route path="/Master/MenuGroup" element={<MenuGroups />} /> */}
          {/* <Route path="/Master/SideBar" element={<SideBar />} /> */}
          <Route
            path="/Master/CustomerDepartment"
            element={<CustomerDepartment />}
          />
          <Route
            path="/Master/CustomerDesignation"
            element={<CustomerDesignation />}
          />
          <Route
            path="/Master/CustomerCategory"
            element={<CustomerCategory />}
          />
          <Route path="/Master/Users" element={<Users />} />
          <Route path="/Master/Branch" element={<Branch />} />
          <Route path="/Master/Branch/AddBranch" element={<Branchpopup />} />
          <Route
            path="/Master/Branch/EditBranch"
            element={<EditBranchPopup />}
          />
          <Route path="/Master/CustomerCode" element={<CustomerCode />} />
          <Route path="/Company/AddCompanies" element={<Addmorecompanys />} />
          <Route path="/Company/EditCompany" element={<EditCompanyPopup />} />

          {/* <Route path="/Company/AddContact" element={<Addmorcontact />} />   */}
          <Route path="/Company/EditBranch" element={<EditBranch />} />
          {/* <Route path="/MyWorkspace/AddTicket" element={<Newticket />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
