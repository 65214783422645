import { Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Buttonticket } from "../Assets/Icon";
import { useNavigate } from "react-router-dom";
import OverAllTicket from "./DashBoard Components/OverAllTicket";
import Delayedticket from "./DashBoard Components/Delayedticket";
import Todayupdated from "./DashBoard Components/Todayupdated";
import Dailyapex from "./DashBoard Components/Dailyapex";
import Todaycount from "./DashBoard Components/Todaycount";

export default function Dashboard() {
  const Navigate = useNavigate();

  var DH = new Date().getHours();
  var TT = DH >= 0 && DH < 12 ? 1 : DH >= 12 && DH <= 15 ? 2 : 3;
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.6); // Default width

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(window.innerWidth * 0.6); // Adjust the width dynamically
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // console.log("global.$userData.logindata", global.$userData.logindata);

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item>
          <span style={{ fontSize: "16px", fontWeight: 500 }}>Welcome</span>
          <span
            style={{
              paddingLeft: "5px",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {global.$userData.logindata.Name}
          </span>
        </Grid>

        <Button
          variant="contained"
          onClick={() => Navigate("/app/AddTicket")}
          style={{
            maxHeight: "22px",
            minHeight: "22px",
            textTransform: "none",
            padding: "12px",
          }}
        >
          {" "}
          + New Ticket{" "}
          <span style={{ paddingLeft: "5px" }}>
            <Buttonticket />
          </span>
        </Button>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          mt: 1,
          height: { xs: "auto", md: "24.4vh" },
        }}
      >
        <Grid
          item
          md={8}
          xs={12}
          sx={{
            pr: 0.5,
            height: { xs: "auto", md: "24.4vh" },
          }}
        >
          <OverAllTicket />
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            height: { xs: "40vh", md: "24.4vh" },
            mt: { xs: 1, md: 0 },
            // minheight: "24.4vh",
            // maxheight: "24.4vh",
            backgroundColor: "#0071BC30",
            borderRadius: 2,
          }}
        >
          <Delayedticket />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          mt: 1,
          height: { xs: "40vh", md: "60vh" },
          display: "flex",
        }}
      >
        <Grid
          container
          item
          xs={12}
          md={5}
          sx={{
            mt: { xs: 1, md: 0 },
          }}
        >
          <Todayupdated />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={7}
          sx={{
            mt: { xs: 1, md: 0 },
            height: { xs: "auto", md: "60vh" },
          }}
        >
          <Grid
            item
            sx={{
              px: 5,
              mx: 1,
              mt: 0.5,
              background: "#fff",
              borderRadius: 1,
              height: { xs: "50vh", md: "50vh" },
              display: { xs: "none", md: "block" },
            }}
            xs={12}
          >
            <Dailyapex chartWidth={chartWidth} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              pl: 1,
              mt: 1,
            }}
          >
            <Todaycount />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
